import React, { useState, useEffect, useContext } from 'react';
import { Col, Label, Input, FormGroup, Row, InputGroup } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
//import { Table } from 'reactstrap';
import useFullPageLoader from "../hooks/useFullPageLoader";
import Sidebar from '../UserDashboard/Sidebar';
import {ProductContext} from '../../ProductProvider';
import ProfileImage from '../profile/ProfileImage';
import { phoneCode } from '../../BookData';
import ProgressBarStatus from '../profile/ProgressBarStatus';
//import UserView from './UserView';
const config = require('../../config.json');


export default function MyProfile() {
    const {loggedUser} = useContext(ProductContext);
    //const [userSearchResult, setUserSearchResult] = useState('');
    const [dispUserMsg, setDispUserMsg] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [profileImage, setProfileImage] = useState('defaultAvatar.jpg');
    const [user, setUser]= useState({userId:'',username:'',phoneNo:'',role:'',accountStatus:'', emailId:''});
    const [editDisabled, setEditDisabled] = useState(true);
    //const [dispMsg, setDispMsg]=useState('');
    const [countryCode, setCountryCode]=useState("+91");
    const [progressValue, setProgressValue]=useState(0);

    useEffect(() => {
      window.scrollTo(0,0);
      setEditDisabled(true);
        handleuserbooksearch()
        
    },[]);

    const processProgressBarUpdate = (tInput)=>{
      var arr=[];
          arr=tInput;
          //console.log("array : ", arr);
          //let result = !Object.values(arr).every(o => o === "NA");
          //let res1= Object.keys(arr).filter(key => (arr[key] === "NA"||arr[key]===""));
          var pro=0;
          for (var key in arr) {
            if ((key==="loginid"||key==="userfullname"||key==="emailid"||key==="phoneno"||key==="user_role"||key==="account_status")&&(arr[key] === "NA" || arr[key] === ""))
                pro++;
        }
        const tpvalue=(((6-pro)/6)*100).toFixed();
      //console.log("pvalue", tpvalue);
        setProgressValue(tpvalue);
        
    }

    const handleuserbooksearch = async() => {
        setDispUserMsg("");
        //setUserSearchResult("");
        showLoader();
          try {   
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //validating with cognito
            // await axios.get(`${config.api.invokeUrl}/books/mybooks?logid=${usernameSearch}&familyName=${loggedUser.familyName}`,{
            //validating with inigo_library_users
            const searchoption = "loginid";
            await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${loggedUser.username}&searchoption=${searchoption}&familyName=${loggedUser.familyName}`,{
               headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                }
            })
            .then((response)=>{
                if(response.status===205){
                    setDispUserMsg("User does not exist. Enter the right details.");
                   
                }else if(response.status===210){
                    setDispUserMsg("Multiple Users Found. Enter the right details.");
                }
                else{
                 //   console.log(response);
                    if(response.data[0].user.length>0){
                      const {loginid,userfullname,emailid,phoneno,user_role,account_status,countryCode}=response.data[0].user[0];
                      const tImage=loginid+".jpeg";
                      setProfileImage(tImage);      
                      processProgressBarUpdate(response.data[0].user[0]);
                      
                      setUser({...user,
                        userId:loginid,
                        username:userfullname,
                        emailId:emailid,
                        phoneNo:phoneno,
                        role:user_role,
                        accountStatus:account_status
                      });
                      setCountryCode(countryCode);
                    }
                    //setUserSearchResult(response.data[0].user);
                }
            })
            .catch((error)=>{
                setDispUserMsg("Error Occured - " + error.message);
            })            
           
          } catch (error) {
            setDispUserMsg("Error Occured - " + error.message);
        }
        hideLoader();
    }

    return (

<div className="dash-board  " style={{paddingBottom:'80px'}} >
  {loader}
{loggedUser.isAuthenticated?

<Row style={{margin:'0px'}} >

<Col className='con-test navbar-bg' lg={3} style={{marginTop:'0px'}}>
<Sidebar />
</Col>
<Col  lg={9} style={{marginTop:'30px'}}>

<h4 className="text-title" style={{marginLeft:'-30px'}}><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</h4>
        <ProfileImage fileName={user.userId} setUser={setUser} setProfileImage={setProfileImage} profileImage={profileImage} user={user} />

<div style={{paddingTop:'15px'}} className='d-flex justify-content-center'>

<FormGroup style={{overflow:'visible'}}>
      <Row className='flex-fill'>
        <Col lg={12}  style={{ backgroundColor:'white', border:'2px solid lightgrey', borderRadius:'10px'}}>
          <Row className='p-2'>
          <div className=' flex-fill'>  <ProgressBarStatus  progressValue={progressValue} dispValue="Profile"/> </div>

            </Row>
        <Row className='p-2'>
      <Col xs={3} lg={2} >
     
                  
                 
                  <Label for="usernameL">Name</Label>
                  </Col>
                  <Col >
                  <Input
                        type="text"
                        name="username"
                        placeholder="Enter Name"
                        value={user.username}
                        disabled={editDisabled}
                        onChange={(event)=>{setUser({...user,username:(event.target.value)})}}  
                      />
                  </Col>
                
      </Row>
      <Row className='p-2'>
      <Col xs={3} lg={2}>
                <Label for="emailL">Email</Label>
                </Col>
                  <Col >
                  <Input type="email" name="emailId"
                       value={user.emailId} 
                       disabled={editDisabled}
                       onChange={(event)=>{setUser({...user, emailId:event.target.value})}}
                         
                      />
                            
                            </Col> 
      </Row>
      <Row className='p-2'>
      <Col xs={3} lg={2}>
                  <Label for="phoneNoL">Phone</Label>
                  </Col>
                  <Col >
                  <InputGroup>
                 
      <select  value={countryCode}                         disabled={editDisabled}
                      onChange={(event)=>{setCountryCode(event.target.value)}}>
                      
                        
                          {phoneCode.map(item => {
                              return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                          })}
                            </select>
                  
                  <Input  type="number" aria-describedby="phoneHelp" className="name" id="phoneno" 
                  placeholder="Enter Phone No" value={user.phoneNo}
                                              disabled={editDisabled}
                        onChange={(event)=>{setUser({...user,phoneNo:event.target.value})}}>
                  </Input>
                 
                  </InputGroup>
       
           
                   
                      </Col>

      </Row>
      <Row className='p-2'>
      <Col xs={3} lg={2}>
<Label for="dateOrdainL">Role</Label>
</Col>
                  <Col >
  <Input type="text" name="dateOrdain"
       value={user.role} 
       disabled={editDisabled}
       onChange={(event)=>{setUser({...user, role:event.target.value})}}     
      />
            
</Col>
</Row>
<Row className='p-2'>
                       <Col xs={3} lg={2}>
  <Label for="lastVowL">Status</Label>
  </Col>
                  <Col >
  <Input type="text" name="lastVow"
        placeholder="Last Vow" value={user.accountStatus} 
        disabled={editDisabled}
       onChange={(event)=>{setUser({...user,accountStatus:(event.target.value)})}}
      />
  </Col>

      </Row>
        </Col>
      </Row>
     
    </FormGroup>
</div>
    
 
</Col>
        
<p className='text-center text-danger p-4'>{dispUserMsg}</p>

<FormGroup check row>
                 
                 <div className="container text-center">
            <div className="d-flex justify-content-around btn ">
            </div>
            </div>
           
                </FormGroup>

    </Row>:<div>"You are not authorised to view this page"</div>}
        </div>
   
   )
}
