import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {ProductProvider} from './ProductProvider';
import {BrowserRouter as Router} from 'react-router-dom';
import Amplify from 'aws-amplify';
import 'font-awesome/css/font-awesome.min.css';

import config from './config';
import './index.css';
import App from './App';


Amplify.configure ({
  Auth: {
      mandatorySignId: true,
      region: config.cognito.REGION,
      userPoolId: config.cognito.USER_POOL_ID,
      userPoolWebClientId: config.cognito.APP_CLIENT_ID
  }
});


ReactDOM.render(
  <ProductProvider>
    <Router>
        <App />
    </Router>  
  </ProductProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

