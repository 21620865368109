import React, { useContext, useState } from 'react';
import './bottombar.css';
import { ProductContext } from '../../ProductProvider';

import { Link, useHistory } from "react-router-dom";

export default function Bottombar() {
   
    let history = useHistory();
    const { loggedUser} = useContext(ProductContext);
    const [activeTab, setActiveTab] = useState('');
    
    

    const handleShow = (value) => {
        if(value==="QuickView" && loggedUser.isAuthenticated){
            setActiveTab('QuickView');
            history.push('/dashboard');

        }else if(value==="QuickView" && !loggedUser.isAuthenticated){
            setActiveTab('QuickView');
            history.push('/');
        }else if(loggedUser.isAuthenticated){
            if (value === "Transaction") {
                    setActiveTab('Transaction');
                    history.push('/books-view');
                } else if (value==="QuickProfile") {
                    setActiveTab('QuickProfile');
                    history.push('/profile-view');
                } else if (value==="SearchView") {
                    setActiveTab('SearchView');
                    history.push('/search-books');
                }
            }else{
                setActiveTab('QuickProfile');
                history.push("/signin");
            }
    }
 
   
   {/*} const [activeTabs, setActiveTabs] = useState('home');
    useEffect(() => {
        console.log("Active tab value : ", activeTabs);
        switch (activeTabs) {
            case 'home':
                history.push('/dashboard')
                break;
            case 'search':
                history.push('/my-transaction')
                break;
            case 'favourites':
                history.push('/favourites')
                break;
            case 'account':
                history.push('/my-transaction')
                break;
            default:
                history.push('/dashboard')
                break;
        }
    }, [activeTabs, history])*/}
    
        return (
       

      
         <div className="fixed-bottom">
                <div className="content-view d-flex" style={{bottom: "0", backgroundColor:"#001822", height:"55px", padding:"8px", fontSize:"24px"}}>
                   
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>{handleShow("QuickProfile")}}>
                               {activeTab==="QuickProfile"? <i style={{color:"white", fontSize:"30px"}} className="fa fa-user" ></i>
                               :<i className="fa fa-user" ></i>}
                            <span className="link_name"></span>
                            
                        </Link>
                     
                    </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#"  onClick={()=>handleShow("SearchView")}>
                           {activeTab==="SearchView"? <i style={{color:"white", fontSize:"30px"}} className="fa fa-search"></i>
                           :<i className="fa fa-search"></i>}
                            <span className="link_name"></span>
                        </Link>
                       
                        </div>
                        <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#" onClick={()=>handleShow("QuickView")}>
                            {activeTab==="QuickView"?<i style={{color:"white", fontSize:"30px"}} className="fa fa-home"></i>
                            :<i className="fa fa-home"></i>}
                            <span className="link_name"></span>
                        </Link>
                        </div>
                    <div className="justify-content-center" style={{width:"35%", textAlign:"center"}}>
                    <Link to="#" onClick={()=>handleShow("Transaction")}>
                            {activeTab==="Transaction"?<i style={{color:"white", fontSize:"30px"}} className="fa fa-book"></i>
                            :<i className="fa fa-book"></i>}
                            <span className="link_name"></span>
                        </Link>
                        </div>
                </div>
              
            
                
            </div>
           
        )
    
}
