import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { CSVLink } from "react-csv";
import axios from "axios";
import { Auth } from "aws-amplify";
import { Table } from 'reactstrap';
//import { useTable } from "react-table";
import Sidebar from '../UserDashboard/Sidebar';
import { ProductContext } from '../../ProductProvider';
//import ReturnBookDisplay from './ReturnBookDisplay';
//import UserView from '../user/UserView';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { bookClassification, bookHeaders } from '../../BookData';
const config = require('../../config.json');


export default function ReturnBooks() {
    const {loggedUser} = useContext(ProductContext);
   // const [usernameSearch, setUsernameSearch] = useState('');
    const [accessionNoSearch, setAccessionNoSearch] = useState('');
  //  const [userSearchResult, setUserSearchResult] = useState('');
   // const [dispUserMsg, setDispUserMsg] = useState('');
    const [dispBookMsg, setDispBookMsg] = useState('');
    const [bookResults, setBookResults] = useState([]);
    //const [issueButton, setIssueButton] = useState(true);
    const [searchOption, setSearchOption]= useState('AccessionNo');
    const [displayBookResults, setDisplayBookResults] = useState('');
    //const [isLoading, setLoading] = useState(false);
    const [loader, showLoader, hideLoader] = useFullPageLoader();   
    const csvLinkEl = React.createRef();
 
    useEffect(() => {
        fetchIssuedBookDetails();
         
    },[]);
    function winScroll(){
      window.scrollTo(0,0);
    }
    const fetchIssuedBookDetails = async() =>{
      //event.preventDefault();
      setBookResults('');
      setDispBookMsg('');
      try {   
        showLoader();
        var familyname=loggedUser.familyName;
        const session = await Auth.currentSession();
        if(loggedUser.familyName===""){
          familyname=session.idToken.payload.family_name;
        }
        const access_token=session.idToken.jwtToken;
        await axios.get(`${config.api.invokeUrl}/books/manage/transaction?familyName=${familyname}`,{
           headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
            }
        }).then((response)=>{
          //console.log("Response : ", response.data);
          setDispBookMsg(response.data.length + " - Books Issued");
          var i=0;
          while(i<response.data.length){
            //console.log("index : ", (response.data[i].issued_on).indexOf(','));
          /*  var tlength=(response.data[i].issued_on).indexOf(',');
            if (tlength<0){
               tlength=(response.data[i].issued_on).indexOf('T');
            }
            console.log("Issed Date : ", (response.data[i].issued_on).substring(0,tlength));*/
           const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===response.data[i].Book_Classification_No);
           var bAuthor="";
           bAuthor = response.data[i].Last_Name;
          // console.log("bAuthor : ", bAuthor);
            if(bAuthor.length===0){
              bAuthor=response.data[i].First_Name;
            }
           
            response.data[i].RackValue=response.data[i].Book_Classification_No+" "+bAuthor.substring(0,3);

           response.data[i].Book_Classification_No=tempClassValue[0].name;
           i++;
          }
          setBookResults(response.data);
          setDisplayBookResults(response.data);
          
        }).catch((error)=>{
          setDispBookMsg("Error Occured - " + error.message);
        })
      }catch(error){
        setDispBookMsg("Error Occured - " + error.message);
      }
      hideLoader();

    }

    const handleDownload =async()=>{
      csvLinkEl.current.link.click();
    }

  /*  const handleuserbooksearch = async(event) => {
        event.preventDefault();
        setDispUserMsg("");
        setUserSearchResult("");
        setBookResults("");
          if(usernameSearch==="")
          {
            setDispUserMsg("Enter the username");
          }else{
      
          try {   
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //validating with cognito
            // await axios.get(`${config.api.invokeUrl}/books/mybooks?logid=${usernameSearch}&familyName=${loggedUser.familyName}`,{
            //validating with inigo_library_users
            const searchoption = "loginid";
            await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${usernameSearch}&searchoption=${searchoption}&familyName=${loggedUser.familyName}`,{
               headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                }
            })
            .then((response)=>{
                if(response.status===205){
                    setDispUserMsg("User does not exist. Enter the right details.");
                   
                }else if(response.status===210){
                    setDispUserMsg("Multiple Users Found. Enter the right details.");
                }
                else if(response.data[0].user[0].account_status==="UNCONFIRMED"){
                  setDispUserMsg("You are yet to Confirm your account. Check your email to confirm your account.");
                } else if(response.data[0].user[0].user_role==="Guest"){
                  setDispUserMsg("Your account is marked as Guest. Librarian / Admin use the 'Manage Users' option to change the User role.");
                } else if(response.data[0].user[0].user_role==="Student" && response.data[1].book.length>2) {
                  console.log("Books count : ",response.data[1].book.length);
                  setDispUserMsg("Max 3 books are allowed for the Student");

                }else{
                    setUserSearchResult(response.data[0].user);
                    setBookResults(response.data[1].book);
                    setIssueButton(false);
                }
            })
            .catch((error)=>{
                setDispUserMsg("Error Occured - " + error.message);
            })            
           
          } catch (error) {
            console.log(`An error has occurred: ${error}`);
          }
        }
        }*/

   /* const handleissuebook = async(event) => {

        event.preventDefault();
        const now = new Date();
        setDispBookMsg("");  
        try {
            if(usernameSearch===""){
                setDispBookMsg("Enter the user details first!");
            }else if(accessionNoSearch===""){
                setDispBookMsg("Enter the Accession No to Issue the Book!");
            }else{
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const params = {
                  "Accession_No": accessionNoSearch,
                  "Book_Status":"Issued",
                  "issued_by": loggedUser.username,
                  "issued_on":now,
                  "username":usernameSearch,
                  "familyname":loggedUser.familyName,
                  "email":loggedUser.email     
                };
                const Accession_No = accessionNoSearch;
                await axios.patch(`${config.api.invokeUrl}/books/manage/transaction/${Accession_No}`, params, {
                  headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                  }
                }).then((resOutput)=>{
                if (resOutput.data.length<1){
                  setDispBookMsg("Not a Valid Accession No!");
                }else if(resOutput.status===205){
                    setDispBookMsg(Accession_No + " - Already Issued to " + resOutput.data + ". Return the Book first before issuing it again");
                }else if(resOutput.status===209){
                    setDispBookMsg(Accession_No + " - belongs to " + resOutput.data + " category. Only Circulation type books can be issued");
                }
                else{
                    setDispBookMsg(Accession_No + " - Book Issued Successfully");
                    setBookResults(resOutput.data);
                    const bookUpdated = resOutput.data;
                    const productToUpdate = [...bookUpdated].find(searchresult => searchresult.Accession_No === Accession_No);
                    //const updatedProducts = [...bookResults].filter(searchresult => searchresult.Accession_No !== Accession_No);
                    productToUpdate.Book_Status = "Issued";
                    productToUpdate.username = usernameSearch;
                    const booktemp=bookResults;

                    booktemp.push(productToUpdate);
                    setBookResults(booktemp);
                }
              }).catch((error)=>{
                setDispBookMsg("Not a Valid Accession No!");
            });
            
            }
        
              } catch (error) {
                setDispBookMsg("An error has occurred : "+ error.message);
              }
           }
     */
     
    /*    const handlereturnbook = async(event) => {
     
            event.preventDefault();
            try {
               if(accessionNoSearch===""){
                 setDispBookMsg("Enter the Accession No to Issue the Book!");
               }else{
                 const session = await Auth.currentSession();
                 const access_token=session.idToken.jwtToken;          
                const params = {
                  "Accession_No": accessionNoSearch,
                  "Book_Status":"Available",
                  "issued_by":"",
                  "issued_on":"",
                  "username":"NA",
                  "familyname":loggedUser.familyName,
                  "email":loggedUser.email 
                };
                const Accession_No = accessionNoSearch;     
                const resOutput = await axios.patch(`${config.api.invokeUrl}/books/manage/transaction/${Accession_No}`, params, {
                 headers: {
                   Authorization: access_token,
                   'x-api-key':config.api.key
                 }
               });
               
                if (resOutput.data.length<1){
                    setDispBookMsg("Not a Valid Accession No!")
                }else if(resOutput.status===205){
                 setDispBookMsg(Accession_No + " - Not Issued to Anyone. Issue the Book first before Returning");
               }else{
                  setDispBookMsg("Book Returned Successfully")
                  
                  setBookResults(resOutput.data);
                  const updatedProducts = [...bookResults].filter(searchresult => searchresult.Accession_No !== Accession_No);
                  setBookResults(updatedProducts);
                }
               }
              } catch (error) {
                console.log(`An error has occurred: ${error}`);
              }
     
           }*/

           const handleReturnBookFromList = async(event, tempAccessionNo, tempIssuedUser, tempBookTitle, tempuseremail) => {
            event.preventDefault();
            showLoader();
            try {
               if(tempAccessionNo===""){
                 setDispBookMsg("Enter the Accession No to Return the Book!");
               }else{
                 
                 //
                 const received_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});;
                 const session = await Auth.currentSession();
                 const access_token=session.idToken.jwtToken;          
                const params = {
                  "Accession_No": tempAccessionNo,
                  "Book_Title":tempBookTitle,
                  "Book_Status":"Available",
                  "issued_by":loggedUser.username,
                  "issued_on":received_datetime,
                  "due_date":"",
                  "username":tempIssuedUser,
                  "familyname":loggedUser.familyName,
                  "email": tempuseremail
                };
               // console.log("Params: ", params);
                const Accession_No = tempAccessionNo;     
                const resOutput = await axios.patch(`${config.api.invokeUrl}/books/manage/transaction/${Accession_No}`, params, {
                 headers: {
                   Authorization: access_token,
                   'x-api-key':config.api.key
                 }
               });
               //console.log("response : ", resOutput);
                if(resOutput.status===200){
                  setDispBookMsg(Accession_No + " - Book Returned Successfully")
                  
                  //setBookResults(resOutput.data);
                  //console.log("Book Results : ", bookResults);
                  //console.log("Issued Books : ", displayBookResults);
                  const updatedProducts = [...displayBookResults].filter(searchresult => searchresult.Accession_No !== Accession_No);
                  //console.log("updatedProducts : ", updatedProducts);
                  setBookResults(updatedProducts);
                  setDisplayBookResults(updatedProducts);
                  setAccessionNoSearch('');
                }
               }
              } catch (error) {
                console.log(`An error has occurred: ${error}`);
              }
              hideLoader();
           }

        const handleFilterResults = (event)=>{
          var inputValue= event.target.value;
          setBookResults(displayBookResults);
          if (searchOption==="AccessionNo"){
            const filteredResults = [...displayBookResults].filter(searchresult => searchresult.Accession_No.startsWith(inputValue));
            setBookResults(filteredResults);

          } else {
              const str = event.target.value;
              const arr = str.split(" ");
              //loop through each element of the array and capitalize the first letter.
              for (var i = 0; i < arr.length; i++) {
                  arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  
              }
              //Join all the elements of the array back into a string 
              //using a blankspace as a separator 
              inputValue = arr.join(" ");
              //console.log(str2);
            const filteredResults = [...displayBookResults].filter(searchresult => searchresult.username.includes(inputValue));
            setBookResults(filteredResults);
          }
         // const filteredResults = [...displayBookResults].filter(searchresult => searchresult.Accession_No === Accession_No);
        }

  /*      const handleAccessionInput = (event) =>{
            setUsernameSearch(event.target.value);
            setUserSearchResult('');
            setBookResults('');
            setIssueButton(true);
        }
     */
  

    return (
      <div className="dash-board" style={{paddingBottom:'80px'}}  >
{loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

<div>
        <div className="navbar-mobile">
    
        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px', paddingBottom:'20px'}}>
        <div >

<div >
  <h4 className="text-title"><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Books</h4>
    <Row>
<Col xl={12} lg={12} md={12}>
<Card>

<CardBody>
<Form >

  <FormGroup check row style={{paddingLeft:"0px"}}>
                 <div >

                 <div className="row">
     <div className="col-md-6 col-sm-6  col-xs-6">
     <Label for="searchoption">
          <input
              type="radio"
              name="searchoption"
              id="searchoption"
              value="AccessionNo"
              
              checked={searchOption==="AccessionNo"}
              onChange={(event)=>setSearchOption(event.target.value)}
              style={{marginRight: '8px'}}
            />               
            Accession</Label>
         </div>
      <div className="col-md-6 col-sm-6 col-xs-6">
      <Label>
         <input
              type="radio"
              id="searchoption"
              value="Username"
              name="searchoption"
              checked={searchOption==="Username"}
              onChange={(event)=>setSearchOption(event.target.value)}
              style={{marginRight: '8px'}}
          />
           UserName</Label>
         </div>
         </div>

<div className="row">

  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="text"
        name="accno"
        placeholder="Enter the input"
        autoFocus="autofocus"

        value={accessionNoSearch} 
        onChange={(event)=>{setAccessionNoSearch(event.target.value);handleFilterResults(event)}}
        
      />
      
  </div>
  </div>
  {bookResults.length>0 ?<div style={{textAlign:"right", color:"blue"}}><b>Download... </b> <i className="fa fa-download" onClick={(event)=>{handleDownload(event)}}></i>
<CSVLink
          headers={bookHeaders}
          filename="Inigo_Library_Issued_Books.csv"
          data={bookResults}
          ref={csvLinkEl}
        /></div> : <div></div>
           
           }
  </div>
     
      
    </FormGroup>
    {dispBookMsg}

    
  </Form>
</CardBody>
</Card>
</Col>


</Row> 

{bookResults.length>0?<div>

<Table hover responsive bordered>

<thead>
<tr>
  <th>Accession</th>
  <th>Book Title</th>
  <th>Author</th>
  <th>Classification</th>
  <th>Scope</th>
  <th>Status</th>
  <th>Issued</th>
  <th>Action</th>
</tr> 
</thead>    
<tbody>
{bookResults.map((searchresult,index) =>(
<tr key={index}>
<th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
<td>{searchresult.Book_Title}</td>
<td>{searchresult.Book_Author} </td>
<td>{searchresult.Book_Classification_No}</td>
<td>{searchresult.Book_Scope}</td>
<td> {searchresult.Book_Status}</td>
<td><p>{searchresult.username}</p><p>({(searchresult.issued_on).indexOf(',')<0? (searchresult.issued_on).substring(0,(searchresult.issued_on).indexOf('T')):(searchresult.issued_on).substring(0,(searchresult.issued_on).indexOf(',')) })</p></td>
<td><button className="btn" onClick={(event)=>{handleReturnBookFromList(event, searchresult.Accession_No, searchresult.username, searchresult.Book_Title, searchresult.user_email)}}>Return</button> </td>
</tr>))}

</tbody></Table>


</div>:<div className="tile notification is-warning"></div>}  
</div>
{loader}

</div>
        </div>
        </div>

        <div className="navbar-bg">
       
        <div className="row " style={{margin:'0px'}}>
          <div className="col-3 col-lg-3 con-test">

            <Sidebar />
          </div>

          <div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>
        <div className="container">

          <div className="container">
            <h4 className="text-title"><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Books</h4>
              <Row>
      <Col xl={12} lg={12} md={12}>
        <Card>
         
          <CardBody>
          <Form >
                         
            <FormGroup check row>
                           <div className="container">

                           <div className="row">
               <div className="col-md-6 col-sm-6  col-xs-6">
               <Label for="searchoption">
                    <input
                        type="radio"
                        name="searchoption"
                        id="searchoption"
                        value="AccessionNo"
                        checked={searchOption==="AccessionNo"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                      />               
                      Accession</Label>
                   </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                <Label>
                   <input
                        type="radio"
                        id="searchoption"
                        value="Username"
                        name="searchoption"
                        checked={searchOption==="Username"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                    />
                     UserName</Label>
                   </div>
                   </div>

          <div className="row">
          <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="accessionno">{searchOption==="Username"?"User Name":"Accession No"}</Label>
            </div>
            <div className="col-md-6 col-sm-6  col-xs-6">
            <Input
                  type="text"
                  name="accno"
                  placeholder="Enter the input"
                  autoFocus="autofocus"

                  value={accessionNoSearch} 
                  onChange={(event)=>{setAccessionNoSearch(event.target.value);handleFilterResults(event)}}
                  
                />
                
            </div>
           {/*} <div className="col-md-2 col-sm-2  col-xs-2">
            <Button disabled ={issueButton}
                  onClick={event => handleissuebook(event)}
                  >Issue Book
    
                  </Button>
      </div>*/}
            
            </div>
           {bookResults.length>0 ?<div style={{textAlign:"right", color:"blue"}}><b>Click to download the data.... </b> <i className="fa fa-download" onClick={(event)=>{handleDownload(event)}}></i>
<CSVLink
          headers={bookHeaders}
          filename="Inigo_Library_Issued_Books.csv"
          data={bookResults}
          ref={csvLinkEl}
        /></div> : <div></div>
           
           }
            
            </div>
               
                
              </FormGroup>
              {dispBookMsg}

              
            </Form>
          </CardBody>
        </Card>
      </Col>

         
    </Row> 


    {/*bookResults.length>0?<div>
      <table className="table table-striped" hover="true" responsive="true">
                        <TableHeader
                            headers={headers}
                            onSorting={(field, order) =>
                                setSorting({ field, order })
                            }
                        />
                        <tbody>
                            {bookResults.map(result => (
                                <tr key={result.Accession_No}>
                                    <th scope="row" key={result.Accession_No}>
                                        {result.Accession_No}
                                    </th>
                                    <td>{result.Book_Title}</td>
                                    <td>{result.Book_Author}</td>
                                    <td>{result.Book_Classification_No}</td>
                                    <td>{result.Book_Scope}</td>
                                    <td>{result.Book_Status}</td>
                                    <td>{result.username}</td>
                                    <td><button className="btn" onClick={(event)=>{handleReturnBookFromList(event, result.Accession_No)}}>Return</button> </td>
                                </tr>

                            ))}
                        </tbody>
                    </table>
                            </div>:<div></div>*/}


 {/* <Table className="bg-container">
                      <tbody>
                        <tr>
                        <th className="table-head-btitle">Accession No</th>
                        <th className="table-head-btitle">Book Title</th>
                        <th className="table-head-atitle">Author</th>
                        <th className="table-head-cls">Clasification</th>
                        <th className="table-head-scope">Scope</th>
                        <th className="table-head-stat">Status</th>
                        <th className="table-head-stat">Issued To</th>
                        </tr>
  
  </tbody></Table>
     <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'center'}}>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Acc. No </p>
    </div>
    <div style={{flex:'1', width:'30', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Book Title</p>
    </div>
    <div style={{flex:'1', width:'20', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Author</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Clasification</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Scope</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Issued To</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Action</p>
    </div>
    </div>      
   */}
       {bookResults.length>0?<div>
      
          <Table hover responsive bordered>
        
        <thead>
          <tr>
            <th>Accession</th>
            <th>Book Title</th>
            <th>Author</th>
            <th>Classification</th>
            <th>Scope</th>
            <th>Status</th>
            <th>Issued</th>
            <th>Action</th>
          </tr> 
        </thead>    
       <tbody>
        {bookResults.map((searchresult,index) =>(
        <tr key={index}>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author} </td>
          <td>{searchresult.Book_Classification_No}</td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td><p>{searchresult.username}</p><p>({(searchresult.issued_on).indexOf(',')<0? (searchresult.issued_on).substring(0,(searchresult.issued_on).indexOf('T')):(searchresult.issued_on).substring(0,(searchresult.issued_on).indexOf(',')) })</p></td>
          <td><button className="btn" onClick={(event)=>{handleReturnBookFromList(event, searchresult.Accession_No, searchresult.username, searchresult.Book_Title, searchresult.user_email)}}>Return</button> </td>
        </tr>))}
      
        </tbody></Table>

        
        </div>:<div className="tile notification is-warning"></div>}  
      </div>
      {loader}

   </div>
        </div></div></div></div>:<div>"You are not authorised to view this page"</div>}
        <button style={{border:"none", background:"transparent"}} onClick={winScroll} > <i className="fa fa-arrow-up" style={{position: "fixed",zIndex:"999", bottom:"20px", right:"10px"}}>
            <p >Top</p> </i> </button>
</div>
     
    )
}
