import React,{useState} from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import signImg from '../../images/auth.gif';
import { phoneCode } from '../../BookData';
const config = require('../../config.json');

function SignUp() {

  const [username, setUsername] = useState('');
  const [userfullname, setUserfullname] = useState('');
  const [phoneno, setPhoneno]= useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [dispMsg, setDispMsg] = useState('');
  const [countryCode, setCountryCode]=useState('+91');


  const handleUserRequest= async (event) => {
    event.preventDefault();
    try{
         setDispMsg("");
         if ( username==="" || password==="" || email==="" || phoneno === "" || userfullname ==="" || code === "")
         {
           setDispMsg("Fill all the required details for Sign Up");
         }
         else
         {
           const library_source = code;
           const res = await axios.get(`${config.api.invokeUrl}/library/${library_source}`,{
            headers: {
              'x-api-key':config.api.key
            }
          });
           if (res.data.length < 1){
               setDispMsg("Invalid Library code. Please contact your Admin");
            }else{
              const str = userfullname;
              const arr = str.split(" ");
              //loop through each element of the array and capitalize the first letter.
              for (var i = 0; i < arr.length; i++) {
                  arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

              }
              //Join all the elements of the array back into a string 
              //using a blankspace as a separator 
              const usernameInput = arr.join(" ");
              
              const lib_source=res.data[0].lib_key;
              const phone=countryCode.toString()+phoneno.toString();
             await Auth.signUp({
                username,
                email,
                password,
                attributes:{
                  email:email,
                  family_name:lib_source,
                  phone_number:phone,
                  name: usernameInput,
                  given_name:"Guest",
                  preferred_username:"NA",
                  profile:"Guest",
                  picture:countryCode.toString()
                }
              }).then((res)=>{
                setDispMsg("Account created successfully. Check your email to confirm.");
                setUsername('');
                setEmail('');
                setPassword('');
                setUserfullname('');
                setPhoneno('');
                setCode('');

              }).catch((err)=>{
                const errmsg = "Error Occured : " + err.message;
                setDispMsg(errmsg);          
              });
    }
  }
     // this.props.history.push("/welcome");
    } catch (err){
      //console.log("Inside error log", err);
      const errmsg = "Error Occured : " + err.message;
      setDispMsg(errmsg);
    }
  
  }

return (
           
<div className="popup-container" style={{paddingTop:'0px', paddingBottom:'60px'}}>
<div className="signup-container">
          <h1 className="header-text">Sign Up</h1>
          <div className="avatar-icon">
            <img className="signupimg" src={signImg} alt="Sign Up"/> 
</div>  
          <div className="d-flex  justify-content-center">


          <form className="col-8" onSubmit={event => handleUserRequest(event)}>

    
  
        
        <input 
          className="input-signup" 
          type="loginid"
          id="loginid"
          aria-describedby="loginidHelp"
          placeholder="Enter login ID"
          value={username}
          onChange={(event)=>setUsername(event.target.value)}
        />     


<input className="input-signup" type="password" id="password" placeholder="Password" 
             value={password}
             onChange={(event)=>{setPassword(event.target.value)}}/>
    
        
        <input 
          className="input-signup" 
          type="userfullname"
          id="userfullname"
          aria-describedby="nameHelp"
          placeholder="Enter your Name"
          value={userfullname}
          onChange={(event)=>{setUserfullname(event.target.value)}}
        />     

                <input 
                  className="input-signup" 
                  type="email"
                  id="emailid"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(event)=>{setEmail(event.target.value)}}
                />     
    <span >
       <select className="input-phonecode-s" value={countryCode} onChange={(event)=>{setCountryCode(event.target.value)}}>
              {phoneCode.map(item => {
                return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
              })}
            </select>
          
        
        <input 
          className="input-phone-s" 
          type="number"
          id="phoneno"
          aria-describedby="phoneHelp"
          placeholder="Enter Phone No"
          value={phoneno}
          maxLength="10"
          onChange={(event)=>{setPhoneno(event.target.value)}}
        />     

    </span>
       
        <input 
                  className="input-signup" 
                  type="text"
                  id="code"
                  aria-describedby="sourceHelp"
                  placeholder="Enter Library Code"
                  value={code}
                  onChange={(event)=>{setCode(event.target.value)}}
                />   
        
    <p style={{color:'white'}}>{dispMsg}</p>

            
           
              
                <div style={{display:'flex', justifyContent:'center', marginLeft:'-15px'}}>
           
                <button className="button-is-success" style={{color:'black'}}>
                 <b>Sign Up</b> 
                </button>
                </div>
            
     
    
    </form>
</div>
</div></div>
        )
    
}
export default SignUp;