export const bookClassification=[
    {
      "name":"Select Book Classification",
      "value":"100"
    },
    {
      "name":"210 Bible General",
      "value":"210"
    },
    {
      "name":"211 Old Testament General",
      "value":"211"
    },{
      "name":"212 A. Pentateuch",
      "value":"212A"
    },{
      "name":"212 B. Historical",
      "value":"212B"
    },{
      "name":"212 C. Prophets",
      "value":"212C"
    },{
      "name":"212 D. Wisdom",
      "value":"212D"
    },{
      "name":"214 Commentaries",
      "value":"214"
    },{
      "name":"215 New Testament General",
      "value":"215"
    },{
      "name":"216 A. Mathew",
      "value":"216A"
    },{
      "name":"216 B. Mark",
      "value":"216B"
    },{
      "name":"216 C. Luke",
      "value":"216C"
    },{
      "name":"216 D. John",
      "value":"216D"
    },{
      "name":"217 Acts",
      "value":"217"
    },{
      "name":"218 Paul",
      "value":"218"
    },{
      "name":"219 Pastoral Epistles",
      "value":"219"
    },{
      "name":"219 A. Revelation",
      "value":"219A"
    },{
      "name":"220 Theology General",
      "value":"220"
    },{
      "name":"221 Pastoral",
      "value":"221"
    },{
      "name":"222 God",
      "value":"222"
    },{
      "name":"223 Christology",
      "value":"223"
    },{
      "name":"224 Grace",
      "value":"224"
    },{
      "name":"225 Moral",
      "value":"225"
    },{
      "name":"226 Eschatology",
      "value":"226"
    },{
      "name":"227 Liberation",
      "value":"227"
    },{
      "name":"228 Community",
      "value":"228"
    },{
      "name":"229 Science",
      "value":"229"
    },{
      "name":"230 Church General",
      "value":"230"
    },{
      "name":"231 Documents",
      "value":"231"
    },{
      "name":"232 Ecumenism",
      "value":"232"
    },{
      "name":"233 Dialogue",
      "value":"233"
    },{
      "name":"234 Inculturaion",
      "value":"234"
    },{
      "name":"235 Christian Anthropology",
      "value":"235"
    },{
      "name":"236 Faith",
      "value":"236"
    },{
      "name":"237 Homily Retreat",
      "value":"237"
    },{
      "name":"238 History",
      "value":"238"
    },{
      "name":"238 A. Laity",
      "value":"238A"
    },{
      "name":"239 Liturgy",
      "value":"239"
    },{
      "name":"239 A. Missiology",
      "value":"239A"
    },{
      "name":"240 Sacraments General",
      "value":"240"
    },{
      "name":"241 Baptism",
      "value":"241"
    },{
      "name":"242 Reconciliation",
      "value":"242"
    },{
      "name":"243 Confirmation",
      "value":"243"
    },{
      "name":"244 Anointing",
      "value":"244"
    },{
      "name":"245 Priesthood",
      "value":"245"
    },{
      "name":"246 Eucharist",
      "value":"246"
    },{
      "name":"247 Marriage",
      "value":"247"
    },{
      "name":"248",
      "value":"248"
    },{
      "name":"249 Catechetics",
      "value":"249"
    },{
      "name":"250 Spirituality General",
      "value":"250"
    },{
      "name":"251 Ignatian",
      "value":"251"
    },{
      "name":"251 A. Retreat",
      "value":"251A"
    },{
      "name":"252 Society of Jesus",
      "value":"252"
    },{
      "name":"253 Mary",
      "value":"253"
    },{
      "name":"254 Discernment",
      "value":"254"
    },{
      "name":"255 Prayer Meditation",
      "value":"255"
    },{
      "name":"256 Religious Life",
      "value":"256"
    },{
      "name":"257 Charismatic",
      "value":"257"
    },{
      "name":"258 Indian",
      "value":"258"
    },{
      "name":"259 Saints",
      "value":"259"
    },{
      "name":"260 Religious",
      "value":"260"
    },{
      "name":"261 Hinduism",
      "value":"261"
    },{
      "name":"262 amil Literature",
      "value":"262"
    },{
      "name":"263 Bhakti Literature",
      "value":"263"
    },{
      "name":"264 Religious Leaders",
      "value":"264"
    },{
      "name":"265 Islam",
      "value":"265"
    },{
      "name":"266 Buddhism",
      "value":"266"
    },{
      "name":"266 A. Jainism",
      "value":"266A"
    },{
      "name":"267 Epics",
      "value":"267"
    },{
      "name":"268 Indian Xtian Theology",
      "value":"268"
    },{
      "name":"269 Others",
      "value":"269"
    },{
      "name":"270 Philosophy General",
      "value":"270"
    },{
      "name":"271 Gandhi",
      "value":"271"
    },{
      "name":"272 Marx",
      "value":"272"
    },{
      "name":"273 Indian",
      "value":"273"
    },{
      "name":"274 Teilhard",
      "value":"274"
    },{
      "name":"275 Tagore",
      "value":"275"
    },{
      "name":"276 Culture",
      "value":"276"
    },{
      "name":"277 Education",
      "value":"277"
    },{
      "name":"278 History",
      "value":"278"
    },{
      "name":"279 Youth",
      "value":"279"
    },{
      "name":"300 Sociology",
      "value":"300"
    },{
      "name":"301 Anthropology",
      "value":"301"
    },{
      "name":"302 Psychology",
      "value":"302"
    },{
      "name":"303 Languages",
      "value":"303"
    },{
      "name":"304 Methodology",
      "value":"304"
    },{
      "name":"305 Tamil Cult. & History",
      "value":"305"
    },{
      "name":"306 Dalits",
      "value":"306"
    },{
      "name":"306 A. Tribals",
      "value":"306A"
    },{
      "name":"306 B. Ecology",
      "value":"306B"
    },{
      "name":"306 C. Others",
      "value":"306C"
    },{
      "name":"307 Periyar",
      "value":"307"
    },{
      "name":"308 Women",
      "value":"308"
    },{
      "name":"308 A. Children",
      "value":"308A"
    },{
      "name":"309 Politics",
      "value":"309"
    },{
      "name":"309 A. Human Rights",
      "value":"309A"
    },{
      "name":"310 Soci. Analysi",
      "value":"310"
    },{
      "name":"311 Globalization",
      "value":"311"
    },{
      "name":"400 Art & Culture",
      "value":"400"
    },{
      "name":"401 Communication",
      "value":"401"
    },{
      "name":"402 English Literature",
      "value":"402"
    },{
      "name":"403 Eng. Fiction",
      "value":"403"
    },{
      "name":"403 A. Tamil FI",
      "value":"403A"
    },{
      "name":"404 Biography",
      "value":"404"
    },{
      "name":"405 A. Tamil Grammer",
      "value":"405A"
    },{
      "name":"405 B. Kavithaigal",
      "value":"405B"
    },{
      "name":"405 C. Drama",
      "value":"405C"
    },{
      "name":"405 D. General",
      "value":"405D"
    },{
        "name":"405 E. Tamil History",
        "value":"405E"
    },{
      "name":"406 Modern Literature",
      "value":"406"
    },{
      "name":"407 Ecology",
      "value":"407"
    },{
      "name":"408 Health",
      "value":"408"
    },{
      "name":"409 Hindutuva Communalis",
      "value":"409"
    },{
      "name":"410 Management",
      "value":"410"
    }
  ];

export const bookCategory=[
    {
      "name":"BIBLE",
      "fvalue":"210",
      "lvalue":"219A",
      "svalue":"21"
    },
    {
      "name":"THEOLOGY",
      "fvalue":"220",
      "lvalue":"229",
      "svalue":"22"
    },
    {
      "name":"CHURCH",
      "fvalue":"230",
      "lvalue":"239A",
      "svalue":"23"
      },
    {
      "name":"SACRAMENTS",
      "fvalue":"240",
      "lvalue":"249",
      "svalue":"24"
      },
    {
      "name":"SPIRITUALITY",
      "fvalue":"250",
      "lvalue":"259",
      "svalue":"25"
      },
    {
      "name":"INDOLOGY",
      "fvalue":"260",
      "lvalue":"269",
      "svalue":"26"
      },
    {
      "name":"PHILOSOPHY",
      "fvalue":"270",
      "lvalue":"279",
      "svalue":"27"
      },
    {
      "name":"SOCIAL SCIENCE",
      "fvalue":"300",
      "lvalue":"311",
      "svalue":"3"
      },
    {
      "name":"VARIETY",
      "fvalue":"400",
      "lvalue":"410",
      "svalue":"4"
      },
  ];

export  const phoneCode=[
    {
      "Country": "USA",
      "Code": "+1"
    },
    {
      "Country": "RUS",
      "Code": "+7"
    },
    {
      "Country": "EGY",
      "Code": "+20"
    },
    {
      "Country": "ZAF",
      "Code": "+27"
    },
    {
      "Country": "GRC",
      "Code": "+30"
    },
    {
      "Country": "NLD",
      "Code": "+31"
    },
    {
      "Country": "BEL",
      "Code": "+32"
    },
    {
      "Country": "FRA",
      "Code": "+33"
    },
    {
      "Country": "ESP",
      "Code": "+34"
    },
    {
      "Country": "HUN",
      "Code": "+36"
    },
    {
      "Country": "ITA",
      "Code": "+39"
    },
    {
      "Country": "ROU",
      "Code": "+40"
    },
    {
      "Country": "CHE",
      "Code": "+41"
    },
    {
      "Country": "AUT",
      "Code": "+43"
    },
    {
      "Country": "GBR",
      "Code": "+44"
    },
    {
      "Country": "DNK",
      "Code": "+45"
    },
    {
      "Country": "SWE",
      "Code": "+46"
    },
    {
      "Country": "NOR",
      "Code": "+47"
    },
    {
      "Country": "POL",
      "Code": "+48"
    },
    {
      "Country": "DEU",
      "Code": "+49"
    },
    {
      "Country": "PER",
      "Code": "+51"
    },
    {
      "Country": "MEX",
      "Code": "+52"
    },
    {
      "Country": "CUB",
      "Code": "+53"
    },
    {
      "Country": "ARG",
      "Code": "+54"
    },
    {
      "Country": "BRA",
      "Code": "+55"
    },
    {
      "Country": "CHL",
      "Code": "+56"
    },
    {
      "Country": "COL",
      "Code": "+57"
    },
    {
      "Country": "VEN",
      "Code": "+58"
    },
    {
      "Country": "MYS",
      "Code": "+60"
    },
    {
      "Country": "AUS",
      "Code": "+61"
    },
    {
      "Country": "IDN",
      "Code": "+62"
    },
    {
      "Country": "PHL",
      "Code": "+63"
    },
    {
      "Country": "NZL",
      "Code": "+64"
    },
    {
      "Country": "SGP",
      "Code": "+65"
    },
    {
      "Country": "THA",
      "Code": "+66"
    },
    {
      "Country": "JPN",
      "Code": "+81"
    },
    {
      "Country": "KOR",
      "Code": "+82"
    },
    {
      "Country": "VNM",
      "Code": "+84"
    },
    {
      "Country": "CHN",
      "Code": "+86"
    },
    {
      "Country": "TUR",
      "Code": "+90"
    },
    {
      "Country": "IND",
      "Code": "+91"
    },
    {
      "Country": "PAK",
      "Code": "+92"
    },
    {
      "Country": "AFG",
      "Code": "+93"
    },
    {
      "Country": "LKA",
      "Code": "+94"
    },
    {
      "Country": "MMR",
      "Code": "+95"
    },
    {
      "Country": "IRN",
      "Code": "+98"
    },
    {
      "Country": "SSD",
      "Code": "+211"
    },
    {
      "Country": "ESH",
      "Code": "+212"
    },
    {
      "Country": "DZA",
      "Code": "+213"
    },
    {
      "Country": "TUN",
      "Code": "+216"
    },
    {
      "Country": "LBY",
      "Code": "+218"
    },
    {
      "Country": "GMB",
      "Code": "+220"
    },
    {
      "Country": "SEN",
      "Code": "+221"
    },
    {
      "Country": "MRT",
      "Code": "+222"
    },
    {
      "Country": "MLI",
      "Code": "+223"
    },
    {
      "Country": "GIN",
      "Code": "+224"
    },
    {
      "Country": "CIV",
      "Code": "+225"
    },
    {
      "Country": "BFA",
      "Code": "+226"
    },
    {
      "Country": "NER",
      "Code": "+227"
    },
    {
      "Country": "TGO",
      "Code": "+228"
    },
    {
      "Country": "BEN",
      "Code": "+229"
    },
    {
      "Country": "MUS",
      "Code": "+230"
    },
    {
      "Country": "LBR",
      "Code": "+231"
    },
    {
      "Country": "SLE",
      "Code": "+232"
    },
    {
      "Country": "GHA",
      "Code": "+233"
    },
    {
      "Country": "NGA",
      "Code": "+234"
    },
    {
      "Country": "TCD",
      "Code": "+235"
    },
    {
      "Country": "CAF",
      "Code": "+236"
    },
    {
      "Country": "CMR",
      "Code": "+237"
    },
    {
      "Country": "CPV",
      "Code": "+238"
    },
    {
      "Country": "STP",
      "Code": "+239"
    },
    {
      "Country": "GNQ",
      "Code": "+240"
    },
    {
      "Country": "GAB",
      "Code": "+241"
    },
    {
      "Country": "COG",
      "Code": "+242"
    },
    {
      "Country": "COD",
      "Code": "+243"
    },
    {
      "Country": "AGO",
      "Code": "+244"
    },
    {
      "Country": "GNB",
      "Code": "+245"
    },
    {
      "Country": "IOT",
      "Code": "+246"
    },
    {
      "Country": "SYC",
      "Code": "+248"
    },
    {
      "Country": "SDN",
      "Code": "+249"
    },
    {
      "Country": "RWA",
      "Code": "+250"
    },
    {
      "Country": "ETH",
      "Code": "+251"
    },
    {
      "Country": "SOM",
      "Code": "+252"
    },
    {
      "Country": "DJI",
      "Code": "+253"
    },
    {
      "Country": "KEN",
      "Code": "+254"
    },
    {
      "Country": "TZA",
      "Code": "+255"
    },
    {
      "Country": "UGA",
      "Code": "+256"
    },
    {
      "Country": "BDI",
      "Code": "+257"
    },
    {
      "Country": "MOZ",
      "Code": "+258"
    },
    {
      "Country": "ZMB",
      "Code": "+260"
    },
    {
      "Country": "MDG",
      "Code": "+261"
    },
    {
      "Country": "REU",
      "Code": "+262"
    },
    {
      "Country": "ZWE",
      "Code": "+263"
    },
    {
      "Country": "NAM",
      "Code": "+264"
    },
    {
      "Country": "MWI",
      "Code": "+265"
    },
    {
      "Country": "LSO",
      "Code": "+266"
    },
    {
      "Country": "BWA",
      "Code": "+267"
    },
    {
      "Country": "SWZ",
      "Code": "+268"
    },
    {
      "Country": "COM",
      "Code": "+269"
    },
    {
      "Country": "SHN",
      "Code": "+290"
    },
    {
      "Country": "ERI",
      "Code": "+291"
    },
    {
      "Country": "ABW",
      "Code": "+297"
    },
    {
      "Country": "FRO",
      "Code": "+298"
    },
    {
      "Country": "GRL",
      "Code": "+299"
    },
    {
      "Country": "GIB",
      "Code": "+350"
    },
    {
      "Country": "PRT",
      "Code": "+351"
    },
    {
      "Country": "LUX",
      "Code": "+352"
    },
    {
      "Country": "IRL",
      "Code": "+353"
    },
    {
      "Country": "ISL",
      "Code": "+354"
    },
    {
      "Country": "ALB",
      "Code": "+355"
    },
    {
      "Country": "MLT",
      "Code": "+356"
    },
    {
      "Country": "CYP",
      "Code": "+357"
    },
    {
      "Country": "FIN",
      "Code": "+358"
    },
    {
      "Country": "BGR",
      "Code": "+359"
    },
    {
      "Country": "LTU",
      "Code": "+370"
    },
    {
      "Country": "LVA",
      "Code": "+371"
    },
    {
      "Country": "EST",
      "Code": "+372"
    },
    {
      "Country": "MDA",
      "Code": "+373"
    },
    {
      "Country": "ARM",
      "Code": "+374"
    },
    {
      "Country": "BLR",
      "Code": "+375"
    },
    {
      "Country": "AND",
      "Code": "+376"
    },
    {
      "Country": "MCO",
      "Code": "+377"
    },
    {
      "Country": "SMR",
      "Code": "+378"
    },
    {
      "Country": "UKR",
      "Code": "+380"
    },
    {
      "Country": "SRB",
      "Code": "+381"
    },
    {
      "Country": "MNE",
      "Code": "+382"
    },
    {
      "Country": "XKX",
      "Code": "+383"
    },
    {
      "Country": "HRV",
      "Code": "+385"
    },
    {
      "Country": "SVN",
      "Code": "+386"
    },
    {
      "Country": "BIH",
      "Code": "+387"
    },
    {
      "Country": "MKD",
      "Code": "+389"
    },
    {
      "Country": "CZE",
      "Code": "+420"
    },
    {
      "Country": "SVK",
      "Code": "+421"
    },
    {
      "Country": "LIE",
      "Code": "+423"
    },
    {
      "Country": "FLK",
      "Code": "+500"
    },
    {
      "Country": "BLZ",
      "Code": "+501"
    },
    {
      "Country": "GTM",
      "Code": "+502"
    },
    {
      "Country": "SLV",
      "Code": "+503"
    },
    {
      "Country": "HND",
      "Code": "+504"
    },
    {
      "Country": "NIC",
      "Code": "+505"
    },
    {
      "Country": "CRI",
      "Code": "+506"
    },
    {
      "Country": "PAN",
      "Code": "+507"
    },
    {
      "Country": "SPM",
      "Code": "+508"
    },
    {
      "Country": "HTI",
      "Code": "+509"
    },
    {
      "Country": "BLM",
      "Code": "+590"
    },
    {
      "Country": "BOL",
      "Code": "+591"
    },
    {
      "Country": "GUY",
      "Code": "+592"
    },
    {
      "Country": "ECU",
      "Code": "+593"
    },
    {
      "Country": "PRY",
      "Code": "+595"
    },
    {
      "Country": "SUR",
      "Code": "+597"
    },
    {
      "Country": "URY",
      "Code": "+598"
    },
    {
      "Country": "ANT",
      "Code": "+599"
    },
    {
      "Country": "TLS",
      "Code": "+670"
    },
    {
      "Country": "ATA",
      "Code": "+672"
    },
    {
      "Country": "BRN",
      "Code": "+673"
    },
    {
      "Country": "NRU",
      "Code": "+674"
    },
    {
      "Country": "PNG",
      "Code": "+675"
    },
    {
      "Country": "TON",
      "Code": "+676"
    },
    {
      "Country": "SLB",
      "Code": "+677"
    },
    {
      "Country": "VUT",
      "Code": "+678"
    },
    {
      "Country": "FJI",
      "Code": "+679"
    },
    {
      "Country": "PLW",
      "Code": "+680"
    },
    {
      "Country": "WLF",
      "Code": "+681"
    },
    {
      "Country": "COK",
      "Code": "+682"
    },
    {
      "Country": "NIU",
      "Code": "+683"
    },
    {
      "Country": "WSM",
      "Code": "+685"
    },
    {
      "Country": "KIR",
      "Code": "+686"
    },
    {
      "Country": "NCL",
      "Code": "+687"
    },
    {
      "Country": "TUV",
      "Code": "+688"
    },
    {
      "Country": "PYF",
      "Code": "+689"
    },
    {
      "Country": "TKL",
      "Code": "+690"
    },
    {
      "Country": "FSM",
      "Code": "+691"
    },
    {
      "Country": "MHL",
      "Code": "+692"
    },
    {
      "Country": "PRK",
      "Code": "+850"
    },
    {
      "Country": "HKG",
      "Code": "+852"
    },
    {
      "Country": "MAC",
      "Code": "+853"
    },
    {
      "Country": "KHM",
      "Code": "+855"
    },
    {
      "Country": "LAO",
      "Code": "+856"
    },
    {
      "Country": "BGD",
      "Code": "+880"
    },
    {
      "Country": "TWN",
      "Code": "+886"
    },
    {
      "Country": "MDV",
      "Code": "+960"
    },
    {
      "Country": "LBN",
      "Code": "+961"
    },
    {
      "Country": "JOR",
      "Code": "+962"
    },
    {
      "Country": "SYR",
      "Code": "+963"
    },
    {
      "Country": "IRQ",
      "Code": "+964"
    },
    {
      "Country": "KWT",
      "Code": "+965"
    },
    {
      "Country": "SAU",
      "Code": "+966"
    },
    {
      "Country": "YEM",
      "Code": "+967"
    },
    {
      "Country": "OMN",
      "Code": "+968"
    },
    {
      "Country": "PSE",
      "Code": "+970"
    },
    {
      "Country": "ARE",
      "Code": "+971"
    },
    {
      "Country": "ISR",
      "Code": "+972"
    },
    {
      "Country": "BHR",
      "Code": "+973"
    },
    {
      "Country": "QAT",
      "Code": "+974"
    },
    {
      "Country": "BTN",
      "Code": "+975"
    },
    {
      "Country": "MNG",
      "Code": "+976"
    },
    {
      "Country": "NPL",
      "Code": "+977"
    },
    {
      "Country": "TJK",
      "Code": "+992"
    },
    {
      "Country": "TKM",
      "Code": "+993"
    },
    {
      "Country": "AZE",
      "Code": "+994"
    },
    {
      "Country": "GEO",
      "Code": "+995"
    },
    {
      "Country": "KGZ",
      "Code": "+996"
    },
    {
      "Country": "UZB",
      "Code": "+998"
    }
  ];

export const bookHeaders = [
  { label: "Accession No", key: "Accession_No" },
  { label: "Book Title", key: "Book_Title" },
  { label: "Author Name", key: "Book_Author" },
  { label: "Classification No", key: "Book_Classification_No" },
  { label: "Scope", key: "Book_Scope" },
  { label: "Status", key: "Book_Status" },
  { label: "Rack", key: "RackValue" },
  { label: "Issued On", key: "issued_on" },
  { label: "Issued To", key: "username" }
];
