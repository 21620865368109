import React, { Component } from 'react';
import { Carousel } from "react-responsive-carousel";
import slider1 from '../images/slider/library1.jpg';
import slider2 from '../images/slider/library2.jpg';
import slider3 from '../images/slider/library3.jpg';
import slider4 from '../images/slider/library4.jpg';
import slider5 from '../images/slider/library5.jpg';
import mslider1 from '../images/slider/m1.jpg';
import mslider2 from '../images/slider/m2.jpg';
import mslider3 from '../images/slider/m3.jpg';
import mslider4 from '../images/slider/m4.jpg';
 
export default class SliderBN extends Component {

  
    render() {
     
        return (
            <div className="menu-slider">
               <Carousel className='navbar-bg' autoPlay infiniteLoop={true} showThumbs={false} showStatus={false} interval={4000} stopOnHover={false}>

        <div >
        
         <img src={slider1} alt="1" />
    
    </div>
    <div>
    <img src={slider2} alt="2" />
   </div >
    <div >
    <img src={slider3} alt="3" />
    </div>
    <div>
    <img src={slider4} alt="4" />
    </div>
    <div>      
    <img src={slider5} alt="5" />
    </div>
    
  </Carousel>
  <Carousel className='navbar-mobile' autoPlay infiniteLoop={true} showThumbs={false} showStatus={false} interval={4000} stopOnHover={false}>

        <div >
        
         <img src={mslider1} alt="1" />
    
    </div>
    <div>
    <img src={mslider2} alt="2" />
   </div >
    <div >
    <img src={mslider3} alt="3" />
    </div>
    <div>
    <img src={mslider4} alt="4" />
    </div>
   
    
  </Carousel>
            </div>
        )
    }
}
