import React, { useContext } from 'react';
import { ProductContext } from '../../ProductProvider';
import Snapshot from './Snapshot';
import Sidebar from './Sidebar';

function DashboardMob() {
    const {loggedUser} = useContext(ProductContext);
        return (
            <div className="dash-board">
                {loggedUser.isAuthenticated?
                    
            <div>        
           {/*} <div className="row " style={{margin:'0px'}}>
                <div className="sidebar-mobile">
                    <Navbar className="navigation__navbar" light expand="md">
                     
                               
                      <NavbarToggler onClick={toggleNavbar}  />
                        <Collapse isOpen={!collapsed} navbar>   
                        <Nav className="ml-auto" navbar> 
                        <NavItem onClick={toggleNavbar}>
                         
                        {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Add", event)} className="list-group-item"><i className="fa fa-plus fa-fw" aria-hidden="true"></i>&nbsp;Add Book</Link>
                    :  ""}
                        
                        
                      </NavItem>      
                  <NavItem onClick={toggleNavbar}>                            
                  {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    
                    <Link to="/#" onClick={(event)=>handleShow("Edit", event)} className="list-group-item" ><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Book</Link>
                    :""}
                
                  </NavItem>
                  <NavItem onClick={toggleNavbar}>
                       
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   
                   <Link to="/#" onClick={(event)=>handleShow("Issue", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Book</Link>
                   :""}
                    </NavItem>
                    <NavItem onClick={toggleNavbar}>
                       
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   <Link to="/#" onClick={(event)=>handleShow("Return", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Book</Link>
                   :""}
                       </NavItem>

                    

                    <NavItem onClick={toggleNavbar}>
                    {loggedUser.isAuthenticated?
                   
                    
                   <Link to="/#" onClick={(event)=>handleShow("Search", event)} className="list-group-item" ><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Book</Link>
                   :""}
                    </NavItem>
                    <NavItem onClick={toggleNavbar}>
                    {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleShow("MyBook", event)} className="list-group-item" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</Link>
                    :""}
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleShow("MyProfile", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</Link>
                    
                    :""} 
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                  
                  <Link to="/#" onClick={(event)=>handleShow("ManageUser", event)} className="list-group-item" ><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage User</Link>
                 :""}
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleSignout(event)} className="list-group-item" ><i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp; Sign Out</Link>
                    :""}
                        </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
               
            </div> */}
             {/*} <div className="row " style={{margin:'0px'}}>*/}
             <div className="navbar-mobile">
       
            </div>
                <div className="col-12 col-sm-12 col-lg-12" style={{marginTop:'0px', marginBottom:'50px'}}>
                    <Snapshot />
                
                </div>
              
            </div>
            :<div>You are NOT Signed in to access the Dashboard. Please Sign In.</div>}
            </div>
        )
    
}
export default DashboardMob;
