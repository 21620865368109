import React from 'react';
import LibrarySlider from './LibrarySlider';
//import '../css/bootstrap.min.css';
//import '../css/animate.css';
//import '../css/Styles.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function Home() {
    return (
        <div className="home">
        <div className="slider">
     <LibrarySlider />
        </div>
        </div>
    )
}
