import React from 'react';
//import {useHistory} from 'react-router-dom';
//import { Auth } from "aws-amplify";
//import AddBooks from '../books/AddBooks';
//import SearchBooks from '../books/SearchBooks';
//import EditBooks from '../books/EditBooks';
//import avatar from '../../images/avatar.png';

//import IssueBooks from '../books/IssueBooks';
//import MyBooks from '../user/MyBooks';
//import MyProfile from '../user/MyProfile';
//import ManageUser from '../user/ManageUser';
//import { ProductContext } from '../../ProductProvider';
//import SearchUser from '../user/SearchUser';
import DashboardView from './DashboardView';
import DashboardMob from './DashboardMob';

function MainDashboard() {
    /*let history = useHistory();
    const {loggedUser, setLoggedUser} = useContext(ProductContext);
    const [showAdd, setShowAdd] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const [showSearch, setShowSearch] = useState(true);
    const [showIssue, setShowIssue] = useState(false);
    const [showMyBooks, setShowMyBooks]=useState(false);
    const [showMyProfile, setShowMyProfile] = useState(false);
    const [showManageUser, setShowManageUser] = useState(false);*/
    
   /* const handleShow =(value, event) =>{
        event.preventDefault();
            setShowAdd(false);
            setShowEdit(false);
            setShowSearch(false);
            setShowIssue(false);
            setShowMyBooks(false);
            setShowMyProfile(false);
            setShowManageUser(false);

        if(value==="Add"){
            setShowAdd(true);
        }else if(value==="Edit"){
            setShowEdit(true);
        }else if(value==="Search"){
            setShowSearch(true);
        }else if(value==="Issue"){
            setShowIssue(true);
        }else if(value==="MyBook"){
            setShowMyBooks(true);
        }else if(value==="MyProfile"){
            setShowMyProfile(true);
        }else if(value==="ManageUser"){
            setShowManageUser(true);
        }
    }

    const handleSignout = (event) =>{
        event.preventDefault();
        try{
           Auth.signOut().then((data) => {
             setLoggedUser({...loggedUser,
               username:'',
               email:'',
               phoneno:'',
               familyName:'',
               profile:'',
               name:'',
               isAuthenticated:false
             });
             history.push("/signin");
           })
         }catch(error){
           console.log(error.message);
         }
    }*/
    
        return (
            <div className="dash-board">
                <div className="navbar-bg">
                    <DashboardView />
                </div>
               
               <div className="navbar-mobile">
                   
                   <DashboardMob />
               </div>
               
            </div>
        )
    
}
export default MainDashboard;
