import React, { useContext, useEffect, useState} from 'react';
import {Button,Card,CardBody,CardHeader,Col,FormGroup,Input,Label,Row,InputGroup} from 'reactstrap';
import { Table } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { phoneCode } from '../../BookData';
import { ProductContext } from '../../ProductProvider';

//import SearchBookDisplay from '../books/SearchBookDisplay';
import useFullPageLoader from "../hooks/useFullPageLoader";
import Sidebar from '../UserDashboard/Sidebar';
//import UserView from './UserView';
const config = require('../../config.json');

export default function SearchUser() {
    const {loggedUser} = useContext(ProductContext);
    const [dispMsg, setDispMsg] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [searchOption, setSearchOption] = useState('userfullname');
    const [searchUserResult, setSearchUserResult] = useState('');
    const [buttonValue, setButtonValue] = useState('Edit');
    const [isEditMode, setIsEditMode] = useState(true);
    const [userDetails, setUserDetails] = useState({loginid:'',userfullname:'',emailid:'',phoneno:'',user_role:'',account_status:''});
    const [bookResults, setBookResults] = useState('');
    const [allUsers, setAllUsers] = useState('');
    const [countryCode, setCountryCode]=useState("+91");
    const [loader, showLoader, hideLoader] = useFullPageLoader();


    useEffect(() => {
   
      window.scrollTo(0,0);
      setButtonValue('Edit');
      
  }, [])

    const handleusersearch = async(event) => {
        event.preventDefault();
        setDispMsg("");
        setSearchUserResult("");
        setUserDetails({loginid:'',userfullname:'',emailid:'',phoneno:'',user_role:'',account_status:''})
        setBookResults('');
        setAllUsers('');
       const session = await Auth.currentSession();
       const access_token=session.idToken.jwtToken;
         if(searchValue==="")
         {
           setDispMsg("Enter the value to search");
         }else{
          showLoader();
         try {   
          var searchInput=searchValue; 
          if(searchOption==="userfullname"){
          const str = searchValue;
          const arr = str.split(" ");
          //loop through each element of the array and capitalize the first letter.
          for (var i = 0; i < arr.length; i++) {
              arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

          }
          //Join all the elements of the array back into a string 
          //using a blankspace as a separator 
          searchInput = arr.join(" ");
          //console.log(str2);
        }
            // console.log("Params:", searchParams);
            await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${searchInput}&searchoption=${searchOption}&familyName=${loggedUser.familyName}`,{
             headers: {
               Authorization: access_token,
               'x-api-key':config.api.key
             }
           })
           .then((response)=>{
             //console.log("Response : ", response);
            if(response.status===205){
                setDispMsg("User does not exist. Enter the right details.");
               
            }else if(response.status===210){
                //setDispMsg("Multiple Users Found. Enter the right details.");
                setDispMsg(response.data.length + " - User's found");
                setAllUsers(response.data);
  
            }else if(response.status===215){
              setDispMsg(response.data.length + " - User's found");
              setAllUsers(response.data);
              //console.log("Response Received : ", response);
          }
            else{
                setUserDetails({...userDetails,
                loginid:response.data[0].user[0].loginid,
                userfullname:response.data[0].user[0].userfullname,
                emailid:response.data[0].user[0].emailid,
                phoneno:response.data[0].user[0].phoneno,
                user_role:response.data[0].user[0].user_role,
                account_status:response.data[0].user[0].account_status
            });
            setCountryCode(response.data[0].user[0].countryCode);
            setDispMsg(searchValue + " - User details fetched.");
            setSearchUserResult(response.data[0].user);
            setBookResults(response.data[1].book);
            }
        })
        .catch((error)=>{
            setDispMsg("Error Occured" + error.message);
        })
                     
         } catch (error) {
           setDispMsg(`An error has occurred: ${error.message}`);
         }
         hideLoader();
       }
       }

       const handleUserSelection = async(event, userDetails) =>{
        event.preventDefault();
        //console.log("User Details : ", userDetails);
        showLoader();
        try {   
           // console.log("Params:", searchParams);
           const tempSearchOption="loginid";
           const session = await Auth.currentSession();
       const access_token=session.idToken.jwtToken;
           await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${userDetails}&searchoption=${tempSearchOption}&familyName=${loggedUser.familyName}`,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          })
          .then((response)=>{
            //console.log("Response : ", response);
              setUserDetails({...userDetails,
               loginid:response.data[0].user[0].loginid,
               userfullname:response.data[0].user[0].userfullname,
               emailid:response.data[0].user[0].emailid,
               phoneno:response.data[0].user[0].phoneno,
               user_role:response.data[0].user[0].user_role,
               account_status:response.data[0].user[0].account_status
           });
           setCountryCode(response.data[0].user[0].countryCode);
           setDispMsg(response.data[0].user[0].userfullname + " - User details fetched.");
           setSearchUserResult(response.data[0].user);
           setBookResults(response.data[1].book);
           setAllUsers('');
           
       })
       .catch((error)=>{
           setDispMsg(`Error Occured : ${error.message}`);
       })
                    
        } catch (error) {
          setDispMsg(`An error has occurred: ${error.message}`);
        }
        hideLoader();

        /*setUserDetails({...userDetails,
          loginid:userDetails.loginid,
          userfullname:userDetails.userfullname,
          emailid:userDetails.emailid,
          phoneno:userDetails.phoneno,
          user_role:userDetails.user_role,
          account_status:userDetails.account_status
      });
      const updatedProducts = [...allUsers].filter(searchresult => searchresult.loginid !== userDetails.loginid);
                  //console.log("updatedProducts : ", updatedProducts);
      setSearchUserResult(updatedProducts);
      
      //console.log("Length : ", searchUserResult, searchUserResult.length);
      setAllUsers('');*/
       }

    const handleUpdateUser = async (event) => {
      event.preventDefault();
      setDispMsg('');
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        if (loggedUser.username===userDetails.loginid)
        {
          setDispMsg("You cannot update your own account. Contact your Admin");
        }else{
          showLoader();
        try {
          const params = {
            "loginid": userDetails.loginid,
            "user_role":userDetails.user_role,
            "familyname":loggedUser.familyName
          };
          const userid=userDetails.loginid
          //console.log("processing update for email ID : ", this.state.loginid);
          await axios.patch(`${config.api.invokeUrl}/users/manage/${userid}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          });    
          setDispMsg(userid + " - Updated Successfully");
        }catch (err) {
            setDispMsg("Error Occured - " + err.message);
        }
        hideLoader();
      }
      }

      const handleDeleteUser = async (event) => {
        event.preventDefault();
        // add call to AWS API Gateway delete product endpoint here
       const userid=userDetails.loginid;
       const session = await Auth.currentSession();
       const access_token=session.idToken.jwtToken;
       setDispMsg('');

      if (loggedUser.username===userDetails.loginid)
      {
        setDispMsg("You cannot delete your own account. Contact your Librarian / Admin");
        }else{
      showLoader();
       try {
         if(bookResults.length>0){
          setDispMsg(userDetails.loginid + " has " +bookResults.length+" books assigned. Kindly return the books before deleting the account.");
         }else{
            await axios.delete(`${config.api.invokeUrl}/users/manage?loginid=${userid}&familyname=${loggedUser.familyName}`,{
              headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
              }
            }).then((response)=>{
              setUserDetails({loginid:'',userfullname:'',emailid:'',phoneno:'',user_role:'',account_status:''});
              setBookResults('');
              setDispMsg(userDetails.loginid + " - Account deleted Successfully");
            }).catch((error)=>{
              setDispMsg("Error Occured - " + error.message);

            });
          }
        }catch (err) {
            setDispMsg("Error Occured - " + err.message);
        }
        hideLoader();
      }
      }

      const handleUserEdit = event => {
        event.preventDefault();
        setDispMsg('');
        if (loggedUser.username===userDetails.loginid){
            setDispMsg("You cannot update your own account. Contact your Admin");
        }else{
            setButtonValue("Save");
            setIsEditMode(false);
            setDispMsg("");
        }
      }
    
      const handleEditSave = (event) => {
        event.preventDefault();
        setButtonValue("Edit");
        setIsEditMode(true);
        handleUpdateUser(event);
      }

      const handleSearchInput = (event) =>{
        //console.log("value selected : ", event.target.value);
        if(event==="CONFIRMED" || event==="Guest")
        {
          setSearchValue(event);
        }else{
          setSearchValue(event.target.value)
        }
        setUserDetails({loginid:'',userfullname:'',emailid:'',phoneno:'',user_role:'',account_status:''});
        setBookResults('');
        setButtonValue("Edit");
        setIsEditMode(true);
        

      }
 

    return (

<div className="dash-board" style={{paddingBottom:'80px'}}>
{loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

<div>
  {/*
        <div className="navbar-mobile">
      
        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px'}}>

        <div className="container">
       <div>
       
            <div className="navbar-mobile">
            <div className="container">   
                    <h4 className="text-title"><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage Users</h4>      
        <form onSubmit={(event) => handleusersearch(event)}>   
        <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
            <FormGroup check row>
            <div className="container">
            <div className="row">
            
                <div className="col-md-6 col-sm-6  col-xs-6">
                <Label>
                  <Input
                    type="radio"
                    name="searchoptionm"
                    id="loginid"
                    value="loginid"
                    checked={searchOption==="loginid"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                    />
                   Login ID</Label>
                </div>
                <div className="col-md-6 col-sm-6  col-xs-6">
                <Label>
                <Input
                    type="radio"
                    name="searchoptionm"
                    id="role"
                    value="role"
                   
                    checked={searchOption==="role"}
                    onChange={(event)=>{setSearchOption(event.target.value);
                      handleSearchInput("Guest")}}
                />

                 User Role</Label>
                  </div>
                </div>
                <div className="row">
                <div className="col-md-6 col-sm-6  col-xs-6">
                 <Label >
                <Input
                    type="radio"
                    name="searchoptionm"
                    id="userfullname"
                    value="userfullname"
                    checked={searchOption==="userfullname"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                User Name</Label>
                  </div>

                  <div className="col-md-6 col-sm-6  col-xs-6">
                <Label >
                <Input
                    type="radio"
                    name="searchoptionm"
                    id="email"
                    value="email"
                    checked={searchOption==="email"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                 Email</Label>
                  </div>
                  </div>
                <div className="row">
                  <div className="col-md-6 col-sm-6  col-xs-6">
                <Label>
                <Input
                    type="radio"
                    name="searchoptionm"
                    id="phoneno"
                    value="phoneno"
                   
                    checked={searchOption==="phoneno"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                 Phone No</Label>
                  </div>
                  <div className="col-md-6 col-sm-6  col-xs-6">
                <Label>
                <Input
                    type="radio"
                    name="searchoptionm"
                    id="confirmed"
                    value="confirmed"
                   
                    checked={searchOption==="confirmed"}
                    onChange={(event)=>{setSearchOption(event.target.value);
                      handleSearchInput("CONFIRMED")}}
                />

                 Active Users</Label>
                  </div>
                  </div>
              
                 </div>
                </FormGroup>
                <CardHeader> 
            {searchOption==="confirmed" ? <select id="active" onChange={(event)=>{handleSearchInput(event)}}>
  <option value="CONFIRMED">Confirmed</option>
  <option value="NOT CONFIRMED">Not Confirmed</option>
</select>:searchOption==="role"?<select id="active" onChange={(event)=>{handleSearchInput(event)}}>
  <option value="Guest">Guest</option>
  <option value="Student">Student</option>
  <option value="Staff">Staff</option>
  <option value="Librarian">Librarian</option>


</select>:<Input
                  type="text"
                  name="searchvalue"
                  id="searchvalue"

                  placeholder="Enter username to search..."
                  value={searchValue} 
    
                  onChange={(event)=>{handleSearchInput(event)}}
                  
                />}
                  <Button className="pull-center">Search User
                    </Button>

            </CardHeader>
                
            </CardBody>
            {dispMsg}
            </Card>
            {allUsers.length>0?<div>
        <Table hover responsive >
        
                <thead>
                  <tr>
                    <th>Login Id</th>
                    <th>User Name</th>
                    <th>Status</th>
                    <th>User Role</th>
                    <th>Email Id</th>
                    <th>Phone Number</th>
                  
                  </tr>
                </thead> 
              
                <tbody>
                {allUsers.map((userresult, index) => (
                <tr key={index}>
                  <th scope="row"><button onClick={(event)=>handleUserSelection(event, userresult.loginid)}>{userresult.loginid}</button></th>
                  <td>{userresult.userfullname}</td>
                  <td>{userresult.account_status} </td>
                  <td>{userresult.user_role} </td>
                  <td>{userresult.emailid}</td>
                  <td>{userresult.phoneno}</td>
                  
                </tr>))}
                </tbody>
                </Table>
                </div>:<div></div>}
        </Col>           
      </Row> 
      </form>
            <CardBody>
           <form> 
            <FormGroup>
            <div className="container">
            <div className="row">
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Label for="accessionNoL">Login ID</Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
              <Input
                    type="text"
                    name="Accession_No"
                    value={userDetails.loginid}
                    
                 //   onChange={(event)=>{setBook({...book,accessionNo:(event.target.value).toUpperCase()})}}
                    disabled
                  />
              </div>
              </div>
              <div className="row">
              
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Label for="bookTitleL"> Name </Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
              <Input
                    type="text"
                    name="Book_Title"
                    value={userDetails.userfullname}
                    disabled
              //      onChange={(event)=>{setBook({...book,bookTitle:(event.target.value).toUpperCase()})}}  
                  />
              </div>
              </div>
      
              
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Label for="bookAuthor">Email</Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
              <Input
                    type="text"
                    name="firstName"
                    value={userDetails.emailid}
                 //   onChange={(event)=>{setBook({...book,authorFirst:(event.target.value).toUpperCase()})}}
                    disabled
                  />
                  
              </div>
              </div>



              
              <div className="row">
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Label for="clsNo">Phone No</Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
              <Input
                    type="text"
                    name="Book_Classification_No"
                    id="clsId"
                    value={userDetails.phoneno}
                    //onChange={(event)=>{setBook({...book,bookClassificationNo:(event.target.value).toUpperCase()})}}
                    disabled
                  />
              </div>
              </div>
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Label for="scope">Role</Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
                  {loggedUser.profile==="Admin"?
              <Input type="select" name="Book_Scope"
                   value={userDetails.user_role}
                    onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
                   disabled={isEditMode}
                  >
                    <option>Guest</option>
                    <option>Student</option>
                    <option>Staff</option>
                    <option>Librarian</option>
                    <option>Admin</option>
            </Input>:
            <Input type="select" name="Book_Scope"
            value={userDetails.user_role}
             onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
            disabled={isEditMode}
           >
             <option>Guest</option>
             <option>Student</option>
             <option>Staff</option>
             <option>Librarian</option>
     </Input>}
              </div>
              </div>
              <div className="row">
              
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Label for="publicationL">Status</Label>
              </div>
              <div className="col-md-8 col-sm-8  col-xs-8">
              <Input
                    type="text"
                    name="Book_Publisher"
                    value={userDetails.account_status}
                   // onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value).toUpperCase()})}}
                    disabled
                  />
              </div>
              </div>
              </div>
                </FormGroup>
               
                {searchUserResult.length>0?
                <FormGroup check row>
                 
                 <div className="container">
            <div className="row">
            <div className="col-md-6 col-sm-6  col-xs-6">
                 
                  <button type="submit" 
               className="button-approve is-info is-small"
               onClick={event => {buttonValue==="Edit"?handleUserEdit(event):handleEditSave(event)}}
             >{buttonValue}</button>


            </div>
            <div className="col-md-6 col-sm-6  col-xs-6">
                 
            <button className="button-del is-info is-small" 
            onClick={event => handleDeleteUser(event)} 
            >Delete</button>
            </div>
            </div>
            </div>
                </FormGroup>:""}
              </form>
            </CardBody>

           
   {bookResults.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Accession</th>
                  <th>Book Title</th>
                  <th>Author</th>
                  <th>Classification</th>
                  <th>Scope</th>
                  <th>Status</th>
                  <th>Issued</th>
                </tr>
              </thead>      
              <tbody>
        {bookResults.map((searchresult,index) =>(
        <tr key={index}>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author} </td>
          <td>{searchresult.Book_Classification_No} </td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
      
        </tbody></Table>

            </div>
   :<div className="tile notification is-warning"></div>}  
         
            </div>
              </div>
              </div>
            
            <div>

            
            </div>
            {loader}
            </div>

          </div>
          </div>
        */}

          <div >

  <div className="row " style={{margin:'0px'}}>
                    <div className=" navbar-bg col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

        <div className="container">
       <div>
       <div >
       <div className="container">   

       
                    <h4 className="text-title"><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage Users</h4>      
        <form onSubmit={(event) => handleusersearch(event)}>  
        

        <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
            <FormGroup check row>
            <div className="container">
            
            <Row>
  <Col xs={12} lg={3}>
  <Label>Search By</Label>
  </Col>
    <Col xs={4} lg={3}> 
    <Label >
                <Input
                    type="radio"
                    name="searchoption"
                    id="userfullname"
                    value="userfullname"
                    checked={searchOption==="userfullname"}
                    onChange={(event)=>{setSearchOption(event.target.value);setSearchValue('')}}
                />

                User Name</Label>
  </Col>
  <Col xs={4} lg={3}> 
  <Label>
               

               <Input
                 type="radio"
                 name="searchoption"
                 id="loginid"
                 value="loginid"
                 checked={searchOption==="loginid"}
                 onChange={(event)=>{setSearchOption(event.target.value);setSearchValue('')}}
                 />
                Login ID</Label>
  </Col>
  <Col xs={4} lg={3}> 
  <Label >
                <Input
                    type="radio"
                    name="searchoption"
                    id="email"
                    value="email"
                    checked={searchOption==="email"}
                    onChange={(event)=>{setSearchOption(event.target.value);setSearchValue('')}}
                />

                 Email</Label>
  </Col>

   
    </Row> 
    <Row >
  <Col xs={12} lg={3}>
  <Label ></Label>
  </Col>
    <Col xs={4} lg={3}> 
    <Label>
                <Input
                    type="radio"
                    name="searchoption"
                    id="phoneno"
                    value="phoneno"
                    checked={searchOption==="phoneno"}
                    onChange={(event)=>{setSearchOption(event.target.value);setSearchValue('')}}
                />

                 Phone No</Label>
  </Col>
  <Col xs={4} lg={3}> 
  <Label>
                <Input
                    type="radio"
                    name="searchoption"
                    id="confirmed"
                    value="confirmed"
                   
                    checked={searchOption==="confirmed"}
                    onChange={(event)=>{setSearchOption(event.target.value);
                      handleSearchInput("CONFIRMED")}}
                />

                 Active Users</Label>
  </Col>
  <Col xs={4} lg={3}> 
  <Label>
                <Input
                    type="radio"
                    name="searchoption"
                    id="role"
                    value="role"
                   
                    checked={searchOption==="role"}
                    onChange={(event)=>{setSearchOption(event.target.value);
                      handleSearchInput("Guest")}}
                />

                 User Role</Label>
  </Col>
    </Row> 
                 </div>
                </FormGroup>
               
            {searchOption==="confirmed" ? <Row className='' >
  <Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
<Input id="activeStatus" name="select" type="select" onChange={(event)=>{handleSearchInput(event)}}>
  <option value="CONFIRMED">Confirmed</option>
  <option value="UNCONFIRMED">Un Confirmed</option>
</Input></Col>
  <Col xs={1} lg={3}><Button className="pull-center">
    <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                    </Button>
  </Col>
</Row>:searchOption==="role"?<Row className='' >
  <Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
<Input id="activeStatus" name="select" type="select"  onChange={(event)=>{handleSearchInput(event)}}>
  <option value="Guest">Guest</option>
  <option value="Student">Student</option>
  <option value="Staff">Staff</option>
  <option value="Librarian">Librarian</option>


  </Input></Col>
  <Col xs={1} lg={3}><Button className="pull-center">
    <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                    </Button>
  </Col>
</Row>:
<Row className='' >
  <Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
  <Input
                  type="text"
                  name="searchvalue"
                  id="searchvalue"
                  autoFocus="autofocus"
                  placeholder="Search here..."
                  value={searchValue} 
    
                  onChange={(event)=>{handleSearchInput(event)}}
                  
                />
  </Col>
  <Col xs={1} lg={3}><Button className="pull-center">
    <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                    </Button>
  </Col>
</Row>
}
                  

                
            </CardBody>
            {dispMsg}
            </Card>
           
  {allUsers.length>0?<div>
        <Table hover responsive >
        
                <thead>
                  <tr>
                    <th>Login Id</th>
                    <th>User Name</th>
                    <th>Status</th>
                    <th>User Role</th>
                    <th>Email Id</th>
                    <th>Phone Number</th>
                  
                  </tr>
                </thead> 
              
                <tbody>
                {allUsers.map((userresult, index) => (
                <tr key={index}>
                  <th scope="row"><button onClick={(event)=>handleUserSelection(event, userresult.loginid)}>{userresult.loginid}</button></th>
                  <td>{userresult.userfullname}</td>
                  <td>{userresult.account_status} </td>
                  <td>{userresult.user_role} </td>
                  <td>{userresult.emailid}</td>
                  <td>{userresult.phoneno}</td>
                  
                </tr>))}
                </tbody>
                </Table>
                </div>:<div></div>}
        </Col>           
      </Row> 
      </form>
           
           <form style={{paddingTop:'15px'}}> 
            <FormGroup>
            <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="accessionNoL">Login ID</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Accession_No"
                    value={userDetails.loginid}
                    
                 //   onChange={(event)=>{setBook({...book,accessionNo:(event.target.value).toUpperCase()})}}
                    disabled
                  />
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="bookTitleL"> Name </Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Book_Title"
                    value={userDetails.userfullname}
                    disabled
              //      onChange={(event)=>{setBook({...book,bookTitle:(event.target.value).toUpperCase()})}}  
                  />
              </div>
              </div>
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="bookAuthor">Email</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="firstName"
                    value={userDetails.emailid}
                 //   onChange={(event)=>{setBook({...book,authorFirst:(event.target.value).toUpperCase()})}}
                    disabled
                  />
                  
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="clsNo">Phone No</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
             
                  <InputGroup>
                 
      <select  value={countryCode}                         disabled
                      onChange={(event)=>{setCountryCode(event.target.value)}}>
                      
                        
                          {phoneCode.map(item => {
                              return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                          })}
                            </select>
                            <Input
                    type="text"
                    name="Book_Classification_No"
                    id="clsId"
                    value={userDetails.phoneno}
                    //onChange={(event)=>{setBook({...book,bookClassificationNo:(event.target.value).toUpperCase()})}}
                    disabled
                  />
                 
                 
                  </InputGroup>
       
           
                   
              
              </div>
              </div>
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="scope">Role</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
                  {loggedUser.profile==="Admin"?
              <Input type="select" name="Book_Scope"
                   value={userDetails.user_role}
                    onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
                   disabled={isEditMode}
                  >
                    <option>Guest</option>
                    <option>Student</option>
                    <option>Staff</option>
                    <option>Librarian</option>
                    <option>Admin</option>
            </Input>:
            <Input type="select" name="Book_Scope"
            value={userDetails.user_role}
             onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
            disabled={isEditMode}
           >
             <option>Guest</option>
             <option>Student</option>
             <option>Staff</option>
             <option>Librarian</option>
     </Input>}
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="publicationL">Status</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Book_Publisher"
                    value={userDetails.account_status}
                   // onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value).toUpperCase()})}}
                    disabled
                  />
              </div>
              </div>
              </div>
                </FormGroup>
               
                {searchUserResult.length>0?
                <FormGroup check row className='text-center'>
               
                  <Col xs={6} lg={6} >
                  <button type="submit"  style={{width:'80px', height:'40px'}}
               className="button-approve is-info is-small"
               onClick={event => {buttonValue==="Edit"?handleUserEdit(event):handleEditSave(event)}}
             >{buttonValue}</button>
                  </Col>
                  <Col xs={6} lg={6}>
                   <button className="button-del is-info is-small bg-danger"  style={{width:'80px', height:'40px'}}
            onClick={event => handleDeleteUser(event)} 
            >Delete</button>
                  </Col>
                 
                </FormGroup>:""}
              </form>
           

                  {bookResults.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Accession</th>
                  <th>Book Title</th>
                  <th>Author</th>
                  <th>Classification</th>
                  <th>Scope</th>
                  <th>Status</th>
                  <th>Issued</th>
                </tr>
              </thead>      
              <tbody>
        {bookResults.map((searchresult,index) =>(
        <tr key={index}>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author} </td>
          <td>{searchresult.Book_Classification_No} </td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
      
        </tbody></Table>

            </div>:<div className="tile notification is-warning"></div>}  
         
            </div>
            </div>

              </div>
            
            <div>

            
            </div>
            {loader}
            </div>
    </div>
        </div></div>
        
        
        </div>
        
        
  
        
        :<div>"You are not authorised to view this page"</div>}
        </div>
    )
}
