import React, { useEffect, useContext } from 'react';
//import axios from "axios";
import { Auth } from "aws-amplify";
import {Col} from 'reactstrap';

import manageuser from '../../images/manageuser.gif';
import profileicon from '../../images/profile.gif';
import chngpass from '../../images/passchng.gif';
import logout from '../../images/logout.gif';



//import snap from '../../images/bg.gif'
import useFullPageLoader from "../hooks/useFullPageLoader";
import { ProductContext } from '../../ProductProvider';
import { Link, useHistory } from 'react-router-dom';

//import MyAccounts from './MyAccounts';
//import {temp_client_accounts,tempClients} from '../../packageDetails';
//const config = require('../../config.json');

export default function QuickProfile(props) {
    let history = useHistory();
    const { loggedUser, setLoggedUser, setShowPage } = useContext(ProductContext);
    const [loader] = useFullPageLoader();
    //const [dispMsg, setDispMsg] = useState('');
    //const [myAccounts, setMyAccounts] = useState('');
    useEffect(() => {
        setShowPage(false);
        window.scrollTo(0,0);
        
    }, [])
   

    /*const assignTempValues = async() =>{
        const tempHost = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WH'));
        const tempDevelop = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WD'));
        const tempEmail = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-EH'));
        setAllClientAccounts(temp_client_accounts);
        setWebDevelopAccounts(tempDevelop);
        setWebHostAccoAunts(tempHost);
        setEmailAccounts(tempEmail);
    }*/

    const handleShow = (value,event) => {
        event.preventDefault();
        if(loggedUser.isAuthenticated){
            if (value==="MyProfile") {
                    history.push("/my-profile");
                } else if (value==="ChangePassword") {
                    history.push('/change-password');
                } else if (value==="ManageUsers") {
                    history.push('/manage-user');
                } 
            }else{
                history.push("/signin");
            }
    }

    const handleSignOut = async(event)=>{
        event.preventDefault();
        //console.log("Clicked");
        try{
            await Auth.signOut().then(() => {
                setLoggedUser({...loggedUser,
                    username:'',
                    email:'',
                    phoneno:'',
                    familyName:'',
                    name:'',
                    profile:'',
                    isAuthenticated:false
                });
                history.push("/");
              })        
            }catch(error){
           // console.log("error ", error);
        }
    }


    /*const fetchUserAccounts = async () => {
        setDispMsg("");
        //setMyAccounts("");
        setAllClientAccounts("");
        setWebDevelopAccounts("");
        setWebHostAccoAunts("");
        setEmailAccounts("");
        showLoader();
        try {
            const session = await Auth.currentSession();
            const access_token = session.idToken.jwtToken;
            const clientid = loggedUser.clientID;
            //console.log("Client ID : ",clientid);
            //const clientid=tempClientid.substring(1, tempClientid.length);
            await axios.get(`${config.api.invokeUrl}/client/accounts/${clientid}`, {
                headers: {
                    Authorization: access_token,
                    'x-api-key': config.api.key
                }
            })
                .then((response) => {
                    //console.log("response: ", response);
                    if (response.data.length>0){
                        setAllClientAccounts(response.data);
                        //setSelectedAccounts(response.data);
                        const tempHost = response.data.filter(plan => plan.packageId.includes('IDN-WH'));
                        const tempDevelop = response.data.filter(plan => plan.packageId.includes('IDN-WD'));
                        const tempEmail = response.data.filter(plan => plan.packageId.includes('IDN-EH'));
                        setWebDevelopAccounts(tempDevelop);
                        setWebHostAccoAunts(tempHost);
                        setEmailAccounts(tempEmail);
                    }
                })
                .catch((error) => {
                    setDispMsg("Error Occured - " + error.message);
                })

        } catch (error) {
            setDispMsg("Error Occured - " + error.message);
        }
        hideLoader();
    }*/

    return (
        <div className="dash-board" style={{paddingTop:"10px", paddingBottom:"60px"}}>
        <h4 className="text-title"><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</h4>

            <div style={{textAlign:"center"}}> {loader} </div>
               
                <div>      
                      
               <Col sm={4}>
                  <Link to="#" onClick={(event)=>{handleSignOut(event)}} ><div className="card" style={{borderRadius:"4px"}}>
                 <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={logout} alt="Avatar" />
                 {/*  
                  <h5 style={{textAlign:"center", paddingTop:"15px", height:"60px"}}><b>Sign Out </b></h5> */}
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                     </div> 
                   </div></Link>
                </Col>          
                 <Col sm={4}>
                  <Link to="#" onClick={(event)=>handleShow("MyProfile",event)} style={{paddingBottom:"8px"}}><div className="card " style={{borderRadius:"4px"}}>
                <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={profileicon} alt="Avatar" />
                
                {/* 
                  <h5 style={{textAlign:"center", paddingTop:"15px", height:"60px"}}><b>My Profile</b></h5> */}
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      </div> 
                   </div></Link>
               </Col>
             
                <Col sm={4} style={{paddingBottom:"60px"}}>
                  <Link to="#" onClick={(event)=>handleShow("ChangePassword",event)} style={{paddingBottom:"8px"}}><div className="card" style={{borderRadius:"4px"}}>
                 <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={chngpass} alt="Avatar" />
                  {/* 
                  <h5 style={{textAlign:"center", paddingTop:"6px", height:"60px"}}><b>Change Password</b></h5> */}
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      </div> 
                   </div></Link>
                   </Col>
                   {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

                   <Col sm={4} style={{paddingBottom:"60px"}}>
                  <Link to="#" onClick={(event)=>handleShow("ManageUsers",event)} style={{paddingBottom:"8px"}}><div className="card" style={{borderRadius:"4px"}}>
                 <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={manageuser} alt="Avatar" />
                   
                  <h5 style={{textAlign:"center", paddingTop:"6px", height:"60px"}}><b>Manage Users</b></h5> 
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      </div> 
                   </div></Link>
                   </Col>:null}
                </div>
      
                </div>
    )
}
