import React from 'react';
import { Progress } from 'reactstrap';

function ProgressBarStatus(props) {
  const {progressValue, dispValue} = props;
    
    return (
        <div  >
       
       
        <Progress multi style={{border:'2px solid blue', height:'30px'}}>
        
          {dispValue==="Profile"?<Progress
            animated
            bar
            color={progressValue<40?"warning":progressValue<80?"info":"success"}
            value={progressValue}
          >
          {dispValue} {progressValue} % Completed 
          </Progress>:<Progress
            animated
            bar
            color={progressValue<26?"danger":progressValue<51?"warning":progressValue<76?"info":"success"}
            value={progressValue}
          >
          {dispValue} - {progressValue===100?"Completed":"In Progress"}
          </Progress>}
        
        </Progress>
      </div>
     )
}

export default ProgressBarStatus;
