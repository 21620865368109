import React from 'react'

export default function ChangePasswordConfirm() {
    return (
        <section className="section auth">
        <div className="container">
          <h1>Change Password</h1>
          <p>Your password has been successfully updated!</p>
        </div>
      </section>
    
    )
}
