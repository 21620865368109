import React, { useContext, useState } from 'react';
import signImg from '../../images/auth.gif';
import { Auth } from "aws-amplify";
import {ProductContext} from '../../ProductProvider';
import { useHistory } from "react-router-dom";

function SignIn() {
  let history = useHistory();
  const {loggedUser, setLoggedUser} = useContext(ProductContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [dispMsg, setDispMsg] = useState('');

  const handleSubmit = async event => {
    event.preventDefault();
    try{
      setDispMsg('');
      if(username==="" || password===""){
        setDispMsg("Fill in user name and password to login.");

      }else{
        await Auth.signIn(username, password)
        .then((user)=>{
          setLoggedUser({...loggedUser,
            username:user.username,
            email:user.attributes['email'],
            phoneno:user.attributes['phone_number'],
            familyName:user.attributes['family_name'],
            name:user.attributes['name'],
            profile:user.attributes['profile'],
            isAuthenticated:true
          });
          history.push("/dashboard");
        })
        .catch((error) =>{
          setDispMsg(error.message);
        })
      }
      
 } catch (error){
    setDispMsg(error.message);
   }
};
  
    return (
      <div>

     
      
        <div className="popup-container" style={{paddingTop:'0px', paddingBottom:'60px'}}>

       
          <div className="login-container">
          <h1 className="header-text">Log In</h1>
          <div className="avatar-icon">
            <img className="signupimg" src={signImg} alt="Sign Up"/> 
          </div>
          
<div className="d-flex justify-content-center">
<form  className="col-8" onSubmit={handleSubmit}>
 <div className='text-center'>
   
 <input 
  className="input " 
  type="text"
  id="username"
  aria-describedby="usernameHelp"
  placeholder="Enter username or email"
  value={username}
  onChange={(event)=>setUsername(event.target.value)}
/>


<input 
  className="input" 
  type="password"
  id="password"
  placeholder="Password"
  value={password}
  onChange={(event)=>setPassword(event.target.value)}
/>

   </div>           


<a style={{marginLeft: '15px'}} href="/forgotpassword">Forgot password?</a>
<br/>
<a style={{marginLeft: '15px'}} href="/signup">New User?</a>
<div className="field">
              <p className="container" style={{color:'white'}}>
                {dispMsg}
                </p>
            </div>
<div className="col">

     
     
<div className = "pull-right">

<button className="button-is-success">
<b>Sign In</b> 
</button>




</div>
</div>


</form>
</div>
      
          </div>
      
        </div>
 
      </div>
    );
  }


export default SignIn;