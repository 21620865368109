import React, { useContext } from 'react';
import { ProductContext } from '../../ProductProvider';
import Sidebar from './Sidebar';
import Snapshot from './Snapshot';
function DashboardView() {
    const {loggedUser} = useContext(ProductContext);
    
    
        return (
            <div className="dash-board">
            {loggedUser.isAuthenticated?
  <div className="row " style={{margin:'0px'}}>
                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

            {/*}    <div className="col-3 col-lg-3 con-test">
                <br/>
              <div className="row " style={{position:'flex', justifyContent:'center'}}>
                            
               <h4 className="text-title" style={{color:'white'}}> <i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; {loggedUser.profile} Dashboard </h4>
              
                </div> 
                <br/>
                <div className="list-group">
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Add", event)} className="list-group-item"><i className="fa fa-plus fa-fw" aria-hidden="true"></i>&nbsp;Add Book</Link>
                    :  ""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Edit", event)} className="list-group-item" ><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Book</Link>
                    :""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Issue", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Book</Link>
                    :""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   <Link to="/#" onClick={(event)=>handleShow("Return", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Book</Link>
                   :""}
                   
                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("Search", event)} className="list-group-item" ><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Book</Link>
                    :""}

                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("MyBook", event)} className="list-group-item" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</Link>
                    :""}

                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("MyProfile", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</Link>
                    :""} 
                    
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("ManageUser", event)} className="list-group-item" ><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage User</Link>
                   :""}

                    {loggedUser.isAuthenticated? 
                    <Link to="/#" onClick={(event)=>handleSignout(event)} className="list-group-item" ><i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp; Sign Out</Link>
                    :""}
                   
                </div>
                    
                   
                    
                    </div>*/}
                   
                <Snapshot />
                </div>
              
            </div>
            :<div>You are NOT Signed in to access the Dashboard. Please Sign In.</div>}
            </div>
        )
    
}
export default DashboardView;
