import React, { useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row} from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import useFullPageLoader from "../hooks/useFullPageLoader";
import {ProductContext} from '../../ProductProvider';
import { bookClassification } from '../../BookData';
import Sidebar from '../UserDashboard/Sidebar';
const config = require('../../config.json');

function EditBooks() {
    const{loggedUser}=useContext(ProductContext);
    const [searchValue, setSearchValue] = useState('');
    const [searchOutputMsg, setSearchOutputMsg] = useState('');
    const [isEditMode, setIsEditMode] = useState(true);
    const [dispMsg, setDispMsg] = useState('');
    const [buttonValue, setButtonValue] = useState('Edit');
    const [searchResult, setSearchResult] = useState('');
    const [book, setBook]=useState({accessionNo:'', authorTitle:'', bookAuthor:'', authorFirst:'', authorSecond:'', bookClassificationNo:'', bookPublisher:'', publishingYear:'', bookTitle:'',price:'', bookScope:'', bookStatus:'', firstName:'',lastName:''})
   // const [editDisabled, setEditDisabled] = useState(true);
   // const [tempAccNo, setTempAccNo] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();



    const handlebooksearch = async(event) => {
        event.preventDefault();
      //  this.resetFields();
        setDispMsg('');
        setSearchOutputMsg("");
        if(searchValue===""){
          setDispMsg("Enter the Accession No to search!");
        } else{   
        try {
          showLoader();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          //const res = await axios.get(`${config.api.invokeUrl}/books/manage?Accession_No=${searchValue}&familyName=${loggedUser.familyName}`,{
            const searchOption="Accession";
            const res = await axios.get(`${config.api.invokeUrl}/books/search?searchValue=${searchValue}&searchOption=${searchOption}&familyName=${loggedUser.familyName}`,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          });
          if (res.data.length>0) 
          {
           // console.log("response : ", res);
            setSearchResult(res.data);
            const name=res.data[0].Book_Author;
            
            const secondName = name.substr(0, name.indexOf(" "));
            const firstName = name.substr(name.indexOf(" ")+1, name.length);
            //setTempAccNo(res.data[0].Accession_No);
           // const classificationValue=res.data[0].Book_Classification_No;
            //const tempClassificationNo=[...bookClassification].filter(searchresult =>searchresult.value===classificationValue);
            //console.log("tempClassificationNo : ", tempClassificationNo);
            //const updatedProducts = [...displayBookResults].filter(searchresult => searchresult.Accession_No !== Accession_No);

            setBook({...book,
                accessionNo:res.data[0].Accession_No,
                bookAuthor:res.data[0].Book_Author,
                authorFirst:firstName,
                authorSecond:secondName,
                bookTitle:res.data[0].Book_Title,
                bookClassificationNo:res.data[0].Book_Classification_No,
                bookPublisher:res.data[0].Book_Publisher,
                bookScope:res.data[0].Book_Scope,
                publishingYear:res.data[0].Publishing_Year,
                price:res.data[0].price,
                bookStatus:res.data[0].Book_Status,
                firstName:res.data[0].First_Name,
                lastName:res.data[0].Last_Name
            });
          }
          else{
            setBook({ accessionNo:"", authorTitle: "", bookAuthor: "", authorFirst:"", authorSecond:"", bookClassificationNo: "", bookPublisher: "", bookScope: "CIRCULATION" , bookTitle: "", publishingYear:"", price:"", firstName:"",lastName:""});
            setSearchOutputMsg("No Records Found");
          }
        } catch (error) {
            setDispMsg(error.message);
        }
        hideLoader();
      }
      }

      const handleUserEdit = event => {
        event.preventDefault();
        setButtonValue("Save");
        setIsEditMode(false);
        setDispMsg("");
      }
    
      const handleEditSave = (event) => {
        event.preventDefault();
        setButtonValue("Edit");
        setIsEditMode(true);
        handleUpdateBook(event);
      }

      const generateOrderdate=()=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        const orderDate = temp.concat(date_year,"-",month_value,"-", date_value);
        return orderDate;
    }


      const handleUpdateBook = async (event) => {
        event.preventDefault();
        if(book.bookClassificationNo==="100"){
          setDispMsg("Select the right Book Classification Number!");
        }else{
        try {
          showLoader();
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const updateDate = generateOrderdate();
          setDispMsg("");
           const Accession_No=book.accessionNo;
           const authorTitle = (book.authorSecond + " " + book.authorFirst + " ## " + book.bookTitle);
           const action="Edit";
           var priceTemp=0;
           if(book.price===undefined){
             priceTemp=0;
           }else{
             priceTemp=book.price;
           }
           const params = {
                "Accession_No": book.accessionNo.toUpperCase(),
                "Book_Author": (book.authorSecond + " " + book.authorFirst).toUpperCase(),
                "First_Name":book.authorFirst.toUpperCase(),
                "Last_Name":book.authorSecond.toUpperCase(),
                "Author_Title": authorTitle.toUpperCase(),
                "Book_Classification_No": book.bookClassificationNo,
                "Book_Publisher": book.bookPublisher.toUpperCase(),    
                "Book_Scope": book.bookScope,
                "Book_Title": book.bookTitle.toUpperCase(),
                "Publishing_Year":book.publishingYear,
                "PK": loggedUser.familyName,
                "updated_on": updateDate,
                "updatedby": loggedUser.username,
                "price":priceTemp,
                "Book_Status":book.bookStatus,
                "action":action
            };
            //console.log("Params : ", params);
          await axios.patch(`${config.api.invokeUrl}/books/manage/${Accession_No}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          });       
//          this.resetFields();
          setDispMsg(Accession_No +" - Updated Successfully");
        }catch (err) {
          setDispMsg(err.message);
        }
        hideLoader();
      }
      }

   /*   const handleAccesionNoValidation = async(event)=>{
        event.preventDefault();
        try{
          if(book.accessionNo===tempAccNo){
            setEditDisabled(!editDisabled);
          }else{
          const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const bookQuery=book.accessionNo;
          const searchOption="AccessionNo";
          const res = await axios.get(`${config.api.invokeUrl}/books/search?searchValue=${bookQuery}&searchOption=${searchOption}&familyName=${loggedUser.familyName}`,{
              headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
              }
          });
          if(res.data.length>0)
          {
            setDispMsg("Accession Number already exists. Try again with different one");
          }else{
            setEditDisabled(!editDisabled);
          }
          //console.log("Response : ", res);
        }
        }catch(error){
          setDispMsg("Error Occured - ", error.message);
        }
      }*/

   /*   const handleAccEdit =(event)=>{
        event.preventDefault();
        setEditDisabled(!editDisabled);
      }*/
    
      const handleBookScopeSelection =async(event)=>{
        //console.log("Event value : ", event.target.value);
        if(book.bookStatus==="Issued"){
          setDispMsg("Book is currently in Issued Status. Return the book, before making changes to Book Scope");
        }else {
          if(event.target.value==="NOT Available"){
          await setBook({...book,bookScope:event.target.value, bookStatus:event.target.value});
        }else if(event.target.value==="CIRCULATION" || event.target.value==="REFERENCE"){
          await setBook({...book,bookScope:event.target.value, bookStatus:"Available"});
        }
      }
      }

      const handleCancelClick = async(event) =>{
        event.preventDefault();
       // setBookuseState({accessionNo:'', authorTitle:'', bookAuthor:'', authorFirst:'', authorSecond:'', bookClassificationNo:'', bookPublisher:'', publishingYear:'', bookTitle:'',price:0, bookScope:'', bookStatus:''});
        setIsEditMode(true);
        setButtonValue("Edit");
      }

  /*   const handleDeleteBook = async(event) => {   
        event.preventDefault();
         const session = await Auth.currentSession();
          const access_token=session.idToken.jwtToken;
          const Accession_No=book.accessionNo;
         try {
          
          //await axios.delete(`${config.api.invokeUrl}/books/${accession_no}`, params);
        //  await axios.delete(`${config.api.invokeUrl}/books/manage?Accession_No=${Accession_No}&familyname=${loggedUser.familyName}`,{
            const action="Delete";
            const params = {
                "Accession_No": book.accessionNo,
                "PK": loggedUser.familyName,
                "action":action
            };
            await axios.patch(`${config.api.invokeUrl}/books/manage/${Accession_No}`, params,{
             headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }
          });
         // this.resetFields();
         setBook({accessionNo:'', authorTitle:'', bookAuthor:'', authorFirst:'', authorSecond:'', bookClassificationNo:'', bookPublisher:'', publishingYear:'', bookTitle:'',price:'', bookScope:''});
          setDispMsg(Accession_No +" - Marked InActive");
         } catch (error) {
          setDispMsg(error.message);
         }
    
      }*/

    return (
<div className="dash-board" style={{paddingBottom:'80px'}} >
{loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
<div>
<div className="navbar-mobile">
    
        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px', paddingBottom:'20px'}}>

        <div >

  <h4 className="text-title"><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Books</h4>
<Row>
<Col xl={12} lg={12} md={12}>
<Card>
<form onSubmit={event => handlebooksearch(event)}>
<CardHeader>
  
<input type="text" className="search-input-ed" 
placeholder="Enter Accession No"  
name="Search_Value"
autoFocus="autofocus"
value={searchValue}          
onChange={(event)=>{setSearchValue((event.target.value))}}/>

<button type="submit" className="searchButton">
<i className="fa fa-search" /> 
</button>
   
</CardHeader>
<h3>  {searchOutputMsg} </h3>


</form>
<CardBody>
<form> 



    {/*--Mobile View Begins--*/}

    <div className="navbar-mobile">
<FormGroup>
<div >
<div className="row">{/*
<div className="col-md-4 col-sm-4  col-xs-4">
  
<Label for="accessionNoL">Accession No</Label> 
  </div>*/}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="text"
        name="Accession_No"
        value={book.accessionNo}
        placeholder="Accession Number"
        onChange={(event)=>{setBook({...book,accessionNo:(event.target.value)})}}
        disabled
      />
  </div>
  </div>
  <div className="row">
    {/*
  <div className="col-md-4 col-sm-4  col-xs-4">
  <Label for="bookTitleL">Book Title</Label>
    </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="text"
        name="Book_Title"
        placeholder="Book Title"
        value={book.bookTitle}
        disabled={isEditMode}
        onChange={(event)=>{setBook({...book,bookTitle:(event.target.value)})}}  
      />
  </div>
  </div>
  </div>
    </FormGroup>
  <FormGroup>                
    <div >
<div className="row">
  {/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="bookAuthor">Author</Label>
  </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="text"
        name="firstName"
        placeholder="First Name"
        value={book.authorFirst}
        onChange={(event)=>{setBook({...book,authorFirst:(event.target.value)})}}
        disabled={isEditMode}
      />
      <Input
        type="text"
        name="secondName"
        placeholder="Second Name"
        value={book.authorSecond}
        onChange={(event)=>{setBook({...book,authorSecond:(event.target.value)})}}
        disabled={isEditMode}  
      />
  </div>
  </div>
  <div className="row">
    {/*
  <div className="col-md-4 col-sm-4  col-xs-4">
  <Label for="clsNo">Classification</Label>
    </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input type="select" name="Book_Scope"
        value={book.bookClassificationNo} 
        onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}
       disabled={isEditMode}
      >
         {bookClassification.map(item => {
      return (<option key={item.name} value={item.value}>{item.name}</option>);
    })}
       
      </Input>
  
  </div>
  </div>
  </div>
    </FormGroup>
    <FormGroup>
    <div>
<div className="row">
  {/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="scope">Scope</Label>
  </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input type="select" name="Book_Scope"
       value={book.bookScope}
       onChange={(event)=>{setBook({...book,bookScope:event.target.value})}}
       disabled={isEditMode}
      >
        <option>CIRCULATION</option>
        <option>REFERENCE</option>
      </Input>
  </div>
  </div>
  <div className="row">
  {/*
  <div className="col-md-4 col-sm-4  col-xs-4">
  <Label for="publicationL">Publication</Label>
  </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="text"
        name="Book_Publisher"
        placeholder="Publisher"
        value={book.bookPublisher}
        onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value)})}}
        disabled={isEditMode}
      />
  </div>
  </div>
  </div>
    </FormGroup>
    <FormGroup>
    <div >
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="yop">Year of Publication</Label>
  </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="number"
        name="Publishing_Year"
        placeholder="Year of Publication"
          min={1000}
          max={9999}
        value={book.publishingYear}
        onChange={(event)=>{setBook({...book,publishingYear:event.target.value})}}
        disabled={isEditMode}  
      />
  </div>
  </div>
  <div className="row">
  {/*
  <div className="col-md-4 col-sm-4  col-xs-4">
  <Label for="priceL">Price</Label>
  </div> */}
  <div className="col-md-8 col-sm-8  col-xs-8">
  <Input
        type="number"
        name="price"
        placeholder="Price"
        disabled={isEditMode}
        value={book.price}
        onChange={(event)=>{setBook({...book,price:event.target.value})}}                    
      />
  </div>
  </div>
  </div>
      </FormGroup>
      {dispMsg}
    </div>

    {searchResult.length>0?
    <FormGroup check row>
     
     <div >
<div className="row">
<div className="col-md-6 col-sm-6  col-xs-6">
     
      <Button 
   className="button-approve is-info" style={{width:"60px"}}
   onClick={event => {buttonValue==="Edit"?handleUserEdit(event):handleEditSave(event)}}
 >{buttonValue}</Button>


</div>
<div className="col-md-6 col-sm-6  col-xs-6">
     
{isEditMode?<div></div>:<Button className="button-del is-info " style={{width:"80px"}}
onClick={event => handleCancelClick(event)}>Cancel</Button>}
</div>
</div>
</div>
    </FormGroup>:""}
  </form>
</CardBody>
</Card>
</Col>           
</Row>
</div>
{loader}
</div>
</div>

<div className="navbar-bg">

  <div className="row " style={{margin:'0px'}}>
                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>


        <div >

            <div className="container">
              <h4 className="text-title"><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Books</h4>
      <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
          <form onSubmit={event => handlebooksearch(event)}>
            <CardHeader>
              
            <input type="text" className="search-input-ed" 
            placeholder="Enter the Accession No to Search"  
            name="Search_Value"
            autoFocus="autofocus"
            value={searchValue}          
            onChange={(event)=>{setSearchValue((event.target.value))}}/>
         
            <button type="submit" className="searchButton">
            <i className="fa fa-search" /> Search
            </button>
               
            </CardHeader>
            <h3>  {searchOutputMsg} </h3>
    
   
            </form>
            <CardBody>
           <form> 
           <div className="navbar-bg">
            <FormGroup>
            <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="accessionNoL">Accession No</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Accession_No"
                    value={book.accessionNo}
                    
                    onChange={(event)=>{setBook({...book,accessionNo:(event.target.value)})}}
                    disabled
                  />
                  {/*!isEditMode && editDisabled && (book.accessionNo!=="")?<button onClick={(event)=>{handleAccEdit(event)}}>Edit</button>
                      :(!editDisabled && (book.accessionNo!==""))?<button onClick={(event)=>{handleAccesionNoValidation(event)}}>validate</button>
    :<div></div>*/}
                  
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="bookTitleL">Book Title</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Book_Title"
                    
                    value={book.bookTitle}
                    disabled={isEditMode}
                    onChange={(event)=>{setBook({...book,bookTitle:(event.target.value)})}}  
                  />
              </div>
              </div>
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="bookAuthor">Author</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={book.authorFirst}
                    onChange={(event)=>{setBook({...book,authorFirst:(event.target.value)})}}
                    disabled={isEditMode}
                  />
                  <Input
                    type="text"
                    name="secondName"
                    placeholder="Second Name"
                    value={book.authorSecond}
                    onChange={(event)=>{setBook({...book,authorSecond:(event.target.value)})}}
                    disabled={isEditMode}  
                  />
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="clsNo">Classification</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input type="select" name="Book_Scope"
                    value={book.bookClassificationNo} 
                    onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}
                   disabled={isEditMode}
                  >
                     {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
                   
                  </Input>
              {/*    <select className="Book_Scope" 
              value={book.bookClassificationNo} 
                  disabled={isEditMode}
                  onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}>
                {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
              </select>
              <Input
                    type="text"
                    name="Book_Classification_No"
                    id="clsId"
                    value={book.bookClassificationNo}
                    onChange={(event)=>{setBook({...book,bookClassificationNo:(event.target.value).toUpperCase()})}}
                    disabled={isEditMode}  
              />*/}
              </div>
              </div>
              </div>
                </FormGroup>
                <FormGroup>
                <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="scope">Scope</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input type="select" name="Book_Scope"
                   value={book.bookScope}
                   onChange={(event)=>{handleBookScopeSelection(event)}}
                   disabled={isEditMode}
                  >
                    <option>CIRCULATION</option>
                    <option>REFERENCE</option>
                    <option>NOT Available</option>

                  </Input>
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="publicationL">Publication</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="text"
                    name="Book_Publisher"
                    value={book.bookPublisher}
                    onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value)})}}
                    disabled={isEditMode}
                  />
              </div>
              </div>
              </div>

                  
                  
                </FormGroup>
               
                
               
                <FormGroup>

                <div className="container">
            <div className="row">
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="yop">Year of Publication</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="number"
                    name="Publishing_Year"
                      min={1000}
                      max={9999}
                    value={book.publishingYear}
                    onChange={(event)=>{setBook({...book,publishingYear:event.target.value})}}
                    disabled={isEditMode}  
                  />
              </div>
              
              <div className="col-md-2 col-sm-2  col-xs-2">
              <Label for="priceL">Price</Label>
              </div>
              <div className="col-md-4 col-sm-4  col-xs-4">
              <Input
                    type="number"
                    name="price"
                    disabled={isEditMode}
                    value={book.price}
                    onChange={(event)=>{setBook({...book,price:event.target.value})}}
                    
                  />
              </div>
              </div>
              </div>
                  </FormGroup>
                  {dispMsg}

                </div>


                

                {searchResult.length>0?
                <FormGroup check row>
                 
                 <div className="container">
            <div className="row">
            <div className="col-md-6 col-sm-6  col-xs-6">
                 
                  <Button
               className="button-approve is-info" style={{width:"60px"}}
               onClick={event => {buttonValue==="Edit"?handleUserEdit(event):handleEditSave(event)}}
             >{buttonValue}</Button>


            </div>
            <div className="col-md-6 col-sm-6  col-xs-6">
                 
            {isEditMode?<div></div>:<Button className="button-del is-info " style={{width:"80px"}}
            onClick={event => handleCancelClick(event)}>Cancel</Button>}
            </div>
            </div>
            </div>
                </FormGroup>:""}
              </form>
            </CardBody>
          </Card>
        </Col>           
      </Row>
      </div>
    
    {loader}
    </div>
    </div>
    </div>

    </div>
        </div>:<div>"You are not authorised to view this page"</div>}
        </div>
        
    )
}

export default EditBooks;
