import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from "axios";
//import styled from 'styled-components';
import { Auth } from "aws-amplify";
import useFullPageLoader from "../hooks/useFullPageLoader";
import {bookClassification} from '../../BookData';
import Sidebar from '../UserDashboard/Sidebar';
import {ProductContext} from '../../ProductProvider';
const config = require('../../config.json');


function AddBooks() {
    const{loggedUser}=useContext(ProductContext);
    const [book, setBook]=useState({accessionNo:'', authorTitle:'', bookAuthor:'', authorFirst:'', authorSecond:'', bookClassificationNo:'100', bookPublisher:'', publishingYear:'', bookTitle:'',price:'', bookScope:'CIRCULATION'})
    const [dispMsg, setDispMsg]=useState('');
    const [editDisabled, setEditDisabled] = useState(true);
    const [loader, showLoader, hideLoader] = useFullPageLoader(); 
    const [fetchAccNo, setFetchAccNo] = useState('');

    useEffect(()=>{
        //generateAccessionNo();
        fetchAccessionNo();
    },[]);

    const fetchAccessionNo = async() =>{
      showLoader();
      try{
        const library_source="AKLIB";
        const res=await axios.get(`${config.api.invokeUrl}/library/${library_source}`,{
          headers: {
            'x-api-key':config.api.key
          }
        });
        if(res.data.length>0){
          const accValue=res.data[0].lib_lastupdate;
          setBook({...book,accessionNo:accValue});   
          setFetchAccNo(accValue);     
        }else{
          setDispMsg("Error Generating Accession No. Try Again");
        }
        //console.log("Response : ", res);
      }catch(error){
        setDispMsg("Error Occured - ", error.message);
      }
      hideLoader();
    }

    /*const generateAccessionNo = async () => {
         const current_datetime = new Date();
         const temp="";
         const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
         var date_month = (current_datetime.getMonth() + 1).toString();
         var month_value="";
         date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
         var date_date = (current_datetime.getDate().toString());
         var date_value="";
         date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
         var date_hours = (current_datetime.getHours().toString());
         var hours_value="";
         date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
         var date_minutes = (current_datetime.getMinutes().toString());
         var minutes_value="";
         date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
         const orderDate = temp.concat(date_year,month_value, date_value, hours_value, minutes_value);
         setBook({...book,accessionNo:orderDate});        
        }*/

    const generateOrderdate=()=>{
        const current_datetime = new Date();
        const temp="";
        const date_year = (current_datetime.getFullYear()).toString();
        var date_month = (current_datetime.getMonth() + 1).toString();
        var month_value="";
        date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
        var date_date = (current_datetime.getDate().toString());
        var date_value="";
        date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
        const orderDate = temp.concat(date_year,"-",month_value,"-", date_value);
        return orderDate;
    }

    const handleAccesionNoValidation = async(event)=>{
      event.preventDefault();
      showLoader();
      setDispMsg("");
      if(book.accessionNo===""){
        setDispMsg("Enter the Accession No to validate!");
      }else{
      try{
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const bookQuery=book.accessionNo;
        const searchOption="Accession";
        const res = await axios.get(`${config.api.invokeUrl}/books/search?searchValue=${bookQuery}&searchOption=${searchOption}&familyName=${loggedUser.familyName}`,{
            headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
            }
        });
        if(res.data.length>0)
        {
          setDispMsg("Accession Number already exists. Try again with different one");
        }else{
          setEditDisabled(!editDisabled);
          setDispMsg("Validated! Good to proceed with this Accession No!");

        }
        //console.log("Response : ", res);
      }catch(error){
        setDispMsg("Error Occured - ", error.message);
      }
    }
    hideLoader();
    }

    const handleAddBook = async (event) => {
        event.preventDefault();
        setDispMsg("");
        // let generateNextValue=true;
        if(!editDisabled){
          setDispMsg("Validate the Accession No");
        }else if(book.bookClassificationNo==="100"){
          setDispMsg("Select the right Book Classification Number!");
        }else{
        // add call to AWS API Gateway add product endpoint here
        showLoader();
        try {
            if(book.accessionNo==="" || book.authorFirst==="" ||book.bookTitle===""){
                setDispMsg("Fill all the details to add a Book");
            }else{
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const orderDate=generateOrderdate();
                const Accession_No=book.accessionNo;
                const params = {
                    "Accession_No": (book.accessionNo.toString()).toUpperCase(),
                    "First_Name":book.authorFirst.toUpperCase(),
                    "Last_Name":book.authorSecond.toUpperCase(),
                    "Book_Author":  (book.authorSecond + " " + book.authorFirst).toUpperCase(),
                    "Author_Title": (book.authorSecond + " " + book.authorFirst +  " ## " + book.bookTitle).toUpperCase(),
                    "Book_Classification_No": book.bookClassificationNo,
                    "Book_Publisher": book.bookPublisher.toUpperCase(),    
                    "Book_Scope": book.bookScope,
                    "Book_Status": "Available",
                    "Book_Title": book.bookTitle.toUpperCase(),
                    "Publishing_Year":book.publishingYear,
                    "updated_on": orderDate,
                    "username": "NA",
                    "PK": loggedUser.familyName,
                    "updatedby": loggedUser.username,
                    "price":book.price,
                    "generateNextValue":fetchAccNo
                };
             //   console.log("Params : ", params);
            await axios.post(`${config.api.invokeUrl}/books/manage/${Accession_No}`, params,{
            headers: {
              Authorization: access_token,
              'x-api-key':config.api.key
            }})
            .then(async(response)=>{
              //console.log("response : ", response.data); 
              const newAccNo=response.data;
              await setBook({...book,accessionNo:newAccNo}); 
              await handleUpdateAccNo(response.data);
                setDispMsg(book.accessionNo + " - Added Successfully");
                setBook({ authorTitle: "", bookAuthor: "", authorFirst:"", authorSecond:"", bookClassificationNo: "100", bookPublisher: "", bookScope: "CIRCULATION" , bookTitle: "", publishingYear:"", price:""});
                //generateAccessionNo();
            })
            .catch((error)=>{
                setDispMsg(error.message);
            })
          }
        
        }catch (err) {
          setDispMsg(err.message);
        }
        hideLoader();       
      }
      }

      const handleUpdateAccNo = async(newAccNo)=>{
        showLoader();
        try{
        //console.log("New Acc No : ", newAccNo);
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        const library_source=loggedUser.familyName;
        const params = {
          "lib_lastupdate":newAccNo,
          "lib_source":loggedUser.familyName
      };
        await axios.patch(`${config.api.invokeUrl}/library/${library_source}`,params,{
          headers: {
            'x-api-key':config.api.key,
            Authorization: access_token
          }
        });
      }catch(error){
        console.log("error");
      }
      hideLoader();
      }
    
    return (

<div className="dash-board" style={{paddingBottom:'80px'}} >
{loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
  
  <form onSubmit={(event) => handleAddBook(event)}> 

  <div className="navbar-mobile">

  <div className="col-12 col-sm-12 col-lg-12" style={{marginTop:'50px'}}>
<h4 className="text-title" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; Add Books</h4>
<FormGroup>

<div >
<div className="row">
  {/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="accessionNoL">Accession No</Label>
</div> */}
<div className="col-md-8 col-sm-8  col-xs-8">
<span className="input-group-text"> 
                  <Input
                  style={{border:"none"}}
                    type="text"
                    
                    
                    value={book.accessionNo}
                    
                    onChange={(event)=>{setBook({...book,accessionNo:event.target.value})}}
                    disabled = {editDisabled}
                  />
            {editDisabled?<Button style={{border:"none"}} onClick={(event)=>{setEditDisabled(!editDisabled)}}>
              <i className="fa fa-pencil"></i>
            
            </Button>
                  :<Button  onClick={(event)=>{handleAccesionNoValidation(event)}}><i className="fa fa-check"></i></Button>}
            </span>
</div>

</div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="bookTitleL">Book Title</Label>
</div> */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input
      type="text"
      name="booktitle"
      placeholder="Book Title"
      value={book.bookTitle}
      autoFocus="autofocus"
      onChange={(event)=>{setBook({...book,bookTitle:(event.target.value)})}}  
    />
</div>
</div>
</div>
    

  </FormGroup>
  
  <FormGroup>


  <div >
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="bookAuthor">Author</Label>
</div> */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input
      type="text"
      name="AuthorFN"
      placeholder="First Name"
      value={book.authorFirst}
      onChange={(event) =>{setBook({...book, authorFirst:(event.target.value)})}}
        
    />
    <Input
      type="text"
      name="AuthorSN"
      placeholder="Second Name"
      value={book.authorSecond}
      onChange={(event)=>{setBook({...book, authorSecond:(event.target.value)})}}
        
    />
</div>
 
</div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="clsNo">Classification</Label>
</div> */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input type="select" name="cls"
                value={book.bookClassificationNo} 
                onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}
              
              >
                 {bookClassification.map(item => {
              return (<option key={item.name} value={item.value}>{item.name}</option>);
            })}
               
              </Input>       
        </div>
</div>
</div>

  </FormGroup>
 
            <FormGroup>


  <div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="scope">Scope</Label>
</div>  */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input type="select" name="scope"
     value={book.bookScope}
     onChange={(event)=>{setBook({...book, bookScope:event.target.value})}}
       
    >
          <option disabled defaultValue>Choose Book Scope Type</option>
      <option value="Circulation">CIRCULATION</option>
      <option value="Reference">REFERENCE</option>
    </Input>
</div>
 
</div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="publicationL">Publication</Label>
</div>  */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input
      type="text"
      name="publication"
      placeholder="Publication"
      value={book.bookPublisher}
     onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value)})}}
    />
</div>
</div>
</div>

  </FormGroup>
            <FormGroup>


  <div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="yop">Publication Year</Label>
</div>  */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input
      type="number"
      name='pub_year'
        min={1000}
        max={9999}
      placeholder="Year of Publication"
      value={book.publishingYear}
      onChange={(event)=>{setBook({...book,publishingYear:event.target.value})}}
        
    />
</div>
 
</div>
<div className="row">
{/*
<div className="col-md-4 col-sm-4  col-xs-4">
<Label for="priceL">Price</Label>
</div> */}
<div className="col-md-8 col-sm-8  col-xs-8">
<Input
      type="number"
      name="price"
      
      placeholder="Price"
      value={book.price}
      onChange={(event)=>{setBook({...book,price:event.target.value})}}
      
    />
</div>
</div>
</div>

  </FormGroup>
  <FormGroup check row className="text-center">
        <Col sm={{ size: 10, offset: 2 }}>
          <button type="submit" className="btn btn-primary pull-center" style={{}}>Submit</button>
        </Col>
      </FormGroup>
  </div>
 </div>






          
         
                
                <div className="navbar-bg">
                <div className="row " style={{margin:'0px'}}>
                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>
<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

<div >

<div className="container">

<h4 className="text-title" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; Add Books</h4>
                
                <Row>
        <Col xl={12} lg={12} md={12}>
          <Card>
            <CardBody>
                <FormGroup>
  
                <div className="container">
                <div className="row">
                <div className="col-md-2 col-sm-2  col-xs-2">
                <Label for="accessionNoL">Accession No</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <span className="input-group-text"> 
                      <Input
                      style={{border:"none"}}
                        type="text"
                        
                        
                        value={book.accessionNo}
                        
                        onChange={(event)=>{setBook({...book,accessionNo:event.target.value})}}
                        disabled = {editDisabled}
                      />
                {editDisabled?<Button style={{border:"none"}} onClick={(event)=>{setEditDisabled(!editDisabled)}}>
                  <i className="fa fa-pencil"></i>
                
                </Button>
                      :<Button onClick={(event)=>{handleAccesionNoValidation(event)}}><i className="fa fa-check"></i></Button>}
                </span>
        

                      
                       </div>
                 
                     
                  
                 <div className="col-md-2 col-sm-2  col-xs-2">
                  <Label for="bookTitleL">Book Title</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input
                        type="text"
                        name="booktitle"
                        placeholder="Book Title"
                        autoFocus="autofocus"
                        value={book.bookTitle}
                        onChange={(event)=>{setBook({...book,bookTitle:(event.target.value)})}}  
                      />
                  </div>
                  </div>
                  </div>
                      
  
                    </FormGroup>
                    
                    <FormGroup>
    
    
                    <div className="container">
                <div className="row">
                <div className="col-md-2 col-sm-2  col-xs-2">
                <Label for="bookAuthor">Author</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input
                        type="text"
                        name="AuthorFN"
                        placeholder="First Name"
                        value={book.authorFirst}
                        onChange={(event) =>{setBook({...book, authorFirst:(event.target.value)})}}
                          
                      />
                      <Input
                        type="text"
                        name="AuthorSN"
                        placeholder="Second Name"
                        value={book.authorSecond}
                        onChange={(event)=>{setBook({...book, authorSecond:(event.target.value)})}}
                          
                      />
                  </div>
                  
                  <div className="col-md-2 col-sm-2  col-xs-2">
                  <Label for="clsNo">Classification</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input type="select" name="cls"
                    value={book.bookClassificationNo} 
                    onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}
                  
                  >
                     {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
                   
                  </Input>
                 {/*  <select className="input-phonecode-s" value={book.bookClassificationNo} 
                  onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value)})}}>
                {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
              </select>
                 <Input
                        type="text"
                        name="cls"
                        id="clsId"
                        placeholder="Classification"
                        value={book.bookClassificationNo}
                        onChange={(event)=>{setBook({...book, bookClassificationNo:(event.target.value).toUpperCase()})}}
                          
              />*/}
                  </div>
                  </div>
                  </div>
    
                    </FormGroup>
                   
                              <FormGroup>
    
    
                    <div className="container">
                <div className="row">
                <div className="col-md-2 col-sm-2  col-xs-2">
                <Label for="scope">Scope</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input type="select" name="scope"
                       value={book.bookScope}
                       onChange={(event)=>{setBook({...book, bookScope:event.target.value})}}
                         
                      >
                            <option disabled defaultValue>Choose Book Scope Type</option>
                        <option value="Circulation">CIRCULATION</option>
                        <option value="Reference">REFERENCE</option>
                      </Input>
                  </div>
                  
                  <div className="col-md-2 col-sm-2  col-xs-2">
                  <Label for="publicationL">Publication</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input
                        type="text"
                        name="publication"
                        placeholder="Publication"
                        value={book.bookPublisher}
                       onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value)})}}
                      />
                  </div>
                  </div>
                  </div>
    
                    </FormGroup>
                              <FormGroup>
    
    
                    <div className="container">
                <div className="row">
                <div className="col-md-2 col-sm-2  col-xs-2">
                <Label for="yop">Publication Year</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input
                        type="number"
                        name='pub_year'
                          min={1000}
                          max={9999}
                        placeholder="Year of Publication"
                        value={book.publishingYear}
                        onChange={(event)=>{setBook({...book,publishingYear:event.target.value})}}
                          
                      />
                  </div>
                  
                  <div className="col-md-2 col-sm-2  col-xs-2">
                  <Label for="priceL">Price</Label>
                  </div>
                  <div className="col-md-4 col-sm-4  col-xs-4">
                  <Input
                        type="number"
                        name="price"
                        
                        placeholder="Price"
                        value={book.price}
                        onChange={(event)=>{setBook({...book,price:event.target.value})}}
                        
                      />
                </div>
                  </div>
                  </div>
    
                    </FormGroup>
                    {dispMsg}

                    <FormGroup check row className="text-center">

        <Col sm={{ size: 10, offset: 2 }}>
          <button type="submit" className="btn btn-primary pull-center" style={{}}>Submit</button>
        </Col>
      </FormGroup>

                    </CardBody>
          </Card>
        </Col>           
      </Row>
                   
              </div>     
                 
      
       </div>

     
      
      </div>
       </div></div>          

               
          
        
          {loader}
         
      </form>
       :
       
       <div>"You are not authorised to view this page"</div>
       
       }
        </div>
      
    )
}

export default AddBooks;