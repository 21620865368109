import React, {useContext, useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Auth } from "aws-amplify";
import LibNavbar from './components/LibNavbar';
import Home from './components/Home';
import SignIn from './components/auth/SignIn';
import SignUp from './components/auth/SignUp';
import ForgotPassword from './components/auth/ForgotPassword';
import ForgotPasswordVerification from './components/auth/ForgotPasswordVerification';
import ChangePasswordConfirm from './components/auth/ChangePasswordConfirm';
import ChangePassword from './components/auth/ChangePassword';
import MainDashboard from './components/UserDashboard/MainDashboard';
import './css/bootstrap.min.css';
import './App.css';
import {ProductContext} from './ProductProvider';
import Snapshot from './components/UserDashboard/Snapshot';
import SearchBooks from './components/books/SearchBooks';
import AddBooks from './components/books/AddBooks';
import EditBooks from './components/books/EditBooks';
import IssueBooks from './components/books/IssueBooks';
import ReturnBooks from './components/books/ReturnBooks';
import DueBooks from './components/books/DueBooks';

import MyBooks from './components/user/MyBooks';
import MyProfile from './components/user/MyProfile';
import SearchUser from './components/user/SearchUser';
import ApproachDeadline from './components/books/ApproachDeadline';
import Bottombar from './components/bottombar/Bottombar.js';
import QuickProfile from './components/UserDashboard/QuickProfile';
import BooksView from './components/UserDashboard/BooksView';
function App() {

  const {loggedUser, setLoggedUser} = useContext(ProductContext);

  useEffect(() => {
    
    validateLogin();    
  },[]);

  const validateLogin = async() =>{
    try{
       Auth.currentAuthenticatedUser()
      .then((user)=>{
        setLoggedUser({...loggedUser,
          username:user.username,
          email:user.attributes['email'],
          phoneno:user.attributes['phone_number'],
          familyName:user.attributes['family_name'],
          name:user.attributes['name'],
          profile:user.attributes['profile'],
          isAuthenticated:true,
        });
        
      })
      .catch((error) =>{
        setLoggedUser({...loggedUser,
          username:'',
          email:'',
          phoneno:'',
          familyName:'',
          name:'',
          profile:'',
          isAuthenticated:false
        });    
      })     
    }catch(error){
      console.log(error);
    }
  }
 

  return (
    <div className="App">
    <Router>
     
        <LibNavbar/>
        <div style={{marginTop:'85px'}}>
          <Switch >
          <Route exact path="/signin" render={(props) => <SignIn {...props} />} />
          <Route exact path="/signup" render={(props) => <SignUp {...props} />} />
          <Route exact path="/forgotpassword" render={(props) => <ForgotPassword {...props} />} />
          <Route exact path="/forgotpasswordverification" render={(props) => <ForgotPasswordVerification {...props} />} />
          <Route exact path="/changepasswordconfirmation" render={(props) => <ChangePasswordConfirm {...props} />} />
          <Route exact path="/dashboard" render={(props) => <MainDashboard {...props} />} />
          <Route exact path="/snapshot" render={(props) => <Snapshot {...props} />} />
          <Route exact path="/search-books" render={(props) => <SearchBooks {...props} />} />
          <Route exact path="/add-books" render={(props) => <AddBooks {...props} />} />
          <Route exact path="/edit-books" render={(props) => <EditBooks {...props} />} />
          <Route exact path="/issue-books" render={(props) => <IssueBooks {...props} />} />
          <Route exact path="/return-books" render={(props) => <ReturnBooks {...props} />} />
          <Route exact path="/my-books" render={(props) => <MyBooks {...props} />} />
          <Route exact path="/my-profile" render={(props) => <MyProfile {...props} />} />
          <Route exact path="/manage-user" render={(props) => <SearchUser {...props} />} />
          <Route exact path="/due-books" render={(props) => <DueBooks {...props} />} />
          <Route exact path="/approaching-deadline" render={(props) => <ApproachDeadline {...props} />} />
          <Route exact path="/profile-view" render={(props) => <QuickProfile {...props} />} />
          <Route exact path="/change-password" render={(props) => <ChangePassword {...props} />} />
          <Route exact path="/books-view" render={(props) => <BooksView {...props} />} />
          <Route exact path="/" render={(props) => <Home {...props} />} />

      </Switch>
        </div>
       
      <div className="navbar-mobile">
          <Bottombar />
          </div>
     
    </Router>
  </div>

  );
}

export default App;
