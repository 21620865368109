import React, {useEffect, useState, useContext} from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import {Col,Row} from 'reactstrap';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { ProductContext } from '../../ProductProvider';
import total_books from '../../images/total_books.jpg';
import available_books from '../../images/available_books.jpg';
import issue_book from '../../images/issue_book.jpg';
import approaching from '../../images/approaching.jpeg';
import bible from '../../images/bookscategory/bible.jpeg';
import church from '../../images/bookscategory/church.jpeg';
import indology from '../../images/bookscategory/Indology.jpeg';
import phlosophy from '../../images/bookscategory/phlosophy.jpeg';
import socialscience from '../../images/bookscategory/socialscience.jpeg';
import theology from '../../images/bookscategory/theology.jpeg';
import variety from '../../images/bookscategory/variety.jpeg';
import sacraments from '../../images/bookscategory/sacraments.jpeg';
import spirituality from '../../images/bookscategory/spirituality.jpeg';

import attention from '../../images/attention.jpg';
import styled from 'styled-components';
import {Link} from 'react-router-dom';


const config = require('../../config.json');

export default function Snapshot() {
    const {loggedUser, setOverdueBooks, setApproachingBooks, overdueBooks, approachingBooks} = useContext(ProductContext);
    const [dispMsg, setDispMsg] = useState('');
    const [issuedCount, setIssuedCount] = useState('');
    const [availableCount, setAvailableCount] = useState('');
    const [totalCount, setTotalCount] = useState('');
    const [bookCategory, setBookCategory] = useState({'bible':'','church':'','sacraments':'','spirituality':'','indology':'','philosophy':'','socialscience':'','theology':'','variety':''});
    const [loader, showLoader, hideLoader] = useFullPageLoader();


    useEffect(() => {
        fetchSnapshotValues();
      //  fetchAdminViewSnapshot();
    },[]);

    /*const fetchAdminViewSnapshot = async()=>{
        try{
            showLoader();
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            const res = await axios.get(`${config.api.invokeUrl}/snapshot/adminiew?familyName=${loggedUser.familyName}&searchOption=${loggedUser.profile}&searchkey=${loggedUser.username}`,{
                headers: {
                Authorization: access_token,
                'x-api-key':config.api.key
                }
            });
            //console.log("respponse : ", res);
            setOverdueBooks(res.data[0]);
            setApproachingBooks(res.data[1]);
            //setSearchResults(res.data);
        }catch(error){
            setDispMsg("Error Occured - ", error.message);
        }
        hideLoader();
    
    }*/

const fetchSnapshotValues = async()=>{
    try{
        showLoader();
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        //   const res = await axios.get(`${config.api.invokeUrl}/snapshot?familyName=${loggedUser.familyName}`,{
        const res = await axios.get(`${config.api.invokeUrl}/dashboard?familyName=${loggedUser.familyName}`,{
            headers: {
            Authorization: access_token,
            'x-api-key':config.api.key
            }
        });
        //console.log("respponse : ", res.data[0].bookCategory);
        setIssuedCount(res.data[0].bookStatus[1].value);
        setAvailableCount(res.data[0].bookStatus[0].value);
        setOverdueBooks(res.data[0].bookDetails[0]);
        setApproachingBooks(res.data[0].bookDetails[1]);
        setBookCategory({...bookCategory,
        bible:res.data[0].bookCategory[0].value,
        theology:res.data[0].bookCategory[1].value,
        church:res.data[0].bookCategory[2].value,
        sacraments:res.data[0].bookCategory[3].value,
        spirituality:res.data[0].bookCategory[4].value,
        indology:res.data[0].bookCategory[5].value,
        philosophy:res.data[0].bookCategory[6].value,
        socialscience:res.data[0].bookCategory[7].value,
        variety:res.data[0].bookCategory[8].value,
        });
        //setBookCategory(res.data[0].bookCategory);
        //setDeleteCount(res.data[2].Delete);
        const totalBooks = res.data[0].bookStatus[1].value+res.data[0].bookStatus[0].value;
        setTotalCount(totalBooks);
        //setSearchResults(res.data);
    }catch(error){
        setDispMsg("Error Occured - ", error.message);
    }
    hideLoader();

}

    return (
        <div style={{paddingTop:'10px'}}>
        <h4 className="text-title" ><i className="fa fa-dashboard fa-fw" aria-hidden="true"></i>&nbsp; Dashboard</h4>
            {loader}
        <Snapview>
            <div className="container" style={{background:"none"}}>
            
            <div style={{textAlign:"center"}}> {loader} </div>
                <Row>
                   {/* <Col sm={3} Style={{padding:"20px"}}>
                   <div className="card">
                       <div style={{padding: "10px"}}>

                   <img src={snap} alt="Avatar" style={{borderRadius:"8px"}}/>
                   
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>SnapShot view of your Account</b></h5>
                    
                  
                    </div>
                     </div>
    </Col> */} 
                    <Col sm={4} >
                       

                    <div className="card">
                   <img className="img-card" src={total_books} alt="Avatar" />
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Total Books</b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                        background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {totalCount} 
                        </h1></div> 
                     </div>
                    </Col>
                    <Col sm={4}>
                    <div className="card">
                   <img  src={issue_book} alt="Avatar" />
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Issued Books</b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", 
                        background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {issuedCount} 
                        </h1></div> 
                     </div>
                    </Col>
                    <Col sm={4}>
                    <div className="card">
                   <img className="img-card" src={available_books} alt="Avatar" />
                    <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px"}}><b>Available Books </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3", background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {availableCount} 
                        </h1></div> 
                     </div>
                    </Col>
               </Row>

                <div>
                 <h4 className="text-title" ><i className="fa fa-warning fa-fw" aria-hidden="true"></i>&nbsp; {loggedUser.profile} - To Do's </h4>
               <Row>
                    <Col sm={4}>  <Link to="/due-books">
                    <div className="card">
                   <img className="img-card" src={attention} alt="Avatar" />
                   
                    
                  
                      <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Overdue Books </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {overdueBooks.length}  
                        </h1></div> 
                    
                        
                         
                     </div></Link> 
                    </Col>
                    <Col sm={4}> <Link to="/approaching-deadline">
                    <div  className="card">
                   <img  src={approaching} alt="Avatar" />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Nearing Returns </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {approachingBooks.length}  
                        </h1></div>
                     </div> </Link>
                    </Col>
               </Row></div>

               <div>
                 <h4 className="text-title" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; Book's Category </h4>
               <Row>
                    <Col sm={4}> 
                    <div className="card">
                   <img className="img-card" src={bible} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />                      
                   <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Bible </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.bible}  
                        </h1></div>      
                     </div>
                    </Col>
                    <Col sm={4}> 
                    <div  className="card">
                   <img  src={theology} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}}  />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Theology </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.theology}  
                        </h1></div>
                     </div> 
                    </Col>
                    <Col sm={4}>  
                    <div className="card">
                   <img className="img-card" src={church} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />                      
                   <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Church </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.church}  
                        </h1></div>      
                     </div>
                    </Col>
                    <Col sm={4}> 
                    <div  className="card">
                   <img  src={sacraments} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Sacraments </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.sacraments}  
                        </h1></div>
                     </div> 
                    </Col>
                    <Col sm={4}> 
                    <div className="card">
                   <img className="img-card" src={spirituality} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />                      
                   <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Spirituality </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.spirituality}   
                        </h1></div>      
                     </div>
                    </Col>
                    <Col sm={4}> 
                    <div  className="card">
                   <img  src={indology} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Indology </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.indology}    
                        </h1></div>
                     </div> 
                    </Col>
                    <Col sm={4}>  
                    <div className="card">
                   <img className="img-card" src={phlosophy} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />                      
                   <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Philosophy </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.philosophy}    
                        </h1></div>      
                     </div>
                    </Col>
                    <Col sm={4}> 
                    <div  className="card">
                   <img  src={socialscience} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Social Science </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.socialscience}  
                        </h1></div>
                     </div> 
                    </Col>
                    <Col sm={4}> 
                    <div  className="card">
                   <img  src={variety} alt="Avatar" style={{marginLeft:"auto", marginRight:"auto", height:"100px", width:"100%"}} />
                   
                        <h5 style={{textAlign:"center", paddingTop:"20px", height:"60px", color:"red"}}><b>Variety </b></h5>
                    <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                     display:"table",  alignItems:"center"}}>
                        <h1 style={{display:"table-cell", verticalAlign:"middle", color:"#1362b3",
                         background:"none", width:"80px", height:"80px", borderRadius:"50px"}}> {bookCategory.variety}  
                        </h1></div>
                     </div> 
                    </Col>


               </Row></div>

            </div>
            {dispMsg}
 </Snapview>
        </div>
    )
}
const Snapview = styled.nav `


 
    
    `