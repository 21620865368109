import React, { useContext, useState } from 'react';
import {Card,CardBody,CardHeader,Col,FormGroup,Label,Row, Button, Input} from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { Table } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
//import SearchBookDisplay from './SearchBookDisplay';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { bookClassification, bookCategory, bookHeaders } from '../../BookData';
import Sidebar from '../UserDashboard/Sidebar';
const config = require('../../config.json');


function SearchBooks() {
    const {loggedUser} = useContext(ProductContext);
    const [bookQuery, setBookQuery]=useState('');
    const [searchOption, setSearchOption] = useState("BookTitle");
    const [dispMsg, setDispMsg]=useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const csvLinkEl = React.createRef();

    
    const handleglobalsearch = async(event) => {
        event.preventDefault();
        setSearchResults('');
        setDispMsg('');    
        try {
          showLoader();
            if(bookQuery==="")
            {
                setDispMsg("Enter the data to Search!!!");
            }
            else{    
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const bookValue=bookQuery.toUpperCase();
                //console.log("book value : ", bookValue);
                const res = await axios.get(`${config.api.invokeUrl}/books/search?searchValue=${bookValue}&searchOption=${searchOption}&familyName=${loggedUser.familyName}`,{
                    headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                    }
                });
                setDispMsg(res.data.length + " - Books found");
                var i=0;
                //console.log("response : ", res.data);
                while(i<res.data.length){
                 const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
                 //console.log("acc no : ", res.data[i].Accession_No);
                 var bAuthor="";
                 bAuthor = res.data[i].Last_Name;
                // console.log("bAuthor : ", bAuthor);
                  if(bAuthor.length===0){
                    bAuthor=res.data[i].First_Name;
                  }
                 
                 res.data[i].RackValue=res.data[i].Book_Classification_No+" "+bAuthor.substring(0,3);
                 //console.log("temp class vale : ", tempClassValue[0]);
                 res.data[i].Book_Classification_No=tempClassValue[0].name;
                 //console.log("After update : ", res.data[i].Book_Classification_No);
                 i++;
                }
                const unSortedData=res.data;
                unSortedData.sort((a, b) => {
                  let fa = a.Book_Author,
                      fb = b.Book_Author;
              
                  if (fa < fb) {
                      return -1;
                  }
                  if (fa > fb) {  
                      return 1;
                  }
                  return 0;
              });
              //  res.data.Book_Author.sort();
               // console.log("Sorted Data : ", unSortedData);
                setSearchResults(res.data);
            }
        } catch (error) {
            setDispMsg(error.message);
        }
        hideLoader();
      }

      const handleDownload =async()=>{
        csvLinkEl.current.link.click();
      }
      
      function winScroll(){
        window.scrollTo(0,0);
      }

    return (
      <div className="dash-board" style={{paddingBottom:'200px'}}>
      {loggedUser.isAuthenticated?

      <div>{/*}
        <div className="navbar-mobile">

        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px'}}>

       
       <div >   
            <h4 className="text-title"><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Books</h4>
      <form onSubmit={(event) => {handleglobalsearch(event)}}>   
      <Row>
            <Col xl={12} lg={12} md={12}>
              <Card> 
                <CardBody>
               <FormGroup>
                 
                 <div className="navbar-mobile">

 <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'left'}}>
<div className="p-2  flex-fill bd-highlight">
<Label for="searchoption">
                    <input
                        type="radio"
                        name="searchoptionm"
                        id="BookTitle"
                        value="BookTitle"
                        checked={searchOption==="BookTitle"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                      />               
                     Book Title</Label>
    </div>
    <div className="p-2  flex-fill bd-highlight">
    <Label>
                   <input
                        type="radio"
                        id="BookAuthor"
                        value="BookAuthor"
                        name="searchoptionm"
                        checked={searchOption==="BookAuthor"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                    />
    
                     Author</Label>  
    </div>
  </div>

   <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'left'}}>
<div className="p-2 flex-fill bd-highlight">
    <Label >
                   <input
                        type="radio"
                        name="searchoptionm"
                        id="ClassificationNo"
                        value="ClassificationNo"
                        checked={searchOption==="ClassificationNo"}
                        onChange={(event)=>{setBookQuery("210");setSearchOption(event.target.value)}}
                        style={{marginRight: '8px'}}
                        />
    
                     Classification</Label>
    </div>
    <div className="p-2 flex-fill bd-highlight">
    <Label >
                   <input
                        type="radio"
                        name="searchoptionm"
                        id="Accession"
                        value="Accession"
                        checked={searchOption==="Accession"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                    />
                     Accession</Label>
    </div>
  
</div>
</div>
     
    </FormGroup>   
    <CardHeader > 
            {searchOption==="Category"?
            <Input type="select" id="bookCategory" name="select" className="input-phonecode-s" value={bookQuery} onChange={(event)=>{setBookQuery(event.target.value)}}>
            {bookCategory.map(item => {
              return (<option key={item.name} value={item.fvalue}>{item.name}</option>);
            })}
          </Input>
                :searchOption==="ClassificationNo"?
                <select className="input-phonecode-s" value={bookQuery} onChange={(event)=>{setBookQuery(event.target.value)}}>
                {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
              </select>
                :<input type="text" className="search-input" placeholder="Search your books here..."    
            value={bookQuery} autoFocus="autofocus"
            onChange={(event)=>{setBookQuery(event.target.value)}}
            />}

            <Button className="pull-center" style={{background:"none", border:"none", color:"black", fontSize:"20px"}}><i className="fa fa-search fa-fw" aria-hidden="true"></i></Button>
            {searchResults.length>0?
            <div style={{textAlign:"right", color:"blue", paddingTop:"10px"}}> <b>Download.... </b><i className="fa fa-download" onClick={(event)=>{handleDownload(event)}}></i>
<CSVLink
          headers={bookHeaders}
          filename="Inigo_Library_Books.csv"
          data={searchResults}
          ref={csvLinkEl}
        /></div>:
        <div></div>
                           }
           
                </CardHeader>
                            
                </CardBody>
                </Card>
                {dispMsg}
                </Col>
                </Row>
            </form>
       
     
           {searchResults.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Accession</th>
                  <th>Book Title</th>
                  <th>Author</th>
                  <th>Classification</th>
                  <th>Rack </th>
                  <th>Scope</th>
                  <th>Status</th>
                  <th>Issued</th>
                </tr>
              </thead>      
              <tbody>
        {searchResults.map((searchresult,index) =>(
        <tr key={index}>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author}</td>
          <td>{searchresult.Book_Classification_No}</td>
          <td>{searchresult.RackValue}</td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td><p>{searchresult.username}</p><p>{searchresult.username==="NA"?"": (searchresult.issued_on).substring(0,10)}</p></td>
        </tr>))}
      
        </tbody></Table>

            </div>:<div className="tile notification is-warning"></div>}  
            
            </div>
            {loader}
            
            </div>    
      </div>

        <div className="navbar-bg">
         

  <div className="row " style={{margin:'0px'}}>

                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>


        <div className="container">
          <div>
             <div className="navbar-bg">
       <div className="container">   
            <h4 className="text-title"><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Books</h4>
      <form onSubmit={(event) => {handleglobalsearch(event)}}>   
      <Row>
            <Col xl={12} lg={12} md={12}>
              <Card> 
                <CardBody>
               <FormGroup>
                 <div className="container">

                <div className="row">
               <div className="col-md-6 col-sm-6  col-xs-6">
              <Label>
                   <input
                        type="radio"
                        name="searchoption"
                        id="BookTitle"
                        value="BookTitle"
                        checked={searchOption==="BookTitle"}
                        onChange={(event)=>{setBookQuery('');setSearchOption(event.target.value)}}
                      />              
                      Book Title</Label>
                   </div>
                <div className="col-md-6 col-sm-6 col-xs-6">
                <Label>
                   <input
                        type="radio"
                        id="BookAuthor"
                        value="BookAuthor"
                        name="searchoption"
                        checked={searchOption==="BookAuthor"}
                        onChange={(event)=>{setBookQuery('');setSearchOption(event.target.value)}}
                    />
    
                     Author</Label>
                   </div>
                   <div className="col-md-6 col-sm-6 col-xs-6">
                   <Label >
                   <input
                        type="radio"
                        name="searchoption"
                        id="Category"
                        value="Category"
                        checked={searchOption==="Category"}
                        onChange={(event)=>{setBookQuery("210 219A");setSearchOption(event.target.value)}}
                        />
    
                    Category</Label>
                   </div>
                   <div className="col-md-6 col-sm-6 col-xs-6">
                   <Label >
                   <input
                        type="radio"
                        name="searchoption"
                        id="ClassificationNo"
                        value="ClassificationNo"
                        checked={searchOption==="ClassificationNo"}
                        onChange={(event)=>{setBookQuery("210");setSearchOption(event.target.value)}}
                        />
    
                    Classification</Label>
                   </div>
                   <div className="col-md-6 col-sm-6 col-xs-6">
                   <Label >
                   <input
                        type="radio"
                        name="searchoption"
                        id="Accession"
                        value="Accession"
                        checked={searchOption==="Accession"}
                        onChange={(event)=>{setBookQuery('');setSearchOption(event.target.value)}}
                    />
                     Accession</Label>
    </div>  
            </div>
               
                 </div>
                 <div className="navbar-mobile">

 <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'left'}}>
<div className="p-2  flex-fill bd-highlight">
<Label for="searchoption">
                    <input
                        type="radio"
                        name="searchoption"
                        id="bookTitle"
                        value="bookTitle"
                        checked={searchOption==="bookTitle"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                      />               
                     Book Title</Label>
    </div>
    <div className="p-2  flex-fill bd-highlight">
    <Label>
                   <input
                        type="radio"
                        id="bookAuthor"
                        value="bookAuthor"
                        name="searchoption"
                        checked={searchOption==="bookAuthor"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                    />
    
                     Author</Label>  
    </div>
  </div>

   <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'left'}}>
<div className="p-2 flex-fill bd-highlight">
    <Label >
                   <input
                        type="radio"
                        name="searchoption"
                        id="Classification"
                        value="Classification"
                        checked={searchOption==="Classification"}
                        onChange={(event)=>{setBookQuery("210");setSearchOption(event.target.value)}}
                        style={{marginRight: '8px'}}
                        />
    
                     Classification</Label>
    </div>
    <div className="p-2 flex-fill bd-highlight">
    <Label >
                   <input
                        type="radio"
                        name="searchoption"
                        id="AccessionNo"
                        value="AccessionNo"
                        checked={searchOption==="AccessionNo"}
                        onChange={(event)=>setSearchOption(event.target.value)}
                        style={{marginRight: '8px'}}
                    />
                     Accession</Label>
    </div>
  
</div>
</div>
     
    </FormGroup>   
    <CardHeader> 
            {searchOption==="Category"?
            <Input id="bookCategory" type="select" name="select" value={bookQuery} onChange={(event)=>{setBookQuery(event.target.value)}}>
            {bookCategory.map(item => {
              return (<option key={item.name} value={item.fvalue}>{item.name}</option>);
            })}
          </Input>
                :searchOption==="ClassificationNo"?
                <select className="input-phonecode-s" value={bookQuery} onChange={(event)=>{setBookQuery(event.target.value)}}>
                {bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}
              </select>
                :<input type="text" className="search-input" placeholder="Search your books here..."    
            value={bookQuery} autoFocus="autofocus"
            onChange={(event)=>{setBookQuery(event.target.value)}}
            />}

            <Button className="pull-center">Search</Button>

            {searchResults.length>0?
            <div style={{textAlign:"right", color:"blue", paddingTop:"20px"}}> <b>Click to download the data.... </b><i className="fa fa-download" onClick={(event)=>{handleDownload(event)}}></i>
<CSVLink
          headers={bookHeaders}
          filename="Inigo_Library_Books.csv"
          data={searchResults}
          ref={csvLinkEl}
        /></div>:
        <div></div>
                           }
                           
                           
                                          </CardHeader>
                            
                </CardBody>
                </Card>
                {dispMsg}
                </Col>
                </Row>
            </form>
       
     
           {searchResults.length>0?<div>
            
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Accession</th>
                  <th>Book Title</th>
                  <th>Author</th>
                  <th>Classification</th>
                  <th>Rack </th>
                  <th>Scope</th>
                  <th>Status</th>
                  <th>Issued</th>
                </tr>
              </thead>      
              <tbody>
        {searchResults.map((searchresult,index) =>(
        <tr key={index}>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author}</td>
          <td>{searchresult.Book_Classification_No}</td>
          <td>{searchresult.RackValue}</td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td><p>{searchresult.username}</p><p>{searchresult.username==="NA"?"": (searchresult.issued_on).substring(0,10)}</p></td>
        </tr>))}
      
        </tbody></Table>

            </div>:<div className="tile notification is-warning"></div>}  
            </div></div>
            </div>
            {loader}
            <button style={{border:"none", background:"transparent"}} onClick={winScroll} > <i className="fa fa-arrow-up" style={{position: "fixed",zIndex:"999", bottom:"20px", right:"10px"}}>
            <p >Top</p> </i> </button>
            </div>    
      </div></div></div>*/}

<div >

<div className="row " style={{margin:'0px'}}>
                  <div className=" navbar-bg col-3 col-lg-3 con-test">

<Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

      <div className="container">
     <div>
     <div >
     <div className="container">   

     
     <h4 className="text-title"><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Books</h4>
      <form onSubmit={(event) => handleglobalsearch(event)}>  
      

      <Row>
      <Col xl={12} lg={12} md={12}>
        <Card>
          <CardBody>
          <FormGroup check row>
          <div className="container">
          
          <Row>
<Col xs={12} lg={4}>
<Label>Search By</Label>
</Col>
  <Col xs={6} lg={4}> 
  <Label >
              <Input
                  type="radio"
                  name="searchoption"
                  id="BookTitle"
                  value="BookTitle"
                  checked={searchOption==="BookTitle"}
                  onChange={(event)=>{setSearchOption(event.target.value);setBookQuery('')}}
              />

              Book Title</Label>
</Col>
<Col xs={6} lg={4}> 
<Label>
             

             <Input
               type="radio"
               name="searchoption"
               id="BookAuthor"
               value="BookAuthor"
               checked={searchOption==="BookAuthor"}
               onChange={(event)=>{setSearchOption(event.target.value);setBookQuery('')}}
               />
              Author</Label>
</Col>
<Col xs={6} lg={4}> 
<Label >
              <Input
                  type="radio"
                  name="searchoption"
                  id="ClassificationNo"
                  value="ClassificationNo"
                  checked={searchOption==="ClassificationNo"}
                  onChange={(event)=>{setBookQuery("210");setSearchOption(event.target.value)}}
                  />

               Classification</Label>
</Col>

  <Col xs={6} lg={4}> 
  <Label>
              <Input
                  type="radio"
                  name="searchoption"
                  id="Accession"
                  value="Accession"
                  checked={searchOption==="Accession"}
                  onChange={(event)=>{setSearchOption(event.target.value);setBookQuery('')}}
              />

              Accession</Label>
</Col>
<Col xs={6} lg={4}> 
  <Label>
              <Input
                  type="radio"
                  name="searchoption"
                  id="Category"
                  value="Category"
                  checked={searchOption==="Category"}
                  onChange={(event)=>{setSearchOption(event.target.value);setBookQuery('21')}}
              />

              Category</Label>
</Col>

  </Row> 

               </div>
              </FormGroup>
             
          {searchOption==="Category" ? <Row className='' >
<Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
<Input id="bookcategory" name="select" type="select" onChange={(event)=>{setBookQuery(event.target.value)}}>
{bookCategory.map(item => {
              return (<option key={item.name} value={item.svalue}>{item.name}</option>);
            })}
</Input></Col>
<Col xs={1} lg={3}><Button className="pull-center">
  <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                  </Button>
</Col>
</Row>:searchOption==="ClassificationNo"?<Row className='' >
<Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
<Input id="activeStatus" name="select" type="select"  onChange={(event)=>{setBookQuery(event.target.value)}}>
{bookClassification.map(item => {
                  return (<option key={item.name} value={item.value}>{item.name}</option>);
                })}

</Input></Col>
<Col xs={1} lg={3}><Button className="pull-center">
  <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                  </Button>
</Col>
</Row>:
<Row className='' >
<Col xs={10} lg={9} style={{marginLeft:'-15px'}}>
<Input
                type="text"
                name="searchvalue"
                id="searchvalue"
                autoFocus="autofocus"
                placeholder="Search here..."
                value={bookQuery} 
  
                onChange={(event)=>{setBookQuery(event.target.value)}}
                
              />
</Col>
<Col xs={1} lg={3}><Button className="pull-center">
  <span className='navbar-bg'>Search</span> <i className='fa fa-search'></i>
                  </Button>
</Col>
</Row>
}
                
{searchResults.length>0?
            <div style={{textAlign:"right", color:"blue", paddingTop:"10px"}}> <b>Download.... </b><i className="fa fa-download" onClick={(event)=>{handleDownload(event)}}></i>
<CSVLink
          headers={bookHeaders}
          filename="Inigo_Library_Books.csv"
          data={searchResults}
          ref={csvLinkEl}
        /></div>:
        <div></div>
                           }
          
              
          </CardBody>
          {dispMsg}
          </Card>
         
{/*allUsers.length>0?<div>
      <Table hover responsive >
      
              <thead>
                <tr>
                  <th>Login Id</th>
                  <th>User Name</th>
                  <th>Status</th>
                  <th>User Role</th>
                  <th>Email Id</th>
                  <th>Phone Number</th>
                
                </tr>
              </thead> 
            
              <tbody>
              {allUsers.map((userresult, index) => (
              <tr key={index}>
                <th scope="row"><button onClick={(event)=>handleUserSelection(event, userresult.loginid)}>{userresult.loginid}</button></th>
                <td>{userresult.userfullname}</td>
                <td>{userresult.account_status} </td>
                <td>{userresult.user_role} </td>
                <td>{userresult.emailid}</td>
                <td>{userresult.phoneno}</td>
                
              </tr>))}
              </tbody>
              </Table>
              </div>:<div></div>*/}
      </Col>           
    </Row> 
    </form>
         
        {/*} <form style={{paddingTop:'15px'}}> 
          <FormGroup>
          <div className="container">
          <div className="row">
          <div className="col-md-2 col-sm-2  col-xs-2">
          <Label for="accessionNoL">Login ID</Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Input
                  type="text"
                  name="Accession_No"
                  value={userDetails.loginid}
                  
               //   onChange={(event)=>{setBook({...book,accessionNo:(event.target.value).toUpperCase()})}}
                  disabled
                />
            </div>
            
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="bookTitleL"> Name </Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Input
                  type="text"
                  name="Book_Title"
                  value={userDetails.userfullname}
                  disabled
            //      onChange={(event)=>{setBook({...book,bookTitle:(event.target.value).toUpperCase()})}}  
                />
            </div>
            </div>
            </div>
              </FormGroup>
              <FormGroup>
              <div className="container">
          <div className="row">
          <div className="col-md-2 col-sm-2  col-xs-2">
          <Label for="bookAuthor">Email</Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Input
                  type="text"
                  name="firstName"
                  value={userDetails.emailid}
               //   onChange={(event)=>{setBook({...book,authorFirst:(event.target.value).toUpperCase()})}}
                  disabled
                />
                
            </div>
            
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="clsNo">Phone No</Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
           
                <InputGroup>
               
    <select  value={countryCode}                         disabled
                    onChange={(event)=>{setCountryCode(event.target.value)}}>
                    
                      
                        {phoneCode.map(item => {
                            return (<option key={item.Country} value={item.Code}>{item.Code}</option>);
                        })}
                          </select>
                          <Input
                  type="text"
                  name="Book_Classification_No"
                  id="clsId"
                  value={userDetails.phoneno}
                  //onChange={(event)=>{setBook({...book,bookClassificationNo:(event.target.value).toUpperCase()})}}
                  disabled
                />
               
               
                </InputGroup>
     
         
                 
            
            </div>
            </div>
            </div>
              </FormGroup>
              <FormGroup>
              <div className="container">
          <div className="row">
          <div className="col-md-2 col-sm-2  col-xs-2">
          <Label for="scope">Role</Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
                {loggedUser.profile==="Admin"?
            <Input type="select" name="Book_Scope"
                 value={userDetails.user_role}
                  onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
                 disabled={isEditMode}
                >
                  <option>Guest</option>
                  <option>Student</option>
                  <option>Staff</option>
                  <option>Librarian</option>
                  <option>Admin</option>
          </Input>:
          <Input type="select" name="Book_Scope"
          value={userDetails.user_role}
           onChange={(event)=>{setUserDetails({...userDetails,user_role:event.target.value})}}
          disabled={isEditMode}
         >
           <option>Guest</option>
           <option>Student</option>
           <option>Staff</option>
           <option>Librarian</option>
   </Input>}
            </div>
            
            <div className="col-md-2 col-sm-2  col-xs-2">
            <Label for="publicationL">Status</Label>
            </div>
            <div className="col-md-4 col-sm-4  col-xs-4">
            <Input
                  type="text"
                  name="Book_Publisher"
                  value={userDetails.account_status}
                 // onChange={(event)=>{setBook({...book,bookPublisher:(event.target.value).toUpperCase()})}}
                  disabled
                />
            </div>
            </div>
            </div>
              </FormGroup>
             
              {searchUserResult.length>0?
              <FormGroup check row className='text-center'>
             
                <Col xs={6} lg={6} >
                <button type="submit"  style={{width:'80px', height:'40px'}}
             className="button-approve is-info is-small"
             onClick={event => {buttonValue==="Edit"?handleUserEdit(event):handleEditSave(event)}}
           >{buttonValue}</button>
                </Col>
                <Col xs={6} lg={6}>
                 <button className="button-del is-info is-small bg-danger"  style={{width:'80px', height:'40px'}}
          onClick={event => handleDeleteUser(event)} 
          >Delete</button>
                </Col>
               
              </FormGroup>:""}
              </form>*/}
         

                {searchResults.length>0?<div>
              <Table hover responsive bordered>
            <thead>
              <tr>
                <th>Accession</th>
                <th>Book Title</th>
                <th>Author</th>
                <th>Classification</th>
                <th>Rack </th>
                <th>Scope</th>
                <th>Status</th>
                <th>Issued</th>
              </tr>
            </thead>      
            <tbody>
      {searchResults.map((searchresult,index) =>(
      <tr key={index}>
        <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
        <td>{searchresult.Book_Title}</td>
        <td>{searchresult.Book_Author} </td>
        <td>{searchresult.Book_Classification_No} </td>
        <td>{searchresult.RackValue}</td>
        <td>{searchresult.Book_Scope}</td>
        <td> {searchresult.Book_Status}</td>
        <td><p>{searchresult.username}</p><p>{searchresult.username==="NA"?"": (searchresult.issued_on).substring(0,10)}</p></td>
      </tr>))}
    
      </tbody></Table>

          </div>:<div className="tile notification is-warning"></div>}  
       
          </div>
          </div>

            </div>
          
          <div>

          
          </div>
          {loader}
          <button style={{border:"none", background:"transparent"}} onClick={winScroll} > <i className="fa fa-arrow-up" style={{position: "fixed",zIndex:"999", bottom:"20px", right:"10px"}}>
            <p >Top</p> </i> </button>
           
          </div>
  </div>
      </div></div>
      
      
      
      </div>

      :<div>"You are not Signed In"</div>}
      </div>
      )
}
export default SearchBooks;