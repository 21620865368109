import React, { useContext, useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import axios from "axios";
import { Auth } from "aws-amplify";
import { Table } from 'reactstrap';
import { ProductContext } from '../../ProductProvider';
//import SearchBookDisplay from './SearchBookDisplay';
//import UserView from '../user/UserView';
import useFullPageLoader from "../hooks/useFullPageLoader";
import { bookClassification } from '../../BookData';
import Sidebar from '../UserDashboard/Sidebar';

const config = require('../../config.json');

export default function IssueBooks() {
    const {loggedUser} = useContext(ProductContext);
    const [usernameSearch, setUsernameSearch] = useState('');
    const [accessionNoSearch, setAccessionNoSearch] = useState('');
    const [userSearchResult, setUserSearchResult] = useState('');
    const [dispUserMsg, setDispUserMsg] = useState('');
    const [dispBookMsg, setDispBookMsg] = useState('');
    const [bookResults, setBookResults] = useState('');
    const [issueButton, setIssueButton] = useState(true);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [searchOption, setSearchOption] = useState("userfullname");
    const [loginValue, setLoginValue]=useState('');
    const [userEmail, setUserEmail] = useState('');
    const [bookSearchResults, setBookSearchResults] = useState('');

    
    const handleuserbooksearch = async(event) => {
        event.preventDefault();
        setDispUserMsg("");
        setUserSearchResult("");
        setBookResults("");
          if(usernameSearch==="")
          {
            setDispUserMsg("Enter the username");
          }else{
            showLoader();
          try {  
            var searchValue=usernameSearch; 
            if(searchOption==="userfullname"){
            const str = usernameSearch;
            const arr = str.split(" ");
            //loop through each element of the array and capitalize the first letter.
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);

            }
            //Join all the elements of the array back into a string 
            //using a blankspace as a separator 
            searchValue = arr.join(" ");
            //console.log(str2);
          }
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //validating with cognito
            // await axios.get(`${config.api.invokeUrl}/books/mybooks?logid=${usernameSearch}&familyName=${loggedUser.familyName}`,{
            //validating with inigo_library_users
            //const searchoption = "loginid";
            await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${searchValue}&searchoption=${searchOption}&familyName=${loggedUser.familyName}`,{
               headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                }
            })
            .then((response)=>{
              //console.log("Response : ", response);
                if(response.status===205){
                    setDispUserMsg("User does not exist. Enter the right details.");
                   
                }else if(response.status===210){
                    setDispUserMsg("Multiple Users Found. Enter the right details.");
                    setUserSearchResult(response.data);

                }
                else if(response.data[0].user[0].account_status==="UNCONFIRMED"){
                  setDispUserMsg("You are yet to Confirm your account. Check your email to confirm your account.");
                } else if(response.data[0].user[0].user_role==="Guest"){
                  setDispUserMsg("Your account is marked as Guest. Librarian / Admin use the 'Manage Users' option to change the User role.");
                } /*else if(response.data[0].user[0].user_role==="Student" && response.data[1].book.length>5) {
                  //console.log("Books count : ",response.data[1].book.length);
                  setDispUserMsg("Max 5 books are allowed for the Student");
                  setBookResults(response.data[1].book);
                }*/else{
                    setUserSearchResult(response.data[0].user);
                    //console.log("Response : ", response);
                    //console.log("login id : ", response.data[0].user[0].loginid);
                    setLoginValue(response.data[0].user[0].userfullname);
                    setUserEmail(response.data[0].user[0].emailid);
                    setBookResults(response.data[1].book);
                    setIssueButton(false);
                }
            })
            .catch((error)=>{
                setDispUserMsg("Error Occured - " + error.message);
            })  
          } catch (error) {
            setDispUserMsg("Error Occured - " + error.message);
          }
          hideLoader();          

        }
        }

    const handleUserSelection = async(event, userDetails)=>{
      event.preventDefault();
      showLoader();
      try {   
        // console.log("Params:", searchParams);
        const tempSearchOption="loginid";
        const session = await Auth.currentSession();
        const access_token=session.idToken.jwtToken;
        await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${userDetails}&searchoption=${tempSearchOption}&familyName=${loggedUser.familyName}`,{
         headers: {
           Authorization: access_token,
           'x-api-key':config.api.key
         }
       })
       .then((response)=>{
         //console.log("Response : ", response);
         setUserSearchResult(response.data[0].user);
         setLoginValue(response.data[0].user[0].userfullname);
         setUserEmail(response.data[0].user[0].emailid);
         setBookResults(response.data[1].book);
         setIssueButton(false);

    })
    .catch((error)=>{
      setDispUserMsg("Error Occured" + error.message);
    })
                 
     } catch (error) {
      setDispUserMsg(`An error has occurred: ${error.message}`);
     }
     hideLoader();

    }

    const handleissuebook = async(event, tempAccessionNo, tempBookTitle) => {

        event.preventDefault();
        const issue_datetime = new Date().toLocaleString('en-US', {timeZone: 'Asia/Kolkata'});
        var return_datetime = new Date();
        console.log("Issue : ", issue_datetime);
        return_datetime.setDate(return_datetime.getDate()+30);
        console.log("Return : ", return_datetime);
        setDispBookMsg("");  
        showLoader();
        try {
                const session = await Auth.currentSession();
                const access_token=session.idToken.jwtToken;
                const params = {
                  "Accession_No": tempAccessionNo,
                  "Book_Title":tempBookTitle,
                  "Book_Status":"Issued",
                  "issued_by": loggedUser.username,
                  "issued_on":issue_datetime,
                  "due_date":return_datetime,
                  "username":loginValue,
                  "familyname":loggedUser.familyName,
                  "email":userEmail     
                };

                //console.log("Params : ", params);
                const Accession_No = accessionNoSearch;
                await axios.patch(`${config.api.invokeUrl}/books/manage/transaction/${Accession_No}`, params, {
                  headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                  }});
                  
                    setDispBookMsg(Accession_No + " - Book Issued Successfully");
                   // const bookUpdated = resOutput.data;
                    const productToUpdate = [...bookSearchResults].find(searchresult => searchresult.Accession_No === Accession_No);
                    productToUpdate.Book_Status = "Issued";
                    productToUpdate.username = loginValue;
                    const booktemp=bookResults;

                    booktemp.push(productToUpdate);
                    setBookResults(booktemp);
                    setBookSearchResults('');
                
            
            } catch (error) {
                setDispBookMsg("An error has occurred : "+ error.message);
              }
              hideLoader();
           }
     

      const handleAccessionValidation = async(event) =>{
        event.preventDefault();
        setDispBookMsg('');
        try {
          if(usernameSearch===""){
            setDispBookMsg("Enter the user details first!");
        }else if(accessionNoSearch===""){
            setDispBookMsg("Enter the Accession No to Issue the Book!");
        }else{    
            showLoader();
              const session = await Auth.currentSession();
              const access_token=session.idToken.jwtToken;
              const searchOption="Accession";
              const res = await axios.get(`${config.api.invokeUrl}/books/search?searchValue=${accessionNoSearch}&searchOption=${searchOption}&familyName=${loggedUser.familyName}`,{
                  headers: {
                  Authorization: access_token,
                  'x-api-key':config.api.key
                  }
              });
             // console.log("Response : ", res);
              if(res.data.length>0){
                if(res.data[0].Book_Scope==="REFERENCE"||res.data[0].Book_Scope==="NOT Available"){
                  setDispBookMsg(accessionNoSearch + " - belongs to " + res.data[0].Book_Scope + " category. Only Circulation type books can be issued");
                } else  if(res.data[0].username!=="NA"){
                  setDispBookMsg(accessionNoSearch + " - Already Issued to " + res.data[0].username + ". Return the Book first before issuing it again");  
                }else {
                  setDispBookMsg(res.data.length + " - Books found");
                  var i=0;
                while(i<res.data.length){
                 const tempClassValue= [...bookClassification].filter(searchresult =>searchresult.value===res.data[i].Book_Classification_No);
                 res.data[i].Book_Classification_No=tempClassValue[0].name;
                 i++;
                }
                  setBookSearchResults(res.data);
                }
              }else{
                setDispBookMsg("Accession No not right!");
              }
          }
      } catch (error) {
        setDispBookMsg(error.message);
      }
      hideLoader();
      }
     
     

        const handleAccessionInput = (event) =>{
            setUsernameSearch(event.target.value);
            setUserSearchResult('');
            setBookResults('');
            setIssueButton(true);
            setBookSearchResults('');
            setDispBookMsg('');

        }
     
  

    return (

<div className="dash-board" style={{paddingBottom:'120px'}}  >
{loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
<div>
        <div className="navbar-mobile">

        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px', paddingBottom:'20px'}}>
        <div >
<div>
  <h4 className="text-title"><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Books</h4>
    <Row>
<Col xl={12} lg={12} md={12}>
<Card>

<CardBody>
<Form >


<FormGroup check row>
  <div >
  
  <div className="navbar-mobile">
  <div className="row">
 
  <div className="col-md-6 col-sm-6  col-xs-6">
       <Label >
      <Input
          type="radio"
          name="searchoption"
          id="userfullname"
          value="userfullname"
          checked={searchOption==="userfullname"}
          onChange={(event)=>setSearchOption(event.target.value)}
      />

      User Name</Label>
        </div>
      <div className="col-md-6 col-sm-6  col-xs-6">
      <Label>
        <Input
          type="radio"
          name="searchoption"
          id="loginid"
          value="loginid"
          checked={searchOption==="loginid"}
          onChange={(event)=>setSearchOption(event.target.value)}
          />
         Login ID</Label>
      </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-sm-6  col-xs-6">
      <Label >
      <Input
          type="radio"
          name="searchoption"
          id="email"
          value="email"
          checked={searchOption==="email"}
          onChange={(event)=>setSearchOption(event.target.value)}
      />

       Email</Label>
        </div>

        <div className="col-md-6 col-sm-6  col-xs-6">
      <Label>
      <Input
          type="radio"
          name="searchoption"
          id="phoneno"
          value="phoneno"
         
          checked={searchOption==="phoneno"}
          onChange={(event)=>setSearchOption(event.target.value)}
      />

       Phone No</Label>
        </div>
      
       </div>
  </div>
       </div>
      </FormGroup>

                 <FormGroup check row style={{paddingLeft:"0px"}}>
                 <div className="container">
<div className="row  navbar-bg">
<div className="col-lg-3 col-md-3 col-sm-3  col-xs-3">
  <Label for="username">User Name</Label>
  </div>
 
 </div>
 <div className="row">
 <div className="col-lg-12 col-md-12 col-sm-12  col-xs-12">
  <span className="input-group-text"> 
  <Input
        type="text"
        name="usernameinput"
        placeholder="Validate User"
        autoFocus="autofocus"

        value={usernameSearch} 

        onChange={(event)=>{handleAccessionInput(event)}}
        
      />
           <Button 
          onClick={event => handleuserbooksearch(event)}
        ><i className="fa fa-question-circle"></i>
        </Button>
       </span>

  </div>
 </div>

  </div>
        
    </FormGroup>

    {userSearchResult.length>0?
<div>     

<Table hover responsive>

<thead>
<tr>
  <th>Login Id</th>
  <th>User Name</th>
  <th>Email</th>
  <th>Phone</th>
  <th>Role</th>
  <th>Status</th>
</tr>
</thead>  
<tbody>
{userSearchResult.map((searchuser, index) => (
<tr  key={index}>
<th scope="row"><button onClick={(event)=>{handleUserSelection(event, searchuser.loginid)}}>{searchuser.loginid}</button></th>
<td>{searchuser.userfullname}</td>
<td>{searchuser.emailid}</td>
<td>{searchuser.phoneno}</td>
<td>{searchuser.user_role}</td>
<td>{searchuser.account_status}</td>
</tr>))}
{/*  <UserView loginid= {searchuser.loginid} 
userfullname={searchuser.userfullname} emailid={searchuser.emailid} 
phoneno={searchuser.phoneno} user_role={searchuser.user_role}
status={searchuser.account_status} 
key={searchuser.loginid} /> */}
</tbody>

</Table>
      
</div>:<div className="tile notification is-warning">{dispUserMsg}</div>}
     


  <FormGroup check row style={{paddingLeft:"0px"}}>
                 <div className="container">
<div className="row navbar-bg">
<div className="col-md-3 col-sm-3  col-xs-3">
  <Label for="accessionno">Accession</Label>
  </div>
 
  </div>
 <div className="row">
 <div className="col-md-12 col-sm-12  col-xs-12">
  
  <span className="input-group-text"> 
  <Input
  type="text"
  name="accno"
  placeholder="Acc. No to Issue Book"
  value={accessionNoSearch} 
  onChange={(event)=>{setAccessionNoSearch(event.target.value)}}
  
/>
      <Button 
            disabled ={issueButton}
            onClick={event => handleAccessionValidation(event)}
            ><i className="fa fa-plus-circle"></i>

            </Button>
                
          </span>
</div>
</div>
  </div>
     
      
    </FormGroup>
    {dispBookMsg}

    
  </Form>
  {bookSearchResults.length>0?<div>
      <Table hover responsive bordered>
    <thead>
      <tr>
        <th>Action</th>
        <th>Accession</th>
        <th>Book Title</th>
        <th>Author</th>
        <th>Classification</th>
        <th>Scope</th>
        <th>Status</th>
        <th>Issued</th>
      </tr>
    </thead>      
    <tbody>
{bookSearchResults.map((searchresult,index) =>(
<tr key={index}>
<th><button onClick={(event)=>handleissuebook(event, searchresult.Accession_No, searchresult.Book_Title)}>Confirm</button></th>
<th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
<td>{searchresult.Book_Title}</td>
<td>{searchresult.Book_Author} </td>
<td>{searchresult.Book_Classification_No} </td>
<td>{searchresult.Book_Scope}</td>
<td> {searchresult.Book_Status}</td>
<td>{searchresult.username}</td>
</tr>))}

</tbody></Table>

  </div>:<div className="tile notification is-warning"></div>}  

</CardBody>
</Card>
</Col>


</Row> 



{bookResults.length>0?<div>

  <Table hover responsive>

<thead>
<tr>
  <th>Accession</th>
  <th>Book Title</th>
  <th>Author</th>
  <th>Classification</th>
  <th>Scope</th>
  <th>Status</th>
  <th>Issued</th>
</tr>
</thead>             
<tbody>
{bookResults.map((searchresult, index) => (
<tr key={index}>
<th scope="row">{searchresult.Accession_No}</th>
<td>{searchresult.Book_Title}</td>
<td>{searchresult.Book_Author}</td>
<td>{searchresult.Book_Classification_No}</td>
<td>{searchresult.Book_Scope}</td>
<td> {searchresult.Book_Status}</td>
<td>{searchresult.username}</td>
</tr>))}


</tbody>
</Table>
</div>:<div className="tile notification is-warning"></div>}  
</div>
{loader}
</div>

        </div>
        </div>

        <div className="navbar-bg">
  <div className="row " style={{margin:'0px'}}>
                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

        <div className="container">

          <div className="container">
            <h4 className="text-title"><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Books</h4>
              <Row>
      <Col xl={12} lg={12} md={12}>
        <Card>
         
          <CardBody>
          <Form >


          <FormGroup check row>
            <div className="container">
            <div className="navbar-bg">
            <div className="row">
           
            <div className="col-md-3 col-sm-3  col-xs-3">
                 <Label >
                <Input
                    type="radio"
                    name="searchoption1"
                    id="userfullname"
                    value="userfullname"
                    checked={searchOption==="userfullname"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                User Name</Label>
                  </div>
                <div className="col-md-3 col-sm-3  col-xs-3">
                <Label>
                  <Input
                    type="radio"
                    name="searchoption1"
                    id="loginid"
                    value="loginid"
                    checked={searchOption==="loginid"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                    />
                   Login ID</Label>
                </div>
                

                  <div className="col-md-3 col-sm-3  col-xs-3">
                <Label >
                <Input
                    type="radio"
                    name="searchoption1"
                    id="email"
                    value="email"
                    checked={searchOption==="email"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                 Email</Label>
                  </div>

                  <div className="col-md-3 col-sm-3  col-xs-3">
                <Label>
                <Input
                    type="radio"
                    name="searchoption1"
                    id="phoneno"
                    value="phoneno"
                   
                    checked={searchOption==="phoneno"}
                    onChange={(event)=>setSearchOption(event.target.value)}
                />

                 Phone No</Label>
                  </div>
                 
                
                 </div>
            </div>
                </div>
                </FormGroup>

                           <FormGroup check row>
                           <div className="container">
          <div className="row  navbar-bg">
          <div className="col-lg-3 col-md-3 col-sm-3  col-xs-3">
            <Label for="username">User Name</Label>
            </div>
           
           </div>
           <div className="row">
           <div className="col-lg-12 col-md-12 col-sm-12  col-xs-12">
            <span className="input-group-text"> 
            <Input
                  type="text"
                  name="usernameinput"
                  placeholder="Validate User"
                  autoFocus="autofocus"

                  value={usernameSearch} 
    
                  onChange={(event)=>{handleAccessionInput(event)}}
                  
                />
                     <Button 
                    onClick={event => handleuserbooksearch(event)}
                  ><i className="fa fa-question-circle"></i>
                  </Button>
                   
              
                         
                                
                          </span>
            
         
                
            </div>
           </div>
          
            </div>
                  
              </FormGroup>

              {userSearchResult.length>0?
        <div>
      
        <Table hover responsive>
        
        <thead>
          <tr>
            <th>Login Id</th>
            <th>User Name</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Role</th>
            <th>Status</th>
          </tr>
        </thead>  
        <tbody>
        {userSearchResult.map((searchuser, index) => (
          <tr  key={index}>
          <th scope="row"><button onClick={(event)=>{handleUserSelection(event, searchuser.loginid)}}>{searchuser.loginid}</button></th>
          <td>{searchuser.userfullname}</td>
          <td>{searchuser.emailid}</td>
          <td>{searchuser.phoneno}</td>
          <td>{searchuser.user_role}</td>
          <td>{searchuser.account_status}</td>
        </tr>))}
    
          </tbody>
       
        </Table>
                
  </div>:<div className="tile notification is-warning">{dispUserMsg}</div>}
               
         
         
            <FormGroup check row>
                           <div className="container">
          <div className="row navbar-bg">
          <div className="col-md-3 col-sm-3  col-xs-3">
            <Label for="accessionno">Accession</Label>
            </div>
           
            </div>
           <div className="row">
           <div className="col-md-12 col-sm-12  col-xs-12">
            
            <span className="input-group-text"> 
            <Input
            type="text"
            name="accno"
            placeholder="Acc. No to Issue Book"
            value={accessionNoSearch} 
            onChange={(event)=>{setAccessionNoSearch(event.target.value)}}
            
          />
                <Button 
                      disabled ={issueButton}
                      onClick={event => handleAccessionValidation(event)}
                      ><i className="fa fa-plus-circle"></i>
        
                      </Button>
                          
                    </span>
      </div>
      </div>
            </div>
               
                
              </FormGroup>
              {dispBookMsg}

              
            </Form>
            {bookSearchResults.length>0?<div>
                <Table hover responsive bordered>
              <thead>
                <tr>
                  <th>Action</th>
                  <th>Accession</th>
                  <th>Book Title</th>
                  <th>Author</th>
                  <th>Classification</th>
                  <th>Scope</th>
                  <th>Status</th>
                  <th>Issued</th>
                </tr>
              </thead>      
              <tbody>
        {bookSearchResults.map((searchresult,index) =>(
        <tr key={index}>
          <th><button onClick={(event)=>handleissuebook(event, searchresult.Accession_No, searchresult.Book_Title)}>Confirm</button></th>
          <th scope="row" key={searchresult.Accession_No}>{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author} </td>
          <td>{searchresult.Book_Classification_No} </td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
      
        </tbody></Table>

            </div>:<div className="tile notification is-warning"></div>}  
    
          </CardBody>
        </Card>
      </Col>

         
    </Row> 

  {bookResults.length>0?<div>
            <Table hover responsive>
        
        <thead>
          <tr>
            <th>Accession</th>
            <th>Book Title</th>
            <th>Author</th>
            <th>Classification</th>
            <th>Scope</th>
            <th>Status</th>
            <th>Issued</th>
          </tr>
        </thead>             
        <tbody>
        {bookResults.map((searchresult, index) => (
        <tr key={index}>
          <th scope="row">{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author}</td>
          <td>{searchresult.Book_Classification_No}</td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
       
        
        </tbody>
        </Table>
      </div>:<div className="tile notification is-warning"></div>}  
      </div>
      {loader}
    </div>
        </div>
        </div></div></div>:<div>"You are not authorised to view this page"</div>}
        </div>
     
    )
}
