import React,{useState} from 'react';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import signImg from '../../images/auth.gif';



function ForgotPassword() {
    let history = useHistory();
    const [email, setEmail] = useState('');
    const [dispMsg, setDispMsg] = useState('');

    const forgotPasswordHandler = async(event) => {
        event.preventDefault();
        if(email===""){
          setDispMsg("Enter your account email address to reset the password!");
        }else{
        // AWS Cognito integration here
        try{
            await Auth.forgotPassword(email);
            history.push(`/forgotpasswordverification`);

        }catch(error)
        {
            setDispMsg(error.message);
        }
      }
      }
    

    return (
        <section className="section auth">
        <div className="popup-container">
        <div className="login-container">
          <h4 className="header-text-sm">Forgot password?</h4>
          <div className="avatar-icon">
            <img className="signupimg" src={signImg} alt="Sign Up"/> 
          </div>
         
          <p className="container" style={{color:'white'}}>
            Please enter the email address associated with your account and we'll
            email you a password reset link.
          </p>

          <form onSubmit={(event)=>forgotPasswordHandler(event)}>
            <div className="field">
              <p className="control has-icons-left has-icons-right">
                <input
                  type="email"
                  className="input"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(event)=>{setEmail(event.target.value)}}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="container" style={{color:'white'}}>
                {dispMsg}
                </p>
            </div>
            <div className="field" style={{display: 'flex', justifyContent:'center'}}>
              <p className="control">
                <button style={{backgroundColor:'var(--mainGreen)'}} className="btn button is-success">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
        </div>
      </section>
   
    )
}

export default ForgotPassword;
