import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, withRouter} from "react-router-dom";


import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { UncontrolledDropdown,DropdownToggle,DropdownItem,DropdownMenu  } from "reactstrap";

import logo1 from '../images/logo.png';
import '../css/Styles.css';
import {ProductContext} from '../ProductProvider';

function LibNavbar() {
  const{loggedUser} = useContext(ProductContext);
  //const [open, setOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
    
    
    const toggleNavbar=()=> {
        setCollapsed(!collapsed);
    }
    
  /*  const closeNavbar=()=> {
        if (collapsed) {
        toggleNavbar();
        }
    }*/
    
        return (
          <div className="d-flex ">
          <Navbar collapseOnSelect fixed='top' expand ='sm'  variant='light' className='ml-auto' style={{backgroundColor:'#001822'}}>
       <div className="flex-fill">
       <Nav >
                   <Nav.Link href='/'style={{padding:'0px'}}>
                   <div className="d-flex flex-row justify-content-around text-center h-100">
           <div className=''>
               <img className='navbarnew-brand-logo' src={logo1}   alt="store" /> 
           </div>
           <div className='brandName align-middle my-auto'>
               <h5 style={{color:'white', lineHeight:'32px', paddingLeft:'15px'}}>ARULKADAL INIGO LIBRARY<br/> 
               <span className='brandtamil'> mUs;fly; ,dpNfh E}yfk;</span> </h5> 
           </div>
           </div>
                  
               
          </Nav.Link>
       
                    

                   
               </Nav>
       
       </div>
   {/*    <Navbar.Toggle    aria-controls="offcanvasNavbar" style={{backgroundColor:'white', padding:'6px', margin:'15px'}}/> */}
       {loggedUser.role==="Admin" ? <div className=" flex-fill text-center">
       <Nav className=' flex-fill m-2'>
               <Container style={{maxWidth:'500px'}}>
              

          
          
              </Container> 
               </Nav>
       
       </div>:null}
       <div className=" d-flex  text-right " >
       <Navbar.Collapse id='responsive-navbar-nav'>
                  
                    <ul className="navbar-nav ml-auto">
                      {!loggedUser.username && (
                        <Nav className="nav-auth-btn p-2 nav-link "  >
                        <Nav.Link style={{color:'brown', marginLeft:'10px', background:'white', borderRadius:'10px', marginBottom:'10px'}} 
                        className="nav-auth-btn p-2 nav-link" href="/signin"><b>Home</b></Nav.Link>
                        </Nav>)}
                    {loggedUser.username && (
                    <li ><a href="/dashboard" className="nav-link"><p style={{color:'white'}}> 
                    <u>Home</u>
                  </p></a></li>)}
                    {loggedUser.isAuthenticated && (
                                    <p style={{color:'white'}} className="nav-link">
                                      Welcome {loggedUser.name} !
                                    </p>
                    )}
                    {!loggedUser.isAuthenticated && 
                  <div>
                  
                  </div>  }
              
                
                </ul>              
             
       {loggedUser.isAuthenticated?
       <div>
       <div >
      
       </div>
      
                </div>   :
                       <Nav className="nav-auth-btn p-2 nav-link "  >
                       <Nav.Link style={{color:'brown', marginLeft:'10px', background:'white', borderRadius:'10px', marginBottom:'10px'}} className="nav-auth-btn p-2 nav-link" href="/signin"><b>Sign In</b></Nav.Link>
                       <Nav.Link style={{color:'brown', marginLeft:'10px', background:'white', borderRadius:'10px', marginBottom:'10px'}} className=" p-2 nav-link" href="/signup"><b>Sign Up</b></Nav.Link>
                       </Nav>
                      
                       }

                  
            
               
              
           </Navbar.Collapse>
                       
       
       </div>
       </Navbar>
     </div> 
  );    
}

export default withRouter(LibNavbar);

const NavWrapper = styled.nav `

background: var(--mainDarkBlue);
.nav-link {
  color: var(--mainWhite) !important;
  font-size: 1.3rem;
  text-transform: capitalize;
    
}
 
    
    `