import React, { useContext, useEffect, useState } from 'react';
import axios from "axios";
import { Auth } from "aws-amplify";
import { Table } from 'reactstrap';
import useFullPageLoader from "../hooks/useFullPageLoader";
import Sidebar from '../UserDashboard/Sidebar';
import { ProductContext } from '../../ProductProvider';
//import SearchBookDisplay from '../books/SearchBookDisplay';

const config = require('../../config.json');

export default function MyBooks() {
    const {loggedUser} = useContext(ProductContext);
    const [bookResults, setBookResults] = useState('');
    const [dispMsg, setDispMsg] = useState('');
    const [loader, showLoader, hideLoader] = useFullPageLoader();


    useEffect(() => {
        handleuserbooksearch();
        
    },[]);

    const handleuserbooksearch = async() => {
        //event.preventDefault();
        setDispMsg("");
        setBookResults("");
        showLoader();
        try {   
            const session = await Auth.currentSession();
            const access_token=session.idToken.jwtToken;
            //validating with cognito
            // await axios.get(`${config.api.invokeUrl}/books/mybooks?logid=${usernameSearch}&familyName=${loggedUser.familyName}`,{
            //validating with inigo_library_users
            const searchoption = "loginid";
            await axios.get(`${config.api.invokeUrl}/users/manage?searchkey=${loggedUser.username}&searchoption=${searchoption}&familyName=${loggedUser.familyName}`,{
               headers: {
                    Authorization: access_token,
                    'x-api-key':config.api.key
                }
            })
            .then((response)=>{
                if(response.status===205){
                    setDispMsg("User does not exist. Enter the right details.");
                   
                }else if(response.status===210){
                    setDispMsg("Multiple Users Found. Enter the right details.");
                }
                else{
                    setDispMsg(response.data[1].book.length + " - Book's assigned to your account.");
                    setBookResults(response.data[1].book);
                }
            })
            .catch((error)=>{
                setDispMsg("Error Occured" + error.message);
            })            
           
          } catch (error) {
            setDispMsg("Error Occured - " + error.message);
        }
        hideLoader();
        }    

    return (

        <div className="dash-board" style={{paddingBottom:'80px'}} >
            {loggedUser.isAuthenticated?

<div>
        <div className="navbar-mobile">
      
        <div className="col-12 col-sm-12 col-lg-12" style={{paddingTop:'10px', height:'1000px'}}>
        <div className="container">
         <h4 className="text-title"><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</h4>
         <br/>
{bookResults.length>0?<div className="container grid-container">
    <div className="tile notification is-warning">{dispMsg}</div> <br/>
   
   <Table dark hover responsive>
        
      <thead>
        <tr>
          <th>Accession</th>
          <th>Title</th>
          <th>Author</th>
          <th>Classification</th>
          <th>Scope</th>
          <th>Status</th>
          <th>Issued</th>

        </tr>
      </thead>
    <tbody>
    {bookResults.map((searchresult, index) => ( 

        <tr key={index}>
          <th scope="row">{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author}</td>
          <td>{searchresult.Book_Classification_No} </td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
       
      </tbody>
   </Table>
    </div>:<div className="tile notification is-warning">{dispMsg}</div>}  
    </div>
   
        </div>
        </div>
        <div className="navbar-bg">
  <div className="row " style={{margin:'0px'}}>
                    <div className="col-3 col-lg-3 con-test">

  <Sidebar />
</div>

<div className="col-12 col-sm-9 col-lg-9" style={{marginTop:'50px'}}>

        <div className="container">
            <br/>
        <div className="container">
         <h4 className="text-title"><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</h4>
         <br/>
{bookResults.length>0?<div className="container grid-container">
    <div className="tile notification is-warning">{dispMsg}</div> <br/>
    {/*
<Table className="bg-container">
                    <tbody>
                      <tr>
                      <th width="10%" className="table-accession-title">Acc. No</th>
                      <th width="30%" className="table-book-title">Book Title</th>
                      <th width="20%" className="table-author-title">Author</th>
                      <th width="8%" className="table-head-cls">Clasification</th>
                      <th width="8%" className="table-head-scope">Scope</th>
                      <th width="12%" className="table-head-stat">Status</th>
                      <th width="12%" className="table-head-stat">Issued To</th>
                      </tr>

    </tbody></Table> 
 
         <div className="table-header" style={{display:'flex',flexDirection:'row', justifyContent: 'center'}}>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Acc. No </p>
    </div>
    <div style={{flex:'1', width:'30', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Book Title</p>
    </div>
    <div style={{flex:'1', width:'20', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Author</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Clasification</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Scope</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Status</p>
    </div>
    <div style={{flex:'1', width:'10', backgroundColor: '#99e1fd'}}>
    <p style={{ fontWeight: 'bold', paddingTop:'10px'}}>Issued To</p>
    </div>
    </div>      
   */}
   
   <Table dark hover responsive>
        
      <thead>
        <tr>
          <th>Accession</th>
          <th>Title</th>
          <th>Author</th>
          <th>Classification</th>
          <th>Scope</th>
          <th>Status</th>
          <th>Issued</th>

        </tr>
      </thead>
    <tbody>
    {bookResults.map((searchresult, index) => ( 

        <tr key={index}>
          <th scope="row">{searchresult.Accession_No}</th>
          <td>{searchresult.Book_Title}</td>
          <td>{searchresult.Book_Author}</td>
          <td>{searchresult.Book_Classification_No} </td>
          <td>{searchresult.Book_Scope}</td>
          <td> {searchresult.Book_Status}</td>
          <td>{searchresult.username}</td>
        </tr>))}
       {/* <SearchBookDisplay Accession_No= {searchresult.Accession_No} Book_Title={searchresult.Book_Title} 
      Book_Author={searchresult.Book_Author} Book_Classification_No={searchresult.Book_Classification_No} 
       Book_Scope={searchresult.Book_Scope} Book_Status={searchresult.Book_Status} username={searchresult.username} 
    key={searchresult.Accession_No} /> */}
      </tbody>
   </Table>
    </div>:<div className="tile notification is-warning">{dispMsg}</div>}  
    </div>
    {loader}

    </div>
    </div>
        </div></div></div>:<div>"You are not authorised to view this page"</div>}
        </div>
   
    )
}
