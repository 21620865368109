import React, { useState } from 'react';
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import signImg from '../../images/auth.gif';


function ForgotPasswordVerification() {
    let history= useHistory();
    const [verificationCode, setVerificationCode] = useState('');
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [dispMsg, setDispMsg]=useState('');

    const passwordVerificationHandler = async(event) => {
        event.preventDefault();
        setDispMsg('');
        if(verificationCode===""||email===""||newPassword===""){
          setDispMsg("Fill in all detail to set up your new password!");
        }else{

        // AWS Cognito integration here
        try{
          await Auth.forgotPasswordSubmit(
            email,
            verificationCode,
            newPassword
          );
          history.push('/changepasswordconfirmation');
        }catch(error)
        {
            setDispMsg(error.message);
        }
      }
    };

    return (
        <section className="section auth">
        <div className="popup-container">
        <div className="signup-container">
          <h4 className="header-text-sm">Set new password</h4>
          <div className="avatar-icon">
            <img className="signupimg" src={signImg} alt="Sign Up"/> 
          </div>
          
          <p className="container" style={{color:'white'}}>
            Please enter the verification code sent to your email address below,
            your email address and a new password.
          </p>

          <form onSubmit={passwordVerificationHandler} style={{marginLeft:'15px'}}>
            <div className="field">
              <p className="control">
                <input
                  type="text"
                  className="input-signup"
                  id="verificationcode"
                  aria-describedby="verificationCodeHelp"
                  placeholder="Enter verification code"
                  value={verificationCode}
                  onChange={(event)=>{setVerificationCode(event.target.value)}}
                />
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input 
                  className="input-signup" 
                  type="email"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                  value={email}
                  onChange={(event)=>{setEmail(event.target.value)}}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
              </p>
            </div>
            <div className="field">
              <p className="control has-icons-left">
                <input
                  type="password"
                  className="input-signup"
                  id="newpassword"
                  placeholder="New password"
                  value={newPassword}
                  onChange={(event)=>{setNewPassword(event.target.value)}}
                />
                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
              </p>
            </div>
            <div className="field">
            <p className="container" style={{color:'white'}}>
                {dispMsg}
                </p>
            </div>
            
            <div className="field" style={{display: 'flex', justifyContent:'center', marginLeft:'-15px'}}>
              <p className="control">
                <button style={{backgroundColor:'var(--mainGreen)'}} className="btn button is-success">
                  Submit
                </button>
              </p>
            </div>
          </form>
        </div>
        </div>
      </section>
        )
}

export default ForgotPasswordVerification;
