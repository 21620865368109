import React, {useState} from 'react';

const ProductContext = React.createContext();;

const ProductProvider = (props) => {

const [loggedUser, setLoggedUser] = useState({username: 'Guest', email: '', phoneno: '', familyName:'', profile:'', name:'', isAuthenticated:false});
const [overdueBooks, setOverdueBooks]=useState([]);
const [approachingBooks, setApproachingBooks]=useState([]);
const [showPage, setShowPage]=useState(false);


    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            overdueBooks,
            setOverdueBooks,
            approachingBooks,
            setApproachingBooks,
            setShowPage
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};
