import React, { useState, useEffect, useContext } from 'react';
import { Auth } from "aws-amplify";
//import signInImg from '../../images/signin-image.jpg';
import { ProductContext } from '../../ProductProvider';

export default function ChangePassword() {
    const { loggedUser } = useContext(ProductContext)
    const [dispMsg, setDispMsg] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');

    
    useEffect(() => {

        window.scrollTo(0,0);
        
    }, []);

    const handleChangePassword = async(event) =>{
        event.preventDefault();
        setDispMsg('');
        try{
            if(oldPassword==="" || newPassword===""){
                setDispMsg("Enter the password!");
            }else{
            await Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                if(data==="SUCCESS"){
                    setOldPassword('');
                    setNewPassword('');
                    setDispMsg("Password Changed Successfully!");
                    
                }})
            .catch(err => setDispMsg("Error Occured : ", err.message));
            }
        }catch(error){
            setDispMsg("Error Occured : ", error.message);
        }
    }

    return (
            <div className="login">
           <section  className="sign-in">
            <div className="container">
                <div className="signin-content">
                   {/*} <div className="signin-image">
                        <figure><img src={signInImg} alt="sign up"/></figure>
                        
    </div>*/}

                    <div className="signin-form">
                        <h2 style={{ fontFamily: "Oswald"}} className="form-title">Change Password</h2>
                        <form className="register-form" id="login-form">
                        <div  style={{ fontFamily: "Oswald"}} className="form-group"> 
                                <div className="form-group">
                                
                                <label id="email"><i className="fa fa-envelope"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="email" name="email" id="email" placeholder="Your Email"
                                value={loggedUser.email}
                                disabled />
                            </div>

                            <div>
                            <div className="form-group">
                                
                                <label id="code"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="oldPassword" id="oldPassword" placeholder="Old Password"
                                value={oldPassword}
                                onChange={(event)=>{setOldPassword(event.target.value)}}/>
                            </div>
                            <div className="form-group">
                                
                                <label id="password"><i className="fa fa-key"></i></label>
                                <input style={{ fontFamily: "Oswald"}} type="password" name="newPassword" id="newPassword" placeholder="New Password"
                                value={newPassword}
                                onChange={(event)=>{setNewPassword(event.target.value)}}/>
                            </div>
                            </div>
                            
                            {dispMsg}    
                                   </div>
                           
                            <button style={{ fontFamily: "Oswald", border:"2px solid #1362b3"}} className="btn" onClick={(event)=>{handleChangePassword(event)}}>Change Password</button> 
                         
                        </form>
                    
                    </div>
                </div>
            </div>
        </section>


            </div>
    )

}