import React, {useContext, useState} from 'react';
import { ProductContext } from '../../ProductProvider';
import {Link, useHistory} from 'react-router-dom';
import { Auth } from "aws-amplify";
import { Collapse, Navbar, NavbarToggler, Nav, NavItem} from "reactstrap";

export default function Sidebar() {
    let history = useHistory();

    const {loggedUser, setLoggedUser} = useContext(ProductContext);
    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar=()=> {
        setCollapsed(!collapsed);
    }

    const handleShow =(value, event) =>{
        event.preventDefault();
            
        if(value==="Add"){
            history.push("/add-books");
        }else if(value==="Edit"){
            history.push("/edit-books");
        }else if(value==="Search"){
            history.push("/search-books");
        }else if(value==="Issue"){
            history.push("/issue-books");
        }else if(value==="Return"){
            history.push("/return-books");
        }else if(value==="MyBook"){
            history.push("/my-books");
        }else if(value==="MyProfile"){
            history.push("/my-profile");
        }else if(value==="ManageUser"){
            history.push("/manage-user");
        }
    }

    const handleSignout = (event) =>{
        event.preventDefault();
        try{
           Auth.signOut().then((data) => {
             setLoggedUser({...loggedUser,
               username:'',
               email:'',
               phoneno:'',
               familyName:'',
               profile:'',
               name:'',
               isAuthenticated:false
             });
             history.push("/signin");
           })
         }catch(error){
           console.log(error.message);
         }
    }
    

    return (
        <div>
                        <div className="dash-board">
                        <div className="navbar-bg">

                <br/>
              <div className="row " style={{position:'flex', justifyContent:'center'}}>
                            
               <h4 className="text-title" > <i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; {loggedUser.profile} Dashboard </h4>
              
                </div> 
                <br/>
                <div className="list-group">
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Add", event)} className="list-group-item"><i className="fa fa-plus fa-fw" aria-hidden="true"></i>&nbsp;Add Book</Link>
                    :  ""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Edit", event)} className="list-group-item" ><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Book</Link>
                    :""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Issue", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Book</Link>
                    :""}

                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   <Link to="/#" onClick={(event)=>handleShow("Return", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Book</Link>
                   :""}
                   
                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("Search", event)} className="list-group-item" ><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Book</Link>
                    :""}

                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("MyBook", event)} className="list-group-item" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</Link>
                    :""}

                    {loggedUser.isAuthenticated?
                    <Link to="/#" onClick={(event)=>handleShow("MyProfile", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</Link>
                    :""} 
                    
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("ManageUser", event)} className="list-group-item" ><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage User</Link>
                   :""}

                    {loggedUser.isAuthenticated? 
                    <Link to="/#" onClick={(event)=>handleSignout(event)} className="list-group-item" ><i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp; Sign Out</Link>
                    :""}
                   
                </div>
                </div>
            </div>

            <div className="navbar-mobile">
            <div className="row " style={{margin:'0px'}}>
                <div className="sidebar-mobile">
                    <Navbar className="navigation__navbar"  light expand="md">
                     
                               
                      <NavbarToggler onClick={toggleNavbar}  />
                        <Collapse isOpen={!collapsed} navbar>   
                        <Nav className="ml-auto" navbar> 
                        <NavItem onClick={toggleNavbar}>
                         
                        {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    <Link to="/#" onClick={(event)=>handleShow("Add", event)} className="list-group-item"><i className="fa fa-plus fa-fw" aria-hidden="true"></i>&nbsp;Add Book</Link>
                    :  ""}
                        
                        
                      </NavItem>      
                  <NavItem onClick={toggleNavbar}>                            
                  {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                    
                    <Link to="/#" onClick={(event)=>handleShow("Edit", event)} className="list-group-item" ><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Book</Link>
                    :""}
                
                  </NavItem>
                  <NavItem onClick={toggleNavbar}>
                       
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   
                   <Link to="/#" onClick={(event)=>handleShow("Issue", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Book</Link>
                   :""}
                    </NavItem>
                    <NavItem onClick={toggleNavbar}>
                       
                    {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                   <Link to="/#" onClick={(event)=>handleShow("Return", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Book</Link>
                   :""}
                       </NavItem>

                    

                    <NavItem onClick={toggleNavbar}>
                    {loggedUser.isAuthenticated?
                   
                    
                   <Link to="/#" onClick={(event)=>handleShow("Search", event)} className="list-group-item" ><i className="fa fa-search fa-fw" aria-hidden="true"></i>&nbsp; Search Book</Link>
                   :""}
                    </NavItem>
                    <NavItem onClick={toggleNavbar}>
                    {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleShow("MyBook", event)} className="list-group-item" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</Link>
                    :""}
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleShow("MyProfile", event)} className="list-group-item" ><i className="fa fa-user fa-fw" aria-hidden="true"></i>&nbsp; My Profile</Link>
                    
                    :""} 
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
                  
                  <Link to="/#" onClick={(event)=>handleShow("ManageUser", event)} className="list-group-item" ><i className="fa fa-users fa-fw" aria-hidden="true"></i>&nbsp; Manage User</Link>
                 :""}
                        </NavItem>
                        <NavItem onClick={toggleNavbar}>
                        {loggedUser.isAuthenticated?
                    
                    <Link to="/#" onClick={(event)=>handleSignout(event)} className="list-group-item" ><i className="fa fa-sign-out fa-fw" aria-hidden="true"></i>&nbsp; Sign Out</Link>
                    :""}
                        </NavItem>
                  </Nav>
                </Collapse>
              </Navbar>
            </div>
               
            </div> 

            </div>  
        </div>
    )
}
