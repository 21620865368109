import React, { useEffect, useContext } from 'react';
//import axios from "axios";
import { Auth } from "aws-amplify";
import {Col} from 'reactstrap';
import profileicon from '../../images/profile.gif';
import chngpass from '../../images/passchng.gif';
import logout from '../../images/logout.gif';



//import snap from '../../images/bg.gif'
import useFullPageLoader from "../hooks/useFullPageLoader";
import { ProductContext } from '../../ProductProvider';
import { Link, useHistory } from 'react-router-dom';

//import MyAccounts from './MyAccounts';
//import {temp_client_accounts,tempClients} from '../../packageDetails';
//const config = require('../../config.json');

export default function BooksView(props) {
    let history = useHistory();
    const { loggedUser, setLoggedUser, setShowPage } = useContext(ProductContext);
    const [loader] = useFullPageLoader();
    //const [dispMsg, setDispMsg] = useState('');
    //const [myAccounts, setMyAccounts] = useState('');
    useEffect(() => {
        setShowPage(false);
        window.scrollTo(0,0);
        
    }, [])
   

    /*const assignTempValues = async() =>{
        const tempHost = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WH'));
        const tempDevelop = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-WD'));
        const tempEmail = temp_client_accounts.filter(plan => plan.packageId.includes('IDN-EH'));
        setAllClientAccounts(temp_client_accounts);
        setWebDevelopAccounts(tempDevelop);
        setWebHostAccoAunts(tempHost);
        setEmailAccounts(tempEmail);
    }*/

    const handleShow = (value,event) => {
        event.preventDefault();
        if(loggedUser.isAuthenticated){
            if (value==="MyBooks") {
                    history.push("/my-books");
                } else if (value==="AddBooks") {
                    history.push('/add-books');
                } else if (value==="EditBooks") {
                    history.push('/edit-books');
                } else if (value==="IssueBooks") {
                    history.push('/issue-books');
                } else if (value==="ReturnBooks") {
                    history.push('/return-books');
                } 
            }else{
                history.push("/signin");
            }
    }



    return (
        <div className="dash-board" style={{paddingTop:"10px", paddingBottom:"60px", height:'1000px'}}>
        <h4 style={{paddingBottom:'10px'}} className="text-title"><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; Library Management</h4>

            <div style={{textAlign:"center"}}> {loader} </div>
               
                <div>      
                      
               <Col sm={4} style={{textAlign:'center'}}>
                 {/* <Link to="#" onClick={(event)=>{handleShow("MyBooks",event)}} ><div className="card" style={{borderRadius:"4px"}}>
                 <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={logout} alt="Avatar" />
                   
                  <h5 style={{textAlign:"center", paddingTop:"15px", height:"60px"}}><b>My Books </b></h5> 
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                     </div> 
                   </div></Link> */}
                   <Link to="/#" onClick={(event)=>handleShow("MyBooks", event)} className="list-group-item" ><i className="fa fa-book fa-fw" aria-hidden="true"></i>&nbsp; My Books</Link>
                </Col>     
                {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?
     
                 <Col sm={4} style={{textAlign:'center'}}>
               
                   <Link  to="/#" onClick={(event)=>handleShow("AddBooks", event)} className="list-group-item"><i className="fa fa-plus fa-fw" aria-hidden="true"></i>&nbsp; Add Books </Link>
               </Col>:null}
               {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

                <Col sm={4} style={{textAlign:'center'}}>
                 
                   <Link to="/#" onClick={(event)=>handleShow("EditBooks", event)} className="list-group-item" ><i className="fa fa-pencil-square-o fa-fw" aria-hidden="true"></i>&nbsp; Edit Book</Link>
                   </Col>:null}
                   {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

                   <Col sm={4} style={{textAlign:'center'}}>
                   {/*<Link to="#" onClick={(event)=>handleShow("IssueBooks",event)} style={{paddingBottom:"8px"}}><div className="card" style={{borderRadius:"4px"}}>
                <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={chngpass} alt="Avatar" />
                   
                  <h5 style={{textAlign:"center", paddingTop:"6px", height:"60px"}}><b>Issue Books</b></h5> 
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      </div> 
                   </div></Link>*/}
                   <Link to="/#" onClick={(event)=>handleShow("IssueBooks", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Issue Book</Link>
                   </Col>:null}
                   {loggedUser.isAuthenticated && (loggedUser.profile==="Librarian" || loggedUser.profile==="Admin")?

                   <Col sm={4} style={{textAlign:'center'}}>
                 {/*  <Link to="#" onClick={(event)=>handleShow("ReturnBooks",event)} style={{paddingBottom:"8px"}}><div className="card" style={{borderRadius:"4px"}}>
                <img style={{borderTopLeftRadius:"4px", borderTopRightRadius:"4px"}} className="dash-img" src={chngpass} alt="Avatar" />
                   
                  <h5 style={{textAlign:"center", paddingTop:"6px", height:"60px"}}><b>Return Books</b></h5> 
                  
                  <div style={{marginTop:"-30px",  right:"0px",lineHeight:"normal", textAlign:"center",
                   display:"table",  alignItems:"center"}}>
                      </div> 
                   </div></Link>*/}
                   <Link to="/#" onClick={(event)=>handleShow("ReturnBooks", event)} className="list-group-item" ><i className="fa fa-list fa-fw" aria-hidden="true"></i>&nbsp; Return Book</Link>
                   </Col>:null}
                </div>
      
                </div>
    )
}
